define('scientia-resourcebooker/pods/application/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    session: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    helpFetcher: _ember['default'].inject.service(),
    router: _ember['default'].inject.service(),
    network: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),

    isSessionTimedout: _ember['default'].computed.alias('session.isSessionTimedout'),
    isNetworkOffline: _ember['default'].computed.alias('network.isNetworkOffline'),

    showHelpModal: false,
    helpDataForPage: null,
    downloadFile: null,

    taskKey: null,
    taskDefer: null,

    showGlobalConfirmModal: _ember['default'].computed.alias('globalConfirmer.modalData'),

    init: function init() {
      var _this = this;

      this._super();

      this.get('session').on('authorizationInvalidated', function () {
        _this.get('session').timeout();
      });

      // On networkOffline trigger invoke timeout on network service.
      this.get('network').on('networkOffline', function () {
        _this.get('network').offline();
      });

      // On networkRestored trigger invoke restored on network service.
      this.get('network').on('networkRestored', function () {
        _this.get('network').restored();
      });

      this.get('i18n').on('missing', function (locale, key, context) {
        if (key.indexOf('booking_window_custom') === -1 && (!context || !context.optional)) {

          // Only log if there are no more fallback paths
          if (!context.hasLocalePathFallback) {
            LE.error('Missing translation:', { locale: locale, key: key, context: context });
          }
        }
      });
    },

    sessionTimeoutObserver: (function () {
      var _this2 = this;

      if (!this.get('isSessionTimedout')) {
        return false;
      }

      this.flashClear();
      this.flashCustom({ timeout: 10000, message: this.get('i18n').t('general.session_timeout_error'), type: 'danger alert-session-timeout' });

      var loggedInAt = this.get('session.loggedInAt');

      if (moment().diff(loggedInAt, 'seconds') > 30) {

        _ember['default'].run.later(this, function () {
          _this2.get('session').saveRedirectURL();
          _this2.get('session').logout();
        }, 5000);
      } else {
        this.get('session').saveRedirectURL();
        this.get('session').logout();
      }
    }).observes('isSessionTimedout'),

    networkTimeoutObserver: (function () {

      var isNetworkOffline = this.get('isNetworkOffline');

      this.flashClear();

      if (isNetworkOffline) {
        this.flashCustom({ sticky: true, destroyOnClick: false, message: this.get('i18n').t('general.network_offline'), type: 'danger alert-network-offline' });
      } else {
        this.flashCustom({ timeout: 10000, message: this.get('i18n').t('general.network_restored'), type: 'success alert-network-restored' });
      }
    }).observes('isNetworkOffline'),

    _cleanProgressData: function _cleanProgressData() {
      this.setProperties({
        taskKey: null,
        taskDefer: null
      });
    },

    actions: {
      confirmLogout: function confirmLogout(shouldSLO) {
        this.get('appInsights').trackEvent('logout');
        this.get('session').logout(true, shouldSLO);
      },

      onShowHelpModal: function onShowHelpModal() {
        var _this3 = this;

        this.set('showHelpModal', true);
        var routePath = this.get('router').get('currentRouteName');
        this.get('helpFetcher').fetch(routePath).then(function (data) {
          data = data || {};
          data.path = routePath;
          data.home = _this3.get('helpFetcher').getHomepageForLocale(_this3.get('i18n.locale'));
          _this3.set('helpDataForPage', data);
        });
      },

      onProgressComplete: function onProgressComplete() {
        this.get('taskDefer').resolve();
        this._cleanProgressData();
      },

      onProgressFailed: function onProgressFailed() {
        this.get('taskDefer').reject({ fail: true });
        this._cleanProgressData();
      },

      onProgressCancel: function onProgressCancel() {
        this.get('taskDefer').reject({ cancelled: true });
        this._cleanProgressData();
      },

      closeDownloadFileModal: function closeDownloadFileModal() {
        this.set('downloadFile', null);
        this.get('session').restoreAutoLogout();
      }
    }
  });
});