define("scientia-resourcebooker/pods/logged-in/search-results/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 15,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/search-results/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle-search at-searchResults");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          morphs[2] = dom.createMorphAt(element0, 5, 5);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [5, 22], [5, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "search", "placeholder", ["subexpr", "t", ["pods.logged_in.search_results.search_placeholder"], [], ["loc", [null, [5, 59], [5, 113]]], 0, 0], "class", "form-input form-input--white sectionTitle-search-input at-searchResults"], ["loc", [null, [5, 8], [5, 195]]], 0, 0], ["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "types", ["loc", [null, [7, 18], [7, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "type", ["loc", [null, [8, 16], [8, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "label", "optionValuePath", "value", "class", "at-searchResults"], ["loc", [null, [6, 8], [12, 10]]], 0, 0], ["content", "help-trigger", ["loc", [null, [13, 8], [13, 24]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 5
            },
            "end": {
              "line": 25,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/search-results/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.items.pagination.currentPage", ["loc", [null, [20, 20], [20, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.items.pagination.totalPages", ["loc", [null, [21, 19], [21, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [23, 21], [23, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 8], [24, 10]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/search-results/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 5, 5);
        return morphs;
      },
      statements: [["block", "section-title", [], ["text", ["subexpr", "t", ["pods.logged_in.search_results.search"], [], ["loc", [null, [3, 26], [3, 68]]], 0, 0], "icon", "icons/search.svg", "class", "at-searchResults"], 0, null, ["loc", [null, [3, 4], [15, 22]]]], ["inline", "search-results-list", [], ["results", ["subexpr", "@mut", [["get", "model.items.results", ["loc", [null, [17, 34], [17, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "type", ["subexpr", "@mut", [["get", "model.type", ["loc", [null, [17, 59], [17, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "onClickSearchResource", ["subexpr", "route-action", ["onClickGlobalSearchResource"], [], ["loc", [null, [17, 92], [17, 136]]], 0, 0]], ["loc", [null, [17, 4], [17, 138]]], 0, 0], ["block", "if", [["get", "shouldShowPaging", ["loc", [null, [18, 11], [18, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [18, 5], [25, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});