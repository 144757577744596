define('scientia-resourcebooker/pods/components/booking-request-form/datetime-section/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var HOUR_FORMAT = 'HH:mm';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--datetime'],
    classNameBindings: ['isLoading'],
    calendarOptionsHelper: _ember['default'].inject.service(),

    model: null,
    calendarOptions: null,
    bookingType: null,
    isLoading: false,

    contentFromTime: null,
    contentToTime: null,

    init: function init() {

      this._super.apply(this, arguments);

      this.set('isLoading', true);
      this._autoAdjustTimes();
      this.send('updateContentForFromTime');
      this.send('updateContentForToTime');

      this.set('isLoading', false);
    },

    _mapValues: function _mapValues(data) {
      return data.map(function (v) {
        return { value: v, label: v };
      });
    },

    _autoAdjustTimes: function _autoAdjustTimes() {
      var calendarOptions = this.get('calendarOptions');

      var startDateTime = this.get('model.startDateTime');
      var endDateTime = this.get('model.endDateTime');

      var fromTime = this.get('model.fromTime');
      var toTime = this.get('model.toTime');

      var fullFromDate = this._mergeMomentDateAndTime(startDateTime, fromTime);
      var fullToDate = this._mergeMomentDateAndTime(endDateTime, toTime);

      var isBookingTimesValid = this.get('calendarOptionsHelper').isBookingTimesValidForCalendarOptions(fullFromDate, fullToDate, calendarOptions);
      if (isBookingTimesValid) return;

      this.set('isLoading', true);

      var forceAdjustmentsToSameDay = !calendarOptions.bookingSpanMultipleDays;

      if (fullToDate.isSameOrBefore(fullFromDate)) {
        fullToDate = fullFromDate.clone().add(1, 'hour');
      }

      var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions = this.get('calendarOptionsHelper').adjustBookingEditStartAndEndDateTimePerCalendarOptions(fullFromDate, fullToDate, calendarOptions, forceAdjustmentsToSameDay);

      var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2 = _slicedToArray(_get$adjustBookingEditStartAndEndDateTimePerCalendarOptions, 2);

      var adjustedStartDateTime = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[0];
      var adjustedEndDateTime = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[1];

      var adjustedFromTime = adjustedStartDateTime.format(HOUR_FORMAT);
      var adjustedToTime = adjustedEndDateTime.format(HOUR_FORMAT);

      if (adjustedToTime === '00:00') {
        adjustedEndDateTime.subtract(1, 'day');
        adjustedToTime = '24:00';
      }

      var model = this.get('model');

      _ember['default'].setProperties(model, {
        startDateTime: adjustedStartDateTime,
        endDateTime: adjustedEndDateTime,
        fromTime: adjustedFromTime,
        toTime: adjustedToTime
      });

      _ember['default'].run.later(this, 'set', 'isLoading', false, 200);
    },

    actions: {
      updateFromTime: function updateFromTime(fromTime) {
        this.set('model.fromTime', fromTime);

        this._autoAdjustTimes();

        this.send('updateContentForToTime');
      },

      updateToTime: function updateToTime(toTime) {
        this.set('model.toTime', toTime);
        this._autoAdjustTimes();
      },

      updateStartDate: function updateStartDate(date) {

        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!this.get('model') || this.get('model.isDeleted')) return;
        if (date.isSame(this.get('model.startDateTime'))) return;

        this.set('model.startDateTime', date);

        // We should also update the endDateTime if the bookingType has externally managed resources or doesn't allow SpanMultipleDays
        // because the endDate needs to be the same day as the startDate
        if (this.get('bookingType.hasExternallyManagedResources') || !this.get('calendarOptions.bookingSpanMultipleDays')) {
          var endDate = this.get('model.endDateTime').clone();
          if (!date.isSame(endDate.subtract(1, 'minute'), 'day')) {
            var adjustedEndDate = date.clone();
            adjustedEndDate.day(date.day());
            this.send('updateEndDate', adjustedEndDate);
          }
        }

        this._autoAdjustTimes();

        this.send('updateContentForFromTime');
        this.send('updateContentForToTime');
      },

      updateEndDate: function updateEndDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!this.get('model') || this.get('model.isDeleted')) return;
        if (date.isSame(this.get('model.endDateTime'))) return;

        this.set('model.endDateTime', date);

        this._autoAdjustTimes();

        this.send('updateContentForFromTime');
        this.send('updateContentForToTime');
      },

      updateContentForFromTime: function updateContentForFromTime() {
        var fromDate = this.get('model.startDateTime');

        var fromTime = this.get('model.fromTime');

        var fullFromDate = this._mergeMomentDateAndTime(fromDate, fromTime);

        var calendarOptions = this.get('calendarOptions');

        var result = this.get('calendarOptionsHelper').getTimeSlotsForStartTime(fullFromDate, calendarOptions, false);
        var resultMapped = this._mapValues(result);

        // Only update the dropdown if the values are different from the old ones (improves performance as it won't need to redraw)
        if (!this.mappedValuesAreEqual(resultMapped, this.get('contentFromTime'))) {
          this.set('contentFromTime', resultMapped);
        }
      },

      updateContentForToTime: function updateContentForToTime() {
        var fromDate = this.get('model.startDateTime');
        var toDate = this.get('model.endDateTime');

        var fromTime = this.get('model.fromTime');
        var toTime = this.get('model.toTime');

        var fullFromDate = this._mergeMomentDateAndTime(fromDate, fromTime);
        var fullToDate = this._mergeMomentDateAndTime(toDate, toTime);

        var calendarOptions = this.get('calendarOptions');

        var result = this.get('calendarOptionsHelper').getTimeSlotsForEndTime(fullFromDate, fullToDate, calendarOptions);
        var resultMapped = this._mapValues(result);

        // Only update the dropdown if the values are different from the old ones (improves performance as it won't need to redraw)
        if (!this.mappedValuesAreEqual(resultMapped, this.get('contentToTime'))) {
          this.set('contentToTime', resultMapped);
        }
      }
    },

    // Just a comparer function so that we don't keep refreshing the content of the dropdown constantly if
    // it's not needed. (Redrawing dropdown is expensive but comparing the values isn't)
    mappedValuesAreEqual: function mappedValuesAreEqual(arr1, arr2) {
      if (!arr1 || !arr2) return false;
      if (_ember['default'].get(arr1, 'length') !== _ember['default'].get(arr2, 'length')) return false;
      var isEqual = true;
      for (var i = 0; i < arr1.length; i++) {
        if (arr1[i].value !== arr2[i].value) {
          isEqual = false;
          break;
        }
      }

      return isEqual;
    },

    _mergeMomentDateAndTime: function _mergeMomentDateAndTime(date, time) {
      var result = date.clone();

      var _time$split = time.split(':');

      var _time$split2 = _slicedToArray(_time$split, 2);

      var hour = _time$split2[0];
      var minute = _time$split2[1];

      result.hour(hour).minute(minute);
      return result;
    }
  });
});