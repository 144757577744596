define('scientia-resourcebooker/services/app-insights', ['exports', 'ember'], function (exports, _ember) {
  /*
  ----EVENTS-----
  
  login
  logout
  language-change : {language}
  show-availability
  
  booking-created : {resourceId, resourceName, bookingTypeId, bookingTypeName}
  booking-edited : {resourceId, resourceName, bookingTypeId, bookingTypeName, bookingId, occurrenceId, isOwner, type, from}
  booking-cancelled: {isOwner, type, bookingId, bookingName, from}
  search-global: {query, type}
  search-resource: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, query}
  load-more
  notification-accept
  notification-request
  notification-more-info
  
  3.0 added:
  bulk-import-template-download {type : custom-fields/resources, file: with-mapping/no-mapping/mapping-file}
  bulk-export {all: true, ids: []}
  bulk-import: {step: preview/confirm, type, request, response}
  report-dashboard-view: {reportId, reportName}
  report-audit-generate: {fromDate, toDate, useBookingDates, isAdmin, count}
  template-help-click: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, resourceGroupName }
  global-help-click: {path, locale, hasHelpData}
  global-help-topic-click': {path, topic, locale, hasHelpDataInCurrentLocale}
  resource-summary: {resourceTypeId, resourceId}
  
  booking-created: added -> {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-edited: added => {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-print: {bookingTypeId}
  booking-search: {query, count, currentPage, totalPages}
  my-bookings-print, {isSearchResults}
  
  resource-filter: {bookingType, bookingTypeId, name, value, type}
  resource-found-filters: {resourceName, bookingTypeId, bookingTypeName, filters, duration, query, availableNow, spanMultipleDays,firstDay, lastDay,fromTime, toTime}
  
  3.1:
  permissions-summary {type: resource/user-group/self/search, resourceTypeId, resourceId, isAdmin, claimType, includeEveryoneGroup}
  permissions-summary-bulk-clear {totalResults, successfulResults}
  permissions-summary-link {type: resource/usergroup, isAdmin}
  
  click-subscribe-{type}
  click-subscribe-regenerate-token
  click-download-calendar
  click-download-calendar-copy-button-success
  click-download-calendar-copy-button-fail
  
  report-bookings-generate {fromDate, toDate, isAdmin, count}
  report-bookings-refresh {isAdmin}
  report-bookings-export {isAdmin}
  report-bookings-print {isAdmin}
  report-audit-generate: {fromDate, toDate, useBookingDates, isAdmin, count} // updated from 3.0
  report-bookings-toggle-active-bookings {value, isAdmin}
  
  calendar-drag, {fromTime, toTime, isAdmin, isEditing}
  
  concurrency-view-mode {mode}
  ******
  3.2:
  booking-type-notification-templates-toogle {value, bookingTypeId}
  booking-type-notification-templates-copy-from-system {allTemplates: boolean, bookingTypeId, notificationType, reciepientType}
  booking-type-notification-template-save {bookingTypeId, recipientType, notificationType sendEmail, isSystem}
  concurrency-toggle-lanes-mode {value}
  concurrency-toggle-template-unavailability {id, name, resources}
  concurrency-view-mode {value}
  
  3.3:
  availability-summary-open {id, name}
  availability-summary-link {id, name, targetType, targetId, targetName}
  create-availability-pattern, update-availability-pattern, delete-availability-pattern {id, name, from}
  availability-link-pattern  {type,id, parentId, patterns: name, id }
  availability-copy-pattern  {type,id, parentId,patternId: patternName, replaceAll}
  availability-preview: {type, name, id}
  availability-copy-pattern-view-details
  availability-link-pattern-view-details
  availability-day-header-click {dayOfWeek, isSpecifics}
  availability-drag {start, end, isSpecifics, isValid}
  availability-clear {type, id, parentId, clearType}
  availability-update-exceptions {type, id, parentId}
  availability-preview-toggle-include-disabled-patterns {value}
  ----------------------------------------
   */
  exports['default'] = _ember['default'].Service.extend({
    systemSettings: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    trackEvent: function trackEvent(event, data) {
      //console.log(event, data);
      if (!data) data = {};

      try {
        data.isAdmin = this.get('currentUser').isAdmin();
      } catch (error) {
        //no-op
      }

      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackEvent(event, data);
    },

    trackPageView: function trackPageView(name) {
      var properties = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackPageView(name, null, properties);
    },

    trackCreate: function trackCreate(type) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.trackEvent('create-' + type, data);
    },

    trackUpdate: function trackUpdate(type) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.trackEvent('update-' + type, data);
    },

    trackDelete: function trackDelete(type) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.trackEvent('delete-' + type, data);
    },

    trackBookingTypeLoadMore: function trackBookingTypeLoadMore(bookingType, resourceGroup) {
      this.trackEvent('load-more', {
        bookingTypeName: bookingType.get('name'),
        bookingTypeId: bookingType.get('id'),
        isResourceGroup: !!resourceGroup,
        resourceGroupId: resourceGroup ? _ember['default'].get(resourceGroup, 'id') : '',
        resourceGroupName: resourceGroup ? _ember['default'].get(resourceGroup, 'name') : ''
      });
    },

    trackBookingTypeHelpTriggerClick: function trackBookingTypeHelpTriggerClick(bookingType, resourceGroup) {
      this.trackEvent('template-help-click', {
        bookingTypeName: bookingType.get('name'),
        bookingTypeId: bookingType.get('id'),
        isResourceGroup: !!resourceGroup,
        resourceGroupId: resourceGroup ? _ember['default'].get(resourceGroup, 'id') : '',
        resourceGroupName: resourceGroup ? _ember['default'].get(resourceGroup, 'name') : ''
      });
    },

    trackBookingTypeFilterApplied: function trackBookingTypeFilterApplied(filterData, bookingType) {
      // Don't log if level 1 or lower
      if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) <= 1) {
        return;
      }
      filterData.bookingType = _ember['default'].get(bookingType, 'name');
      filterData.bookingTypeId = _ember['default'].get(bookingType, 'id');
      this.trackEvent('resource-filter', filterData);
    },

    trackBookingTypeNotificationTemplateUpdate: function trackBookingTypeNotificationTemplateUpdate(template) {
      var logData = {
        bookingTypeId: template.get('bookingTypeIdentity'),
        recipientType: template.get('recipientType'),
        notificationType: template.get('name'),
        sendEmail: template.get('sendEmail'),
        isSystem: template.get('bookingTypeIdentity') === '00000000-0000-0000-0000-000000000000'
      };

      this.trackEvent('booking-type-notification-templates-save', logData);
    },

    trackConcurrencyToggleTemplateUnavailability: function trackConcurrencyToggleTemplateUnavailability(concurrencyObject, value) {
      var logData = {
        id: concurrencyObject.get('id'),
        name: concurrencyObject.get('name'),
        resources: concurrencyObject.get('resources.length'),
        value: value
      };
      this.trackEvent('concurrency-toggle-template-unavailability', logData);
    },

    trackConcurrencyToggleConcurrencyUnavailability: function trackConcurrencyToggleConcurrencyUnavailability(concurrencyObject, value) {
      var logData = {
        id: concurrencyObject.get('id'),
        name: concurrencyObject.get('name'),
        resources: concurrencyObject.get('resources.length'),
        value: value
      };
      this.trackEvent('concurrency-toggle-concurrency-unavailability', logData);
    },

    trackCopyPattern: function trackCopyPattern(_ref) {
      var isCopy = _ref.isCopy;
      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var pattern = _ref.pattern;
      var replaceAll = _ref.replaceAll;

      this.trackEvent('availability-copy-pattern', {
        type: type,
        id: id,
        parentId: parentId,
        patternId: pattern.get('id'),
        patternName: pattern.get('name'),
        replaceAll: replaceAll
      });
    },

    trackLinkPattern: function trackLinkPattern(_ref2) {
      var type = _ref2.type;
      var id = _ref2.id;
      var parentId = _ref2.parentId;
      var _ref2$patterns = _ref2.patterns;
      var patterns = _ref2$patterns === undefined ? [] : _ref2$patterns;

      var patternData = patterns.map(function (p) {
        return { id: p.get('id'), name: p.get('name') };
      });

      this.trackEvent('availability-link-pattern', {
        type: type,
        id: id,
        parentId: parentId,
        patterns: JSON.stringify(patternData)
      });
    },

    trackAvailabilityPreview: function trackAvailabilityPreview(_ref3) {
      var type = _ref3.type;
      var id = _ref3.id;
      var name = _ref3.name;

      this.trackEvent('availability-preview', {
        type: type,
        id: id,
        name: name
      });
    },

    trackAvailabilityPreviewIncludeDisabledPatterns: function trackAvailabilityPreviewIncludeDisabledPatterns(value) {
      this.trackEvent('availability-preview-toggle-include-disabled-patterns', { value: value });
    },

    trackAvailabilityPatternCopyLinkModalViewPattern: function trackAvailabilityPatternCopyLinkModalViewPattern(_ref4) {
      var isCopyMode = _ref4.isCopyMode;

      var eventName = 'availability-' + (isCopyMode ? 'copy' : 'link') + '-pattern-view-details';
      this.trackEvent(eventName);
    },

    trackAvailabilitySummaryOpen: function trackAvailabilitySummaryOpen(availabilityPattern) {
      this.trackEvent('availability-summary-open', {
        id: availabilityPattern.get('id'),
        name: availabilityPattern.get('name')
      });
    },

    trackAvailabilityHeaderClick: function trackAvailabilityHeaderClick(_ref5) {
      var dayOfWeek = _ref5.dayOfWeek;
      var isSpecifics = _ref5.isSpecifics;

      this.trackEvent('availability-day-header-click', { dayOfWeek: dayOfWeek, isSpecifics: isSpecifics });
    },

    trackAvailabilityDrag: function trackAvailabilityDrag(_ref6) {
      var start = _ref6.start;
      var end = _ref6.end;
      var isSpecifics = _ref6.isSpecifics;
      var isValid = _ref6.isValid;

      this.trackEvent('availability-drag', { start: start, end: end, isSpecifics: isSpecifics, isValid: isValid });
    },

    trackClearUnavailability: function trackClearUnavailability(_ref7) {
      var type = _ref7.type;
      var id = _ref7.id;
      var parentId = _ref7.parentId;
      var unavailabilityOption = _ref7.unavailabilityOption;

      this.trackEvent('availability-clear', { type: type, id: id, parentId: parentId, clearType: unavailabilityOption });
    },

    trackAvailabilityUpdateExceptions: function trackAvailabilityUpdateExceptions(_ref8) {
      var type = _ref8.type;
      var id = _ref8.id;
      var parentId = _ref8.parentId;

      this.trackEvent('availability-update-exceptions', { type: type, id: id, parentId: parentId });
    }

  });
});