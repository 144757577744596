define("scientia-resourcebooker/pods/application/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "waitingBar");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["logout-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [13, 25], [13, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 20], [13, 39]]], 0, 0], false], [], ["loc", [null, [13, 12], [13, 46]]], 0, 0], "singleLogoutMode", ["subexpr", "@mut", [["get", "singleLogoutMode", ["loc", [null, [14, 23], [14, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "singleLogoutCheckboxValue", ["subexpr", "@mut", [["get", "singleLogoutCheckboxValue", ["loc", [null, [15, 32], [15, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["confirmLogout"], [], ["loc", [null, [16, 14], [16, 38]]], 0, 0]], ["loc", [null, [11, 11], [16, 39]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [17, 32], [17, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 27], [17, 46]]], 0, 0], false], [], ["loc", [null, [17, 19], [17, 53]]], 0, 0]], ["loc", [null, [10, 9], [17, 54]]], 0, 0]], ["loc", [null, [8, 1], [18, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["model", ["subexpr", "@mut", [["get", "showGlobalConfirmModal", ["loc", [null, [28, 12], [28, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "singleButtonMode", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.singleButtonMode", ["loc", [null, [29, 23], [29, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "confirmTitle", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.title", ["loc", [null, [30, 19], [30, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "confirmMessage", ["subexpr", "@mut", [["get", "showGlobalConfirmModal.message", ["loc", [null, [31, 21], [31, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [26, 11], [31, 52]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showGlobalConfirmModal", ["loc", [null, [32, 32], [32, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 27], [32, 55]]], 0, 0], null], [], ["loc", [null, [32, 19], [32, 61]]], 0, 0]], ["loc", [null, [25, 9], [32, 62]]], 0, 0]], ["loc", [null, [23, 0], [33, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["help-modal"], ["model", ["subexpr", "@mut", [["get", "helpDataForPage", ["loc", [null, [42, 14], [42, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [43, 27], [43, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 22], [43, 41]]], 0, 0], false], [], ["loc", [null, [43, 14], [43, 48]]], 0, 0]], ["loc", [null, [40, 11], [43, 49]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [44, 34], [44, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 29], [44, 48]]], 0, 0], false], [], ["loc", [null, [44, 21], [44, 55]]], 0, 0]], ["loc", [null, [39, 9], [44, 56]]], 0, 0]], ["loc", [null, [37, 2], [45, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 0
            },
            "end": {
              "line": 59,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["download-modal"], ["model", ["subexpr", "@mut", [["get", "downloadFile", ["loc", [null, [54, 12], [54, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", ["closeDownloadFileModal"], [], ["loc", [null, [55, 12], [55, 45]]], 0, 0], "confirm", ["subexpr", "action", ["closeDownloadFileModal"], [], ["loc", [null, [56, 14], [56, 47]]], 0, 0]], ["loc", [null, [52, 11], [56, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [57, 37], [57, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 27], [57, 42]]], 0, 0]], [], ["loc", [null, [57, 19], [57, 43]]], 0, 0]], ["loc", [null, [51, 9], [57, 44]]], 0, 0]], ["loc", [null, [49, 1], [58, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 0
            },
            "end": {
              "line": 73,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/task-modal"], ["progressKey", ["subexpr", "@mut", [["get", "taskKey", ["loc", [null, [67, 18], [67, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onGetProgressFailed", ["subexpr", "action", ["onProgressFailed"], [], ["loc", [null, [68, 26], [68, 53]]], 0, 0], "onCancel", ["subexpr", "action", ["onProgressCancel"], [], ["loc", [null, [69, 15], [69, 42]]], 0, 0], "onComplete", ["subexpr", "action", ["onProgressComplete"], [], ["loc", [null, [70, 17], [70, 46]]], 0, 0]], ["loc", [null, [65, 11], [70, 47]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [71, 39], [71, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [71, 29], [71, 44]]], 0, 0]], [], ["loc", [null, [71, 21], [71, 45]]], 0, 0]], ["loc", [null, [64, 9], [71, 46]]], 0, 0]], ["loc", [null, [62, 1], [72, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 0
            },
            "end": {
              "line": 78,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "custom-flash-message", [], ["flash", ["subexpr", "@mut", [["get", "flash", ["loc", [null, [77, 31], [77, 36]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [77, 2], [77, 38]]], 0, 0]],
        locals: ["flash"],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 0
            },
            "end": {
              "line": 82,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "timeout-overlay");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 0
            },
            "end": {
              "line": 86,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "timeout-overlay");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "aria-hidden", "true");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(11);
        morphs[0] = dom.createAttrMorph(element0, 'data-route-name');
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 14, 14, contextualElement);
        morphs[8] = dom.createMorphAt(fragment, 15, 15, contextualElement);
        morphs[9] = dom.createMorphAt(fragment, 17, 17, contextualElement);
        morphs[10] = dom.createMorphAt(fragment, 19, 19, contextualElement);
        return morphs;
      },
      statements: [["attribute", "data-route-name", ["concat", [["get", "router.currentRouteName", ["loc", [null, [1, 44], [1, 67]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "loading", ["loc", [null, [3, 6], [3, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [5, 7]]]], ["block", "if", [["get", "confirmLogout", ["loc", [null, [7, 6], [7, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 0], [19, 7]]]], ["block", "if", [["get", "showGlobalConfirmModal", ["loc", [null, [21, 6], [21, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 0], [34, 7]]]], ["block", "if", [["get", "showHelpModal", ["loc", [null, [36, 6], [36, 19]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [36, 0], [46, 7]]]], ["block", "if", [["get", "downloadFile", ["loc", [null, [48, 6], [48, 18]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [48, 0], [59, 7]]]], ["block", "if", [["get", "taskKey", ["loc", [null, [61, 6], [61, 13]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [61, 0], [73, 7]]]], ["block", "each", [["get", "flashMessages.queue", ["loc", [null, [76, 8], [76, 27]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [76, 0], [78, 9]]]], ["block", "if", [["get", "isSessionTimedout", ["loc", [null, [80, 6], [80, 23]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [80, 0], [82, 7]]]], ["block", "if", [["get", "isNetworkOffline", ["loc", [null, [84, 6], [84, 22]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [84, 0], [86, 7]]]], ["content", "liquid-outlet", ["loc", [null, [88, 0], [88, 17]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});