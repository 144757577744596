define('scientia-resourcebooker/services/concurrency-events-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var concurrencyConfigurationIds = _ref.concurrencyConfigurationIds;
      var concurrencyGroupIds = _ref.concurrencyGroupIds;
      var isConcurrencyConfiguration = _ref.isConcurrencyConfiguration;
      var includeConcurrencyGroups = _ref.includeConcurrencyGroups;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var includeBookingTypes = _ref.includeBookingTypes;
      var allowDuplicates = _ref.allowDuplicates;
      var forceResync = _ref.forceResync;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.concurrency.busyTime;

      var data = {
        concurrencyGroupIdentities: concurrencyGroupIds,
        concurrencyConfigurationIdentities: concurrencyConfigurationIds,
        includeConcurrencyGroups: includeConcurrencyGroups,
        startDate: startDate.startOf('minute').toISOString(),
        endDate: endDate.startOf('minute').toISOString(),
        includeTemplateAvailability: includeBookingTypes,
        resourceFilterType: isConcurrencyConfiguration ? 0 : 1,
        forceResync: forceResync
      };

      return this.get('authenticatedRequest').post(url, data).then(function (response) {
        return _this._normalizeResponse(response, allowDuplicates);
      });
    },

    _normalizeResponse: function _normalizeResponse(data, allowDuplicates) {
      var _this2 = this;

      if (!allowDuplicates) return data.map(function (s) {
        return _this2._normalizeSlot(s);
      });

      var expandedSlots = [];
      data.forEach(function (slot) {

        slot = _this2._normalizeSlot(slot);

        // set resourceIdLanesViewVariant to the first resource Id
        slot.resourceIdLanesViewVariant = slot.resourceIds[0];

        if (slot.resourceIds.length > 1) {
          slot.resourceIds.forEach(function (resourceId, index) {

            if (index > 0) {
              var newSlot = JSON.parse(JSON.stringify(slot));
              newSlot.id = newSlot.id + '-' + index;
              newSlot.resourceIdLanesViewVariant = resourceId;
              expandedSlots.push(newSlot);
            }
          });
        }
      });

      data.push.apply(data, expandedSlots);
      return data;
    },

    _normalizeSlot: function _normalizeSlot(slot) {
      if (slot.duration === 59) slot.duration = 60;

      // Adjust events that span DST boundaries (have a different start and end utc offsets)
      var start = moment(slot.startDateTime);
      var end = start.clone().add(slot.duration, 'minute');
      var startOffset = start.clone().subtract(1, 'minute').utcOffset();
      var endOffset = end.clone().subtract(1, 'minute').utcOffset();

      if (startOffset > endOffset) {
        slot.duration += startOffset;
      } else if (startOffset < endOffset) {
        slot.duration -= endOffset;
      }

      return slot;
    }
  });
});