define('scientia-resourcebooker/serializers/resource-group', ['exports', 'ember', 'scientia-resourcebooker/serializers/application'], function (exports, _ember, _scientiaResourcebookerSerializersApplication) {

    var EDIT_PERMISSION_GROUPS = ['description', 'resources', 'permissions', 'all'];

    var RESOURCE_EDIT_PERMISSION_GROUPS = ['description', 'custom-fields', 'availability', 'permissions', 'all'];

    exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend({

        normalize: function normalize(model, hash) {
            var applicationSerializer = this.get('store').serializerFor('application');

            hash = applicationSerializer._camelizeKeysDeep(hash);
            hash = applicationSerializer._setPermissionGroupDefaults(hash);

            hash = applicationSerializer._normalizeNestedPermissionGroups(hash, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');
            hash = applicationSerializer._normalizeNestedPermissionGroups(hash, RESOURCE_EDIT_PERMISSION_GROUPS, 'editNestedPermissionsGroups', 'resourcesEdit%@PermissionGroups');
            var data = this._super(model, hash);
            return data;
        },

        serialize: function serialize(snapshot /*, options*/) {

            var serialized = this._super.apply(this, arguments);
            var applicationSerializer = this.store.serializerFor('application');

            serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');
            serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, RESOURCE_EDIT_PERMISSION_GROUPS, 'editNestedPermissionsGroups', 'resourcesEdit%@PermissionGroups');

            // // Rename resourceManageBookingsPermissionGroups to manageBookingsPermissionGroups
            // // Rename resourceViewCalendarsPermissionGroups to viewCalendarsPermissionGroups
            // serialized.manageBookingsPermissionGroups = serialized.resourcesManageBookingsPermissionGroups;
            // serialized.viewCalendarsPermissionGroups = serialized.resourceViewCalendarsPermissionGroups;

            // delete serialized.manageBookingsPermissionGroups;
            // delete serialized.resourceViewCalendarsPermissionGroups;
            // Strip out unecessary resource details
            var rIds = snapshot.record.get('resourceIds') || [];
            var resourceIds = rIds.map(function (resource) {
                return {
                    identity: _ember['default'].get(resource, 'id'),
                    name: _ember['default'].get(resource, 'name')
                };
            });

            serialized.resourceIdentities = resourceIds;

            return serialized;
        }
    });
});