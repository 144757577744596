define('scientia-resourcebooker/pods/logged-in/notifications/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/page-changer'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsPageChanger) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsPageChanger['default'], {

    notificationRequestProcessor: _ember['default'].inject.service(),
    notificationsFetcher: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    notification: null,

    showAcceptModal: null,
    showRejectModal: null,
    showMoreInfoModal: null,
    showNotesModal: null,

    loadingBookingRequest: false,
    isLoadingNotifications: false,

    isRejecting: false,
    isConfirmingMoreInfo: false,

    hasNotifications: _ember['default'].computed.gt('model.results.length', 0),
    hasSearchOptions: _ember['default'].computed.gt('searchOptionsSelected.length', 0),

    shouldShowPaging: _ember['default'].computed.gt('model.results.length', 0),

    queryParams: ['page', { selectedTab: 'type' }, 'perPage'],
    perPage: 10,
    page: 1,
    selectedTab: 3,

    toApproveCount: 0,
    moreInfoActionCount: 0,

    searchQuery: '',
    lastSearchOptionSelected: null,
    searchOptionsSelected: [],
    historyBookingId: null,

    notificationTypes: ['event_name', 'notification_type', 'booker_name', 'resource_name', 'booking_reference', 'actioned_by', 'booking_date_start_range', 'booking_date_end_range'],

    searchOptions: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var localePath = 'components.notification_searcher.search_options';
      return this.get('notificationTypes').map(function (type) {
        return { value: type, id: type, name: _this.get('i18n').t(localePath + '.' + type) };
      });
    }),

    selectedTabChanged: _ember['default'].observer('selectedTab', function () {
      _ember['default'].run.once(this, function () {
        this.set('page', 1);
      });
    }),

    // The current timeframe selected
    timeframeSelected: null,

    timeframeSelectedLabel: _ember['default'].computed('timeframeSelected', 'startDateTime', 'endDateTime', function () {
      var timeframe = this.get('timeframeSelected');
      if (timeframe !== 'specific_range') return this.get('i18n').t('components.date_period_selector.' + timeframe);

      var dateFormat = this.get('i18n').t('components.date_period_selector.specific_range_date_format');
      return this.get('startDateTime').format(dateFormat) + ' - ' + this.get('endDateTime').format(dateFormat);
    }),

    // The dates related to the time frame
    startDateTime: null,
    endDateTime: null,

    // Temporary timeframe modal properties
    _modalTimeframeSelected: null,
    _modalStartDateTime: null,
    _modalEndDateTime: null,

    // list of available timeframe types
    timeframeTypes: _ember['default'].computed('i18n.locale', function () {
      var _this2 = this;

      var labels = ['today', 'last_seven_days', 'last_month', 'specific_range'];
      return labels.map(function (label) {
        return {
          label: _this2.get('i18n').t('components.date_period_selector.' + label),
          value: label
        };
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('timeframeSelected', 'last_seven_days');
    },

    _getAdjustedTimeframeDates: function _getAdjustedTimeframeDates(start, end) {
      if (!start) {
        if (end) start = end.clone().startOf('day');
      }

      if (!end) {
        end = start.clone().endOf('day');
      }

      if (end && start && end.isBefore(start)) {
        end = start.clone().endOf('day');
      }

      return { startDate: start, endDate: end };
    },
    actions: {

      searchOptionChanged: function searchOptionChanged(value) {
        if (value === 'notification_type' || this.get('lastSearchOptionSelected') === 'notification_type') {
          this.set('searchQuery', '');
        }

        this.set('lastSearchOptionSelected', value);
      },

      searchNotifications: function searchNotifications() {

        if (_ember['default'].isEmpty(this.get('lastSearchOptionSelected')) || _ember['default'].isEmpty(this.get('searchQuery'))) return;

        var searchOption = this.get('lastSearchOptionSelected');
        var searchQuery = this.get('searchQuery');
        var searchOptionsSelected = this.get('searchOptionsSelected');

        if (_ember['default'].isEmpty(searchOptionsSelected)) this.set('isLoadingNotifications', true);

        var existingSearchExists = searchOptionsSelected.findBy('key', searchOption);
        if (existingSearchExists) searchOptionsSelected.removeObject(existingSearchExists);

        searchOptionsSelected.pushObject(_ember['default'].Object.create({
          key: searchOption,
          name: this.get('i18n').t('components.notification_searcher.search_options.' + searchOption),
          value: searchQuery }));

        this.setProperties({
          lastSearchOptionSelected: null,
          searchQuery: '',
          page: 1 });

        this.send('reload');
      },

      clearSearch: function clearSearch() {
        this.setProperties({
          searchOptionsSelected: [],
          page: 1
        });
        this.send('reload');
      },

      removeSearchTerm: function removeSearchTerm(key) {
        var searches = this.get('searchOptionsSelected');
        var toRemove = searches.findBy('key', key);
        searches.removeObject(toRemove);
        this.set('page', 1);

        this.send('reload');
      },

      onTimeframeChange: function onTimeframeChange(rangeType) {
        this.set('timeframeSelected', rangeType);
        if (rangeType === 'specific_range') {
          this.setProperties({
            startDateTime: moment().startOf('day'),
            endDateTime: moment().add(6, 'days').endOf('day')
          });
          return;
        } else {

          var start = undefined;
          var end = undefined;
          switch (rangeType) {

            case 'today':
              start = moment().startOf('day');
              end = moment().endOf('day');
              break;

            case 'last_seven_days':
              start = moment().subtract(6, 'days').startOf('day');
              end = moment().endOf('day');
              break;

            case 'last_month':
              start = moment().subtract(1, 'month').startOf('day');
              end = moment().endOf('day');
              break;

            default:
              break;
          }
          this.setProperties({
            startDateTime: start,
            endDateTime: end
          });

          // this.send('reload');
        }
      },

      onTimeframeDatesChange: function onTimeframeDatesChange(start, end) {
        var _getAdjustedTimeframeDates2 = this._getAdjustedTimeframeDates(start, end);

        var startDate = _getAdjustedTimeframeDates2.startDate;
        var endDate = _getAdjustedTimeframeDates2.endDate;

        this.setProperties({
          startDateTime: startDate,
          endDateTime: endDate
        });
      },

      // #region Modal Timeframe actions

      // When opening timeframe modal set initial modal properties
      onShowTimeframeModal: function onShowTimeframeModal() {
        var timeframeSelected = this.get('timeframeSelected');
        this.setProperties({
          _modalTimeframeSelected: timeframeSelected,
          _modalStartDateTime: timeframeSelected === 'specific_range' ? this.get('startDateTime') : moment().startOf('day'),
          _modalEndDateTime: timeframeSelected === 'specific_range' ? this.get('endDateTime') : moment().endOf('day'),
          showTimeframeModal: true
        });
      },

      // Update dates for timeframe modal
      onTimeframeModalDatesChange: function onTimeframeModalDatesChange(start, end) {
        var _getAdjustedTimeframeDates3 = this._getAdjustedTimeframeDates(start, end);

        var startDate = _getAdjustedTimeframeDates3.startDate;
        var endDate = _getAdjustedTimeframeDates3.endDate;

        this.setProperties({
          _modalStartDateTime: startDate,
          _modalEndDateTime: endDate
        });
      },

      // Update timeframe for timeframe modal
      onTimeframeModalTimeframeChange: function onTimeframeModalTimeframeChange(timeframe) {
        this.set('_modalTimeframeSelected', timeframe);
      },

      // When cancelling modal just close it and reset properties to null
      onTimeframeModalCancel: function onTimeframeModalCancel() {
        this.setProperties({
          showTimeframeModal: false,
          _modalTimeframeSelected: null,
          _modalStartDateTime: null,
          _modalEndDateTime: null
        });
      },

      onTimeframeModalConfirm: function onTimeframeModalConfirm() {
        // set new timeframe,start and end date
        // reload reports

        this.set('showTimeframeModal', false);

        var modalTimeframeSelected = this.get('_modalTimeframeSelected');
        this.send('onTimeframeChange', modalTimeframeSelected);

        if (modalTimeframeSelected === 'specific_range') {
          this.send('onTimeframeDatesChange', this.get('_modalStartDateTime'), this.get('_modalEndDateTime'));
        }

        this.send('reload');
      },

      // #region timeframe modal
      /*****************************
      * /END TIMEFRAME MODAL ACTIONS
      ******************************/

      acceptRequest: function acceptRequest(request) {
        this.set('showAcceptModal', request);
        // this.send('confirmAcceptRequest', request);
      },

      rejectRequest: function rejectRequest(request) {
        this.set('showRejectModal', request);
      },

      moreInfoRequest: function moreInfoRequest(request) {
        this.set('showMoreInfoModal', request);
      },

      viewNotes: function viewNotes(request) {
        this.set('showNotesModal', request);
      },

      confirmAcceptRequest: function confirmAcceptRequest() {
        var _this3 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isAccepting', true);
        var notificationId = this.get('showAcceptModal.eventId');

        this.get('notificationRequestProcessor').accept(this.get('showAcceptModal'), note).then(function () {
          _this3.flashSuccess(_this3.get('i18n').t('components.notification.flash_messages.accepted_booking'));
          _ember['default'].$('.notificationTab--accepted').addClass('dc-tabs-tabChanged');
          _ember['default'].run.later(_this3, function () {
            _ember['default'].$('.notificationTab--accepted').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);
        })['catch'](function () {
          _this3.flashError(_this3.get('i18n').t('components.notification.flash_messages.error_accept_booking'));
        })['finally'](function () {
          _this3.set('isAccepting', false);
          _this3.set('showAcceptModal', false);
          _this3.send('reload');
        });
      },

      showRejectModal: function showRejectModal() {
        var _this4 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isRejecting', true);
        var notificationId = this.get('showRejectModal.eventId');

        this.get('notificationRequestProcessor').reject(this.get('showRejectModal'), note).then(function () {
          _this4.set('showRejectModal', null);
          _this4.flashSuccess(_this4.get('i18n').t('components.notification.flash_messages.rejected_booking'));

          _ember['default'].$('.notificationTab--rejected').addClass('dc-tabs-tabChanged');

          _ember['default'].run.later(_this4, function () {
            _ember['default'].$('.notificationTab--rejected').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);

          _this4.send('reload');
        })['catch'](function () {
          _this4.flashError(_this4.get('i18n').t('components.notification.flash_messages.error_reject_booking'));
        })['finally'](function () {
          _this4.set('isRejecting', false);
        });
      },

      showMoreInfoModal: function showMoreInfoModal() {
        var _this5 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isConfirmingMoreInfo', true);
        var notificationId = this.get('showMoreInfoModal.eventId');

        this.get('notificationRequestProcessor').moreInfo(this.get('showMoreInfoModal'), note).then(function () {
          _this5.set('showMoreInfoModal', null);
          _this5.flashSuccess(_this5.get('i18n').t('components.notification.flash_messages.moreinfo_booking'));

          _ember['default'].$('.notificationTab--more-info').addClass('dc-tabs-tabChanged');

          _ember['default'].run.later(_this5, function () {
            _ember['default'].$('.notificationTab--more-info').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);

          _this5.send('reload');
        })['catch'](function () {
          _this5.flashError(_this5.get('i18n').t('components.notification.flash_messages.error_moreinfo_booking'));
        })['finally'](function () {
          _this5.set('isConfirmingMoreInfo', false);
        });
      },

      openEventOverview: function openEventOverview(request) {
        var _this6 = this;

        this.set('loadingBookingRequest', request.eventId);

        var bookingRequestCached = this.store.peekAll('booking-request').findBy('id', request.eventId);
        if (bookingRequestCached) {
          this.store.unloadRecord(bookingRequestCached);
        }

        this.store.find('booking-request', request.eventId).then(function (bookingRequest) {
          _this6.set('historyBookingId', request.eventId);
          _this6.set('loadingBookingRequest', false);
          _this6.set('showBookingRequestActions', false);
          _this6.set('showBookingRequest', bookingRequest);
        })['catch'](function () {
          _this6.flashError(_this6.get('i18n').t('components.notification.flash_messages.unable_to_fetch_booking'));
          _this6.set('loadingBookingRequest', false);
        });
      }

    }
  });
});