define('scientia-resourcebooker/models/booking-form-field-multiple-choice', ['exports', 'ember', 'ember-data/attr', 'scientia-resourcebooker/models/booking-form-field', 'model-fragments/attributes', 'ember-cp-validations'], function (exports, _ember, _emberDataAttr, _scientiaResourcebookerModelsBookingFormField, _modelFragmentsAttributes, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    externalDataSourceUri: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: _ember['default'].computed.not('model.isUsingExternalDataSource')
    }), (0, _emberCpValidations.validator)('bookingFormFieldExternalDataSourceUri', {
      disabled: _ember['default'].computed.not('model.isUsingExternalDataSource')
    })],

    items: [(0, _emberCpValidations.validator)('uniqueItemValue', {
      dependentKeys: ['model.items.@each.value'],
      disabled: _ember['default'].computed.bool('model.isUsingExternalDataSource')
    }), (0, _emberCpValidations.validator)('emptyItemValueText', {
      dependentKeys: ['model.items.@each.value', 'model.items.@each.text'],
      disabled: _ember['default'].computed.bool('model.isUsingExternalDataSource') })],

    minimum: [(0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true,
      lte: _ember['default'].computed.readOnly('model.maximum'),
      messageKey: 'errors.minimumIsLowerThanMaximum',
      disabled: _ember['default'].computed('model.isManaging', 'model.isMinMaxRestricted', 'model.maximum', 'model.minimum', function () {
        return !this.get('model.isManaging') || !this.get('model.isMinMaxRestricted') || parseInt(this.get('model.maximum')) === 0 || !this.get('model.maximum') || parseInt(this.get('model.minimum')) === 0 || !this.get('model.minimum');
      })
    })]

  });

  var ValidationsUser = (0, _emberCpValidations.buildValidations)({
    value: {
      validators: [(0, _emberCpValidations.validator)('multipleChoiceMinMax', {
        dependentKeys: ['model.value.length'],
        disabled: _ember['default'].computed(function () {
          return !this.get('model.isActive') || this.get('model.isManaging');
        })

      })]
    }
  });

  exports['default'] = _scientiaResourcebookerModelsBookingFormField['default'].extend(Validations, ValidationsUser, {
    isUsingExternalDataSource: (0, _emberDataAttr['default'])('boolean'),
    externalDataSourceIsSearchable: (0, _emberDataAttr['default'])('boolean'),
    externalDataSourceUri: (0, _emberDataAttr['default'])('string'),
    items: (0, _modelFragmentsAttributes.fragmentArray)('booking-form-field-multiple-choice-item', { defaultValue: [] }),
    minimum: (0, _emberDataAttr['default'])('number'),
    maximum: (0, _emberDataAttr['default'])('number'),
    isMinMaxRestricted: (0, _emberDataAttr['default'])('boolean'),
    isListType: (function () {
      return true;
    }).property(),
    isMandatory: (function () {
      return this.get('isMinMaxRestricted') && parseInt(this.get('minimum')) >= 1;
    }).property('minimum', 'isMinMaxRestricted')
  });
});