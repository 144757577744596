define('scientia-resourcebooker/pods/components/manage-resources/booking-type-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'component.manage_resources.booking_type_form';

  exports['default'] = _ember['default'].Component.extend({
    item: null,
    submitAction: null,
    localePrefix: DEFAULT_LOCALE_PREFIX,
    disabled: false,

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property(),

    actions: {

      onSearchBookingForm: function onSearchBookingForm(query, deferred) {
        this.attrs.searchBookingForm(query.term, deferred);
      },

      onSelectBookingForm: function onSelectBookingForm(bookingForm) {
        this.set('bookingForm', { identity: bookingForm.get('id'), name: bookingForm.get('name') });
      }
    }

  });
});