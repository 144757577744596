define("scientia-resourcebooker/pods/components/modal-header/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/modal-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "aria-label", "Minimize/Expand");
          dom.setAttribute(el1, "class", "button button--icon at-lf-dialog-header at-lf-dialog-header--minimize");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["toggleMinimize"], [], ["loc", [null, [6, 12], [6, 39]]], 0, 0], ["inline", "inline-svg", [["subexpr", "if", [["get", "isMinimized", ["loc", [null, [6, 178], [6, 189]]], 0, 0, 0, 0], "icons/maximize.svg", "icons/minimize.svg"], [], ["loc", [null, [6, 174], [6, 232]]], 0, 0]], ["class", "lf-dialog-header-toolbar-icon lf-dialog-header-toolbar-icon--minimize"], ["loc", [null, [6, 161], [6, 312]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/modal-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "type", "button");
          dom.setAttribute(el1, "aria-label", "Close");
          dom.setAttribute(el1, "class", "button button--icon at-lf-dialog-header at-lf-dialog-header--close");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["close"], [], ["loc", [null, [9, 12], [9, 30]]], 0, 0], ["inline", "inline-svg", ["icons/cross.svg"], ["class", "lf-dialog-header-toolbar-icon lf-dialog-header-toolbar-icon--danger"], ["loc", [null, [9, 139], [9, 247]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/modal-header/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "modal-header-content");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "lf-dialog-header-toolbar");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element2);
        morphs[1] = dom.createMorphAt(element2, 0, 0);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 2, 2);
        return morphs;
      },
      statements: [["element", "action", ["onHeaderClick"], [], ["loc", [null, [1, 34], [1, 60]]], 0, 0], ["content", "yield", ["loc", [null, [1, 61], [1, 70]]], 0, 0, 0, 0], ["block", "if", [["get", "needsMinimizeButton", ["loc", [null, [5, 8], [5, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 2], [7, 9]]]], ["block", "if", [["get", "needsCloseButton", ["loc", [null, [8, 8], [8, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [8, 2], [10, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});