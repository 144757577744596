define("scientia-resourcebooker/pods/logged-in/booking-request/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["calendar-download"], ["showIcalRegenerateTokenModal", ["subexpr", "action", ["showIcalRegenerateTokenModal"], [], ["loc", [null, [8, 35], [8, 74]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [9, 25], [9, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 46]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 53]]], 0, 0]], ["loc", [null, [6, 9], [9, 54]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [10, 32], [10, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 27], [10, 53]]], 0, 0], false], [], ["loc", [null, [10, 19], [10, 60]]], 0, 0]], ["loc", [null, [5, 7], [10, 61]]], 0, 0]], ["loc", [null, [3, 2], [11, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["confirmMessage", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_confirm"], [], ["loc", [null, [20, 23], [20, 86]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_title"], [], ["loc", [null, [21, 21], [21, 82]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [22, 27], [22, 55]]], 0, 0, 0, 0], false], [], ["loc", [null, [22, 22], [22, 62]]], 0, 0]], [], ["loc", [null, [22, 14], [22, 63]]], 0, 0], "confirm", ["subexpr", "action", ["regenerateTokenConfirm"], [], ["loc", [null, [23, 16], [23, 49]]], 0, 0]], ["loc", [null, [18, 11], [23, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 39], [24, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 29], [24, 44]]], 0, 0]], [], ["loc", [null, [24, 21], [24, 45]]], 0, 0]], ["loc", [null, [17, 9], [24, 46]]], 0, 0]], ["loc", [null, [15, 4], [25, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [34, 12], [34, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", ["subexpr", "@mut", [["get", "showBookingRequestActions", ["loc", [null, [35, 32], [35, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [36, 25], [36, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 20], [36, 44]]], 0, 0], false], [], ["loc", [null, [36, 12], [36, 51]]], 0, 0], "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [37, 20], [37, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [38, 18], [38, 47]]], 0, 0]], ["loc", [null, [32, 9], [38, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [40, 32], [40, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 27], [40, 51]]], 0, 0], false], [], ["loc", [null, [40, 19], [40, 58]]], 0, 0]], ["loc", [null, [31, 7], [40, 59]]], 0, 0]], ["loc", [null, [29, 2], [40, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [49, 12], [49, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [47, 9], [49, 34]]], 0, 0]], ["loc", [null, [46, 7], [49, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [50, 37], [50, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 27], [50, 42]]], 0, 0]], [], ["loc", [null, [50, 19], [50, 43]]], 0, 0]], ["loc", [null, [44, 2], [51, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 0
            },
            "end": {
              "line": 65,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [60, 12], [60, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [61, 25], [61, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [61, 20], [61, 64]]], 0, 0], null], [], ["loc", [null, [61, 12], [61, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [62, 14], [62, 58]]], 0, 0]], ["loc", [null, [58, 9], [62, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [63, 32], [63, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 27], [63, 71]]], 0, 0], null], [], ["loc", [null, [63, 19], [63, 77]]], 0, 0]], ["loc", [null, [57, 7], [63, 78]]], 0, 0]], ["loc", [null, [55, 2], [64, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 67,
              "column": 0
            },
            "end": {
              "line": 80,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [73, 25], [73, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 20], [73, 59]]], 0, 0], false], [], ["loc", [null, [73, 12], [73, 66]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_title"], [], ["loc", [null, [74, 19], [74, 95]]], 0, 0], "singleButtonMode", true, "confirmMessage", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_message"], [], ["loc", [null, [76, 21], [76, 99]]], 0, 0], "confirm", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [77, 27], [77, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [77, 22], [77, 61]]], 0, 0], false], [], ["loc", [null, [77, 14], [77, 68]]], 0, 0]], ["loc", [null, [71, 9], [77, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [78, 32], [78, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 27], [78, 66]]], 0, 0], null], [], ["loc", [null, [78, 19], [78, 72]]], 0, 0]], ["loc", [null, [70, 7], [78, 73]]], 0, 0]], ["loc", [null, [68, 2], [79, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 0
            },
            "end": {
              "line": 95,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["withBookingRequest", ["subexpr", "@mut", [["get", "withBookingRequest", ["loc", [null, [87, 25], [87, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [88, 21], [88, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [89, 22], [89, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "nameWithPrimaryResourceNames", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [91, 25], [91, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [91, 20], [91, 50]]], 0, 0], null], [], ["loc", [null, [91, 12], [91, 56]]], 0, 0], "on-event-click", ["subexpr", "action", ["openBookingRequestOverview"], [], ["loc", [null, [92, 21], [92, 58]]], 0, 0]], ["loc", [null, [85, 9], [92, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [93, 32], [93, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [93, 27], [93, 57]]], 0, 0], null], [], ["loc", [null, [93, 19], [93, 63]]], 0, 0]], ["loc", [null, [84, 7], [93, 64]]], 0, 0]], ["loc", [null, [83, 2], [94, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 10
                },
                "end": {
                  "line": 118,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "button-print", [], ["onClick", ["subexpr", "action", ["onPrintClick"], [], ["loc", [null, [117, 35], [117, 58]]], 0, 0], "location", "at-bookingRequestsIndex"], ["loc", [null, [117, 12], [117, 95]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 115,
                "column": 8
              },
              "end": {
                "line": 124,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [116, 20], [116, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [116, 10], [118, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 128,
                "column": 8
              },
              "end": {
                "line": 130,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--primary at-downloadCalendar");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["showDownloadCalendar"], [], ["loc", [null, [129, 18], [129, 51]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "at-downloadCalendar-icon"], ["loc", [null, [129, 103], [129, 171]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.subscribe"], [], ["loc", [null, [129, 177], [129, 231]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 132,
                "column": 8
              },
              "end": {
                "line": 132,
                "column": 48
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "help-trigger", ["loc", [null, [132, 32], [132, 48]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 4
            },
            "end": {
              "line": 135,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle--align-right at-bookingRequest");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          morphs[3] = dom.createMorphAt(element5, 6, 6);
          morphs[4] = dom.createMorphAt(element5, 8, 8);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [111, 32], [111, 95]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [112, 24], [112, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", ["subexpr", "action", ["submitSearch"], [], ["loc", [null, [112, 41], [112, 64]]], 0, 0], "placeholder", ["subexpr", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [112, 77], [112, 138]]], 0, 0], "class", "form-input form-input--white sectionTitle-search-input at-bookingRequest"], ["loc", [null, [112, 10], [112, 221]]], 0, 0], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [115, 18], [115, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [115, 8], [124, 19]]]], ["block", "unless", [["get", "icalDisableBookings", ["loc", [null, [128, 18], [128, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [128, 8], [130, 19]]]], ["block", "unless", [["get", "media.isTiny", ["loc", [null, [132, 18], [132, 30]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [132, 8], [132, 59]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child8 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 147,
                "column": 8
              },
              "end": {
                "line": 150,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "rb-agenda-view", [], ["results", ["subexpr", "@mut", [["get", "model.searchResults.results", ["loc", [null, [148, 35], [148, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-bookingRequest", "onEventClick", "openBookingRequestOverview"], ["loc", [null, [148, 10], [148, 132]]], 0, 0], ["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.currentPage", ["loc", [null, [149, 39], [149, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [149, 98], [149, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "totalPages", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.totalPages", ["loc", [null, [149, 141], [149, 185]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [149, 10], [149, 187]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 137,
              "column": 4
            },
            "end": {
              "line": 152,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestSearch");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "bookingRequestSearch-title");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("em");
          dom.setAttribute(el4, "class", "bookingRequestSearch-title-query");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.results_for"], [], ["loc", [null, [141, 12], [141, 68]]], 0, 0], ["content", "query", ["loc", [null, [142, 57], [142, 66]]], 0, 0, 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [144, 18], [144, 42]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.clear_search"], [], ["loc", [null, [144, 58], [144, 115]]], 0, 0], ["block", "if", [["get", "model.searchResults.results.length", ["loc", [null, [147, 14], [147, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [147, 8], [150, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child9 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 152,
                "column": 4
              },
              "end": {
                "line": 156,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.booking_request.index.searchLoading"], [], ["loc", [null, [154, 8], [154, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 158,
                  "column": 6
                },
                "end": {
                  "line": 175,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [160, 25], [160, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "calendarEvents", ["subexpr", "@mut", [["get", "model.bookings", ["loc", [null, [162, 25], [162, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "event-id-path", "id", "event-title-path", "nameWithPrimaryResourceNames", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "status", "show-tag-button", false, "on-view-type-changed", ["subexpr", "action", ["onViewTypeChanged"], [], ["loc", [null, [169, 31], [169, 59]]], 0, 0], "on-event-click", "openBookingRequestOverview", "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-sheet-change", "fetchBookingRequests"], ["loc", [null, [159, 8], [174, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 156,
                "column": 4
              },
              "end": {
                "line": 177,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [158, 12], [158, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [158, 6], [175, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 152,
              "column": 4
            },
            "end": {
              "line": 177,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showSearchLoading", ["loc", [null, [152, 14], [152, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [152, 4], [177, 4]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 180,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("main");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-bookingRequest");
        dom.setAttribute(el2, "id", "main-section");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [15, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
        morphs[7] = dom.createMorphAt(element7, 1, 1);
        morphs[8] = dom.createMorphAt(element7, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "showDownloadCalendar", ["loc", [null, [2, 6], [2, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [12, 7]]]], ["block", "if", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [14, 6], [14, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 0], [26, 7]]]], ["block", "if", [["get", "showBookingRequest", ["loc", [null, [28, 6], [28, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [41, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [43, 6], [43, 27]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [43, 0], [52, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [54, 6], [54, 44]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [54, 0], [65, 7]]]], ["block", "if", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [67, 6], [67, 39]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [67, 0], [80, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [82, 6], [82, 30]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [82, 0], [95, 7]]]], ["block", "section-title", [], ["icon", "icons/calendar.svg", "text", ["subexpr", "t", ["pods.logged_in.booking_request.index.my_bookings"], [], ["loc", [null, [102, 9], [102, 63]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [105, 16], [105, 75]]], 0, 0], "clickAction", "reloadView", "location", "at-bookingRequest"], 7, null, ["loc", [null, [100, 4], [135, 22]]]], ["block", "if", [["get", "showSearchResults", ["loc", [null, [137, 10], [137, 27]]], 0, 0, 0, 0]], [], 8, 9, ["loc", [null, [137, 4], [177, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});