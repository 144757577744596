define("scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 261
              },
              "end": {
                "line": 8,
                "column": 372
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/repeat.svg"], ["class", "bookingRequestOverview-recurrence-icon"], ["loc", [null, [8, 292], [8, 372]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "data-span", "true");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "date-span", "true");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'datetime');
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createAttrMorph(element3, 'datetime');
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element3, 2, 2);
          morphs[5] = dom.createMorphAt(element3, 4, 4);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDateTime", ["loc", [null, [7, 40], [7, 59]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [7, 102], [7, 121]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateTimeFormat", ["loc", [null, [7, 129], [7, 150]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 84], [7, 152]]], 0, 0], ["attribute", "datetime", ["get", "model.endDateTime", ["loc", [null, [8, 40], [8, 57]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [8, 100], [8, 117]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateFormat", ["loc", [null, [8, 125], [8, 142]]], 0, 0, 0, 0]], [], [], 0, 0], "subtractDayOnMidnight", true], ["loc", [null, [8, 82], [8, 171]]], 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [8, 190], [8, 207]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [8, 215], [8, 232]]], 0, 0, 0, 0]], [], [], 0, 0], "midnightAsTwentyFour", true], ["loc", [null, [8, 172], [8, 260]]], 0, 0], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [8, 267], [8, 290]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 261], [8, 379]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 14,
                "column": 115
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["icons/repeat.svg"], ["class", "bookingRequestOverview-recurrence-icon"], ["loc", [null, [14, 35], [14, 115]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 15,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-date");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-start");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" -\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("time");
          dom.setAttribute(el1, "class", "timeDate-end");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'datetime');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          return morphs;
        },
        statements: [["attribute", "datetime", ["get", "model.startDateTime", ["loc", [null, [10, 23], [10, 42]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [10, 85], [10, 104]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingDateFormat", ["loc", [null, [10, 112], [10, 129]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 67], [10, 131]]], 0, 0], ["inline", "datetime-format", [["get", "model.startDateTime", ["loc", [null, [11, 53], [11, 72]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [11, 80], [11, 97]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 35], [11, 99]]], 0, 0], ["inline", "datetime-format", [["get", "model.endDateTime", ["loc", [null, [12, 51], [12, 68]]], 0, 0, 0, 0]], ["format", ["subexpr", "@mut", [["get", "bookingTimeFormat", ["loc", [null, [12, 76], [12, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "midnightAsTwentyFour", true], ["loc", [null, [12, 33], [12, 121]]], 0, 0], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [14, 10], [14, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 4], [14, 122]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 121
              },
              "end": {
                "line": 17,
                "column": 241
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("(");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(": ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(")");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_overview.occurrences"], [], ["loc", [null, [17, 162], [17, 217]]], 0, 0], ["content", "occurrencesLength", ["loc", [null, [17, 219], [17, 240]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 256
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestOverview-recurrenceDescription");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["content", "recurrenceDescription", ["loc", [null, [17, 95], [17, 120]]], 0, 0, 0, 0], ["block", "if", [["get", "showOccurrencesCountAfterPattern", ["loc", [null, [17, 127], [17, 159]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 121], [17, 248]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "button-print", [], ["onClick", ["subexpr", "route-action", ["onPrintClick", ["subexpr", "hash", [], ["printType", "booking", "model", ["get", "model", ["loc", [null, [21, 90], [21, 95]]], 0, 0, 0, 0]], ["loc", [null, [21, 58], [21, 96]]], 0, 0]], [], ["loc", [null, [21, 29], [21, 97]]], 0, 0], "location", "at-bookingRequestOverview"], ["loc", [null, [21, 6], [21, 136]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [20, 14], [20, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 4], [22, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "bookingRequestOverview-timeDate-left");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bookingRequestOverview-timeDate-right");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(element4, 3, 3);
        morphs[3] = dom.createMorphAt(element4, 5, 5);
        return morphs;
      },
      statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "bookingRequestOverview-timeDate-icon"], ["loc", [null, [2, 2], [2, 82]]], 0, 0], ["block", "if", [["get", "spansMultipleDays", ["loc", [null, [6, 10], [6, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 4], [15, 11]]]], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [17, 10], [17, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [17, 4], [17, 263]]]], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [19, 12], [19, 26]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [19, 2], [23, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});