define('scientia-resourcebooker/pods/components/notification-searcher/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sectionTitle-search notificationSearcher-wrapper'],

    content: [],
    optionSelected: null,
    query: '',

    dateFields: ['booking_date_start_range', 'booking_date_end_range'],

    isDateInput: _ember['default'].computed('optionSelected', function () {
      if (this.get('dateFields').includes(this.get('optionSelected'))) {
        this.set('query', moment());
        return true;
      } else {
        if (moment(this.get('query')).isValid()) this.set('query', '');
        return false;
      }
    }),

    isNotificationTypeInput: _ember['default'].computed('optionSelected', function () {
      return this.get('optionSelected') === 'notification_type';
    }),

    notificationTypes: (function () {
      var mapped = [{ id: 1, value: 32, name: this.get('i18n').t('notification_types.more_info') }, { id: 2, value: 2, name: this.get('i18n').t('notification_types.requested') }, { id: 3, value: 8, name: this.get('i18n').t('notification_types.accepted') }, { id: 4, value: 16, name: this.get('i18n').t('notification_types.rejected') }, { id: 5, value: 1, name: this.get('i18n').t('notification_types.cancelled') }];

      return mapped;
    }).property(),

    actions: {

      search: function search() {
        this.sendAction('search');
      },

      updateDate: function updateDate(value) {
        this.set('query', value);
      }
    }
  });
});