define('scientia-resourcebooker/pods/components/recurring-event-fields/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var DAILY = 0;
  var WEEKLY = 1;
  var MONTHLY = 2;
  // const YEARLY = 3;

  var UNTIL_NR_OCCURRENCES = 0;
  var UNTIL_DATE = 1;

  var HOUR_FORMAT = 'HH:mm';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recurringEventFields'],
    classNameBindings: ['class'],

    i18n: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    dateTimeHelpers: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),

    calendarOptions: null,
    contentFromTime: null,
    contentToTime: null,
    canShowTemplate: false,

    init: function init() {
      this._super.apply(this, arguments);

      this._autoAdjustTimes();
      this.send('updateContentFromTime');
      this.send('updateContentToTime');

      // This is an attempt to fix an issue with Firefox not refreshing the startDate value after it has been adjusted
      _ember['default'].run.later(this, function () {
        if (this.get('isDestroying') || this.get('isDestroyed')) return;
        this.set('canShowTemplate', true);
      }, 200);
    },

    useOldTimePicker: _ember['default'].computed.alias('systemSettings.options.bookingUseSplitTimePicker'),

    // Used by oldTimePicker
    startHour: _ember['default'].computed('calendarOptions.calendarStartTime', function () {
      return this.get('dateTimeHelpers').getStartHourFromTime(this.get('calendarOptions.calendarStartTime'));
    }),

    // Used by oldTimePicker
    endHour: _ember['default'].computed('calendarOptions.calendarEndTime', function () {
      return this.get('dateTimeHelpers').getEndHourFromTime(this.get('calendarOptions.calendarEndTime'));
    }),

    toTimeStartTimeRange: _ember['default'].computed('calendarOptions.calendarStartTime', 'model.fromTime', function () {

      var calendarStart = this.get('calendarOptions.calendarStartTime');
      var fromTime = this.get('model.fromTime');

      if (fromTime) {
        return this.get('dateTimeHelpers').getNextTimePeriod(fromTime, this.get('calendarOptions'), true);
      } else {
        return this.get('dateTimeHelpers').getNextTimePeriod(calendarStart, this.get('calendarOptions'), true);
      }
    }),

    isDaily: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === DAILY;
    }),

    isWeekly: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === WEEKLY;
    }),

    isMonthly: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === MONTHLY;
    }),

    recurrenceTypeStringKey: (function () {
      var type = this.get('model.recurrenceTypeSelected') || 0;
      var locs = {
        0: 'days',
        1: 'weeks',
        2: 'months',
        3: 'years'
      };

      return locs[type];
    }).property('model.recurrenceTypeSelected', 'i18n.locale'),

    maxDateForUntilDate: null,

    recurrenceTypes: (function () {
      return [{
        id: DAILY,
        value: DAILY,
        label: this.get('i18n').t('components.recurring_event_fields.daily').toString()
      }, {
        id: WEEKLY,
        value: WEEKLY,
        label: this.get('i18n').t('components.recurring_event_fields.weekly').toString()
      }, {
        id: MONTHLY,
        value: MONTHLY,
        label: this.get('i18n').t('components.recurring_event_fields.monthly').toString()
      }
      /*,
      {
        id: YEARLY,
        value: YEARLY,
        label: t('components.recurring_event_fields.yearly')
      }*/
      ];
    }).property('i18n.locale'),

    untilOptions: (function () {
      return [{
        id: UNTIL_DATE,
        value: UNTIL_DATE,
        label: this.get('i18n').t('components.recurring_event_fields.until_date').toString()
      }, {
        id: UNTIL_NR_OCCURRENCES,
        value: UNTIL_NR_OCCURRENCES,
        label: this.get('i18n').t('components.recurring_event_fields.until_nr_occurrences').toString()
      }];
    }).property('i18n.locale'),

    recurrenceDescription: _ember['default'].computed('i18n.locale', 'model.recurrenceTypeSelected', 'model.startDate', 'model.interval', 'model.excludeWeekends', 'model.untilType', 'model.fromTime', 'model.toTime', 'model.numberOfOccurrences', 'model.recurringDaysOfWeek.length', 'model.datesOfMonth.length', 'model.recurrenceMonthlyInterval.length', 'model.dayOfWeek.length', 'model.untilDate', function () {

      return this.get('recurrenceProcessor').generateDescription(this.get('model'));
    }),

    fromTimeHour: _ember['default'].computed('model.fromTime', function () {

      if (this.get('model.fromTime')) {
        return parseInt(this.get('model.fromTime').split(':')[0]);
      }
    }),

    calendarDaysDisabled: _ember['default'].computed('calendarOptions.calendarShowWeekends', function () {
      var showWeekends = this.get('calendarOptions.calendarShowWeekends');
      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      return showWeekends ? null : weekendDays;
    }),

    _autoAdjustTimes: function _autoAdjustTimes() {
      var calendarOptions = this.get('calendarOptions');

      var startDateTime = this.get('model.startDate');
      var fromTime = this.get('model.fromTime');
      var toTime = this.get('model.toTime');

      if (!startDateTime || !fromTime || !toTime) return;

      var fullFromDate = this._mergeMomentDateAndTime(startDateTime, fromTime);
      var fullToDate = this._mergeMomentDateAndTime(startDateTime.clone(), toTime); // Always use same date for EndDate in Recurring (as they do not span days)

      var isBookingTimesValid = this.get('calendarOptionsHelper').isBookingTimesValidForCalendarOptions(fullFromDate, fullToDate, calendarOptions);

      if (fullToDate.isSameOrBefore(fullFromDate)) {
        fullToDate = fullFromDate.clone().add(1, 'hour');
      }

      if (!isBookingTimesValid) {
        var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions = this.get('calendarOptionsHelper').adjustBookingEditStartAndEndDateTimePerCalendarOptions(fullFromDate, fullToDate, calendarOptions, true);

        var _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2 = _slicedToArray(_get$adjustBookingEditStartAndEndDateTimePerCalendarOptions, 2);

        var adjustedStartDateTime = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[0];
        var adjustedEndDateTime = _get$adjustBookingEditStartAndEndDateTimePerCalendarOptions2[1];

        var adjustedFromTime = adjustedStartDateTime.format(HOUR_FORMAT);
        var adjustedToTime = adjustedEndDateTime.format(HOUR_FORMAT);
        this.get('model').setProperties({
          fromTime: adjustedFromTime,
          toTime: adjustedToTime === '00:00' ? '24:00' : adjustedToTime
        });
      }
    },

    _mergeMomentDateAndTime: function _mergeMomentDateAndTime(date, time) {
      var result = date.clone();

      var _time$split = time.split(':');

      var _time$split2 = _slicedToArray(_time$split, 2);

      var hour = _time$split2[0];
      var minute = _time$split2[1];

      result.hour(hour).minute(minute);
      return result;
    },

    actions: {

      updateContentFromTime: function updateContentFromTime() {
        var fromDate = moment();
        var calendarOptions = this.get('calendarOptions');
        var result = this.get('calendarOptionsHelper').getTimeSlotsForStartTime(fromDate, calendarOptions);

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentFromTime', data);
      },

      updateContentToTime: function updateContentToTime() {
        var fromTime = this.get('model.fromTime') || this.get('calendarOptions.calendarStartTime');

        var _fromTime$split = fromTime.split(':');

        var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

        var h = _fromTime$split2[0];
        var m = _fromTime$split2[1];

        var fromDate = moment().hour(h).minute(m);

        var calendarOptions = this.get('calendarOptions');

        var toTime = this.get('toTime') || this.get('calendarOptions.calendarEndTime');

        var _toTime$split = toTime.split(':');

        var _toTime$split2 = _slicedToArray(_toTime$split, 2);

        var toHour = _toTime$split2[0];
        var toMinutes = _toTime$split2[1];

        var toDate = fromDate.clone().hour(toHour).minute(toMinutes);
        var result = this.get('calendarOptionsHelper').getTimeSlotsForEndTime(fromDate, toDate, calendarOptions);

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentToTime', data);
      },

      onSetFromTime: function onSetFromTime(fromTime) {
        this.set('model.fromTime', fromTime);

        var toTime = this.get('model.toTime');
        if (toTime && fromTime && this.get('dateTimeHelpers').isTimeBefore(toTime, fromTime, true)) {
          var _fromTime$split3 = fromTime.split(':');

          var _fromTime$split32 = _slicedToArray(_fromTime$split3, 2);

          var fromHour = _fromTime$split32[0];
          var fromMinutes = _fromTime$split32[1];

          var _toTime$split3 = toTime.split(':');

          var _toTime$split32 = _slicedToArray(_toTime$split3, 2);

          var toHour = _toTime$split32[0];
          var toMinutes = _toTime$split32[1];

          var startDate = moment().hour(fromHour).minute(fromMinutes);
          var endDate = startDate.clone().hour(toHour).minute(toMinutes);

          if (endDate.isSameOrBefore(startDate)) {
            endDate = startDate.clone().add(1, 'hour');
          }

          var calendarOptions = this.get('calendarOptions');
          var result = this.get('calendarOptionsHelper').adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions, false);
          var time = result.format('HH:mm');
          this.set('model.toTime', time === '00:00' ? '24:00' : time);
        }

        this.send('updateContentToTime');
      },

      onSetToTime: function onSetToTime(toTime) {
        this.set('model.toTime', toTime);
      },

      updateStartDate: function updateStartDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model') || !date) return;
        if (!date.isSame(this.get('model.startDate'))) {
          this.set('model.startDate', moment(date));
        }

        this.set('maxDateForUntilDate', date.clone().add(3, 'y'));

        if (!this.get('model.untilDate') || moment(this.get('model.untilDate')).isBefore(moment(date))) {
          this.set('model.untilDate', moment(date).add(1, 'w'));
        }
      },

      updateUntilDate: function updateUntilDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model') || !date) return;
        if (moment(date).isBefore(this.get('model.startDate'))) {
          date = moment(this.get('model.startDate'));
        }

        if (!date.isSame(this.get('model.untilDate'))) {
          this.set('model.untilDate', moment(date));
        }
      },

      updateRecurrenceTypeSelected: function updateRecurrenceTypeSelected(type) {
        this.set('model.recurrenceTypeSelected', parseInt(type));
      },

      updateUntilType: function updateUntilType(type) {
        var untilDate = this.get('model.untilDate');

        if (type === 1 && untilDate) {
          if (untilDate.format && untilDate.format('YYYY') === '0001') {
            this.set('model.untilDate', moment());
          }
        }
        this.set('model.untilType', type);
      },

      isValidUntilDate: function isValidUntilDate(date) {
        var startDate = this.get('model.startDate') || moment();
        return date.isAfter(startDate, 'd');
      }
    }

  });
});