define('scientia-resourcebooker/pods/components/resources-grid/component', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _ember['default'].Component.extend(_emberCan.CanMixin, {
    tagName: 'ul',
    classNames: ['resourcesGrid'],

    bookingTypes: null,
    currentUser: _ember['default'].inject.service(),

    isModal: false,

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    disabledResourceModal: null,

    disabledResourceMessage: _ember['default'].computed('disabledResourceModal.tileMessage', function () {
      return this.get('disabledResourceModal.tileMessage') || this.get('i18n').t('components.resources_grid.resource_disabled');
    }),

    confirmMessage: _ember['default'].computed('disabledResourceMessage', function () {
      var message = this.get('disabledResourceMessage');
      if (this.can('access admin')) message += '<p>' + this.get('i18n').t('components.resources_grid.resource_disabled_admin_extra') + '</p>';
      return _ember['default'].String.htmlSafe(message);
    }),

    actions: {
      onDisabledBookingTypeClick: function onDisabledBookingTypeClick(bookingType) {
        this.set('disabledResourceModal', bookingType);
      },

      onCloseDisabledResourceModal: function onCloseDisabledResourceModal() {
        if (this.get('isAdmin')) {
          this.sendAction('onConfirmNavigation', this.get('disabledResourceModal'));
          return;
        } else {
          this.set('disabledResourceModal', null);
        }
      }
    }

  });
});