define('scientia-resourcebooker/pods/components/rb-datepicker/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({
    systemSettings: _ember['default'].inject.service(),
    firstDay: null,
    lastDay: null,
    popupMode: false,
    closeOnSelection: false,
    direction: 'today-future',
    restrictToDayIndex: null,
    dayOutOfMonthClickable: false,
    blockDatesBefore: null,
    blockDatesAfter: null,

    blockDaysOfWeek: null, // ie [0, 6]
    months: 1,

    handleDaysChange: (function () {
      _ember['default'].run.next(this, function () {

        var validFirstDay = moment(this.get('firstDay'), this.get('i18n').t('components.date_input.date_format').toString(), true).isValid();
        var validLastDay = moment(this.get('lastDay'), this.get('i18n').t('components.date_input.date_format').toString(), true).isValid();

        if (validFirstDay && validLastDay) {
          var selected = this.get('firstDay') + ' - ' + this.get('lastDay');
          this.get('calendar').setSelected(selected);
        } else if (validFirstDay) {
          // Only set the value if its different
          var old = moment(this.get('calendar').getSelected(), 'YYYY-MM-DD');
          var selected = this.get('firstDay');

          // Make sure we apply all restrictions like blocking dates and limit to specific weekday
          selected = this._adjustDayByRestrictions(selected);
          if (!old.isSame(selected, 'day')) {
            this.get('calendar').setSelected(selected);
          }
        }
      });
    }).observes('firstDay', 'lastDay'),

    updateDatepicker: (function () {
      this.sendAction('updateSelectedDates');
      this._insertDatepicker();
    }).observes('spanMultipleDays'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._insertDatepicker();
    },

    willDestroyElement: function willDestroyElement() {
      if (!this.get('isDestroyed') && this.get('calendar') && this.get('calendar.destroy')) this.get('calendar').destroy();
    },

    _insertDatepicker: function _insertDatepicker() {
      var _this = this;

      if (!this.$()) return false;

      var popupMode = this.get('popupMode');
      if (this.get('calendar')) this.$(this.get('calendar').container).remove();

      var mode = this.get('spanMultipleDays') ? 'range' : 'single';

      if (!popupMode) {

        this.set('calendar', new Kalendae(this.$()[0], {
          mode: mode,
          direction: this.get('direction'),
          weekStart: this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset'),
          selected: this.get('selected'),
          dayOutOfMonthClickable: this.get('dayOutOfMonthClickable'),
          subscribe: {
            'change': function change() {
              return _this._handleDatepickerChange();
            }
          },

          blackout: function blackout(date) {
            if (_this.get('blockDatesBefore')) {
              var ex = date.isBefore(_this.get('blockDatesBefore'));
              if (ex) return true;
            }

            if (_this.get('blockDatesAfter')) {
              var ex = date.isAfter(_this.get('blockDatesAfter'));
              if (ex) return true;
            }

            if (_this.get('restrictToDayIndex') !== null && _this.get('restrictToDayIndex') !== undefined) {
              return date.day() !== _this.get('restrictToDayIndex');
            }

            if (_this.get('blockDaysOfWeek')) {
              return _this.get('blockDaysOfWeek').indexOf(date.day()) !== -1;
            }
          }
        }));
      } else {
        // let input = this.$('<input></input').addClass(this.get('location'));
        // this.$().append(input);
        if (!this.$().find('input').length) return false;
        this.set('calendar', new Kalendae.Input(this.$().find('input')[0], {
          mode: mode,
          direction: this.get('direction'),
          selected: this.get('firstDay'),
          closeOnSelection: this.get('closeOnSelection'),
          weekStart: this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset'),
          dayOutOfMonthClickable: this.get('dayOutOfMonthClickable'),
          months: this.get('months'),
          format: 'YYYY-MM-DD',
          side: 'top',
          subscribe: {
            'change': function change() {
              return _this._handleDatepickerChange();
            }
          },
          blackout: function blackout(date) {
            if (_this.get('blockDatesBefore')) {
              var ex = date.isBefore(_this.get('blockDatesBefore'));
              if (ex) return true;
            }

            if (_this.get('blockDatesAfter')) {
              var ex = date.isAfter(_this.get('blockDatesAfter'));
              if (ex) return true;
            }

            if (_this.get('restrictToDayIndex') !== null && _this.get('restrictToDayIndex') !== undefined) {
              return date.day() !== _this.get('restrictToDayIndex');
            }

            if (_this.get('blockDaysOfWeek')) {
              return _this.get('blockDaysOfWeek').indexOf(date.day()) !== -1;
            }
          }
        }));
      }

      // Originally the component was made to work with 2 variables firstDay and LastDay
      // However we aso want to make it work in single mode so we only handle the days change
      // if (!this.get('selected')) {
      this.handleDaysChange();
      // }
    },

    _handleDatepickerChange: function _handleDatepickerChange() {
      if (this.get('calendar') && this.get('calendar').getSelected()) {
        var _get$getSelected$split = this.get('calendar').getSelected().split(' - ');

        var _get$getSelected$split2 = _slicedToArray(_get$getSelected$split, 2);

        var firstDay = _get$getSelected$split2[0];
        var lastDay = _get$getSelected$split2[1];

        this.sendAction('updateSelectedDates', firstDay, lastDay);
      }
    },

    //jshint maxcomplexity:12
    _adjustDayByRestrictions: function _adjustDayByRestrictions(date) {
      if (!Number.isInteger(this.get('restrictToDayIndex')) || !moment.isMoment(date)) return date;

      // Keep adding 1 day if the current Date is before the BlockDatesBefore date
      if (this.get('blockDatesBefore')) {
        while (date.isBefore(this.get('blockDatesBefore')) || date.isSame(this.get('blockDatesBefore'), 'day')) {
          date.add(1, 'day');
        }
      }

      // Keep subtracting 1 day if the current date is after the blockDatesAfter
      if (this.get('blockDatesAfter')) {
        while (date.isAfter(this.get('blockDatesAfter'))) date.subtract(1, 'day');
      }

      // This will adjust the date until it matches the an optional day index (0 = sundays only...)
      if (this.get('restrictToDayIndexDirection') === 'forward') {
        while (date.day() !== this.get('restrictToDayIndex')) date.add(1, 'day');
      } else if (this.get('restrictToDayIndexDirection') === 'backward') {
        while (date.day() !== this.get('restrictToDayIndex')) date.subtract(1, 'day');
      }

      return date;
    }
  });
});