define('scientia-resourcebooker/services/resource-permission-summary-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var MAPPING = {
    admin: {
      'templatePermissions': ['editPermissionsGroups'],
      'resourceTypePermissions': ['readPermissionGroups', 'writePermissionGroups', 'editPermissionsGroups', 'manageGroupsPermissionGroups', 'manageResourcesPermissionGroups'],
      'resourceGroupPermissions': ['showPermissionGroups', 'readPermissionGroups', 'writePermissionGroups', 'editPermissionsGroups', 'editNestedPermissionsGroups', 'viewCalendarsPermissionGroups', 'manageBookingsPermissionGroups', 'viewBookingsReportPermissionGroups', 'viewAuditReportPermissionGroups'],
      'resourcePermissions': ['readPermissionGroups', 'writePermissionGroups', 'approvePermissionGroups', 'viewCalendarsPermissionGroups', 'viewAuditReportPermissionGroups', 'manageBookingsPermissionGroups', 'editPermissionsGroups', 'resourceOwners', 'viewBookingsReportPermissionGroups']
    },

    book: {
      'templatePermissions': ['readPermissionGroups', 'bookPermissionGroups', 'requestPermissionGroups', 'recurringPermissionGroups', 'basePermissionGroups', 'boboPermissionGroups'],
      'resourceTypePermissions': [],
      'resourceGroupPermissions': ['readPermissionGroups', 'showPermissionGroups', 'requestPermissionGroups', 'bookPermissionGroups'],
      'resourcePermissions': ['readPermissionGroups', 'bookPermissionGroups', 'requestPermissionGroups', 'resourceOwners']
    }
  };

  // These are the 4 keys that we receive from the API
  var PERMISSION_TYPES = ['templatePermissions', 'resourceTypePermissions', 'resourceGroupPermissions', 'resourcePermissions'];

  // These are the related translation keys for them
  var PERMISSION_TYPES_LOCALE = ['templates', 'resource_type', 'resource_groups', 'resource'];

  // These are the route paths for each type based on the index above (template, resource type, group, resource)
  var ROUTE_PATH_FOR_TYPES = ['manage-resources.booking-type.edit', 'manage-resources.resource-type.edit', 'manage-resources.resource-group.edit', 'manage-resources.resource.edit', 'manage-resources.user-group.edit'];

  // These are the tab index for the claim or permission tab for each object based on the indexes above
  var EDIT_TAB_INDEX_FOR_TYPES = [5, 2, 2, 3, 1];

  var EDIT_PERMISSIONS_NAMES = ['editAllPermissionGroups', 'editStatusPermissionGroups', 'editAvailabilityPermissionGroups', 'editResourceRestrictionsPermissionGroups', 'editPermissionsPermissionGroups', 'editRulesPermissionGroups', 'editDescriptionPermissionGroups', 'editCustomFieldsPermissionGroups', 'editResourcesPermissionGroups', 'editNotificationsPermissionGroups', 'editCalendarPermissionGroups'];

  var EDIT_NESTED_PERMISSION_NAMES = ['resourcesEditAllPermissionGroups', 'resourcesEditStatusPermissionGroups', 'resourcesEditAvailabilityPermissionGroups', 'resourcesEditResourceRestrictionPermissionGroups', 'resourcesEditPermissionsPermissionGroups', 'resourcesEditRulesPermissionGroups', 'resourcesEditDescriptionPermissionGroups', 'resourcesEditCustomFieldsPermissionGroups', 'resourcesEditResourcesPermissionGroups'];

  var UNLINKABLE_USER_GROUPS = [_scientiaResourcebookerConfigEnvironment['default'].IDS.USER_GROUP_EVERYONE];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),
    objectId: null,

    fetch: function fetch(resourceTypeId, resourceId) {
      var _this = this;

      this.set('objectId', resourceId);

      this.get('appInsights').trackEvent('permissions-summary', { type: 'resource', resourceTypeId: resourceTypeId, resourceId: resourceId, isAdmin: this.get('isAdmin') });
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_PERMISSION_SUMMARY.loc(resourceTypeId, resourceId)).then(function (results) {
        return _this._normalizeResponse(results);
      });
    },

    fetchForUser: function fetchForUser(_ref) {
      var _this2 = this;

      var type = _ref.type;
      var query = _ref.query;
      var includeEveryoneGroup = _ref.includeEveryoneGroup;

      this.get('appInsights').trackEvent('permissions-summary', { type: 'search', claimType: type, includeEveryoneGroup: includeEveryoneGroup, isAdmin: this.get('isAdmin') });

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.USER_PERMISSION_SUMMARY;
      return this.get('authenticatedRequest').fetch(url, { claimType: type, claimValue: query, includeEveryoneGroup: includeEveryoneGroup }).then(function (response) {
        var results = _this2._normalizeResponse(response);
        results.permissionGroupPermissions = _this2.normalizeUserGroupsData(response.permissionGroupPermissions);

        return results;
      });
    },

    fetchForUserGroup: function fetchForUserGroup(id) {
      var _this3 = this;

      this.set('objectId', id);

      this.get('appInsights').trackEvent('permissions-summary', { type: 'user-group', id: id, isAdmin: this.get('isAdmin') });
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.USERGROUP_PERMISSION_SUMMARY.loc(id);
      return this.get('authenticatedRequest').fetch(url).then(function (results) {
        return _this3._normalizeResponse(results);
      });
    },

    fetchForSelf: function fetchForSelf() {
      var _this4 = this;

      this.get('appInsights').trackEvent('permissions-summary', { type: 'self', isAdmin: this.get('isAdmin') });
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.SELF_PERMISSION_SUMMARY;
      return this.get('authenticatedRequest').fetch(url).then(function (results) {
        return _this4._normalizeResponse(results);
      });
    },

    _normalizeResponse: function _normalizeResponse(response) {
      response.resourcePermissions = _ember['default'].isEmpty(response.resourcePermissions) ? [] : _ember['default'].isArray(response.resourcePermissions) ? [].concat(_toConsumableArray(response.resourcePermissions)) : [response.resourcePermissions];
      response.resourceTypePermissions = _ember['default'].isEmpty(response.resourceTypePermissions) ? [] : _ember['default'].isArray(response.resourceTypePermissions) ? [].concat(_toConsumableArray(response.resourceTypePermissions)) : [response.resourceTypePermissions];
      response.resourceGroupPermissions = _ember['default'].isEmpty(response.resourceGroupPermissions) ? [] : response.resourceGroupPermissions;
      response.templatePermissions = _ember['default'].isEmpty(response.templatePermissions) ? [] : response.templatePermissions;

      try {
        var normalizedData = this._normalizeFromMapping(response);
        return normalizedData;
      } catch (error) {
        LE.error('Unable to normalize permissions summary', error);
        return { admin: [], book: [] };
      } finally {
        this.set('objectId', null);
      }
    },

    normalizeUserGroupsData: function normalizeUserGroupsData(data) {
      var _this5 = this;

      if (!data) return [];
      return data.map(function (group) {
        return {
          id: group.identity,
          name: group.name,
          link: _this5.getRouteDataForObject(4, group)
        };
      }).sort(function (a, b) {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
    },

    getEmptyResults: function getEmptyResults() {
      var data = {
        admin: {
          templatePermissions: [],
          resourceTypePermissions: [],
          resourceGroupPermissions: [],
          resourcePermissions: []

        },
        book: {
          templatePermissions: [],
          resourceTypePermissions: [],
          resourceGroupPermissions: [],
          resourcePermissions: []
        } };

      return this._normalizeResponse(data);
    },

    getRouteDataForObject: function getRouteDataForObject(type, object) {

      // First get the route path
      var linkPath = ROUTE_PATH_FOR_TYPES[type];
      var linkData = [linkPath];

      // If a group or a resource push the resourceTypeId
      if (type === 2 || type === 3) {
        linkData.push(object.resourceTypeId);
      }

      // Finally push the Id
      linkData.push(object.id);

      linkData.push({
        isQueryParams: true,
        values: { selectedTab: EDIT_TAB_INDEX_FOR_TYPES[type] }
      });

      return linkData;
    },

    _normalizeFromMapping: function _normalizeFromMapping(data) {
      var _this6 = this;

      var response = { admin: [], book: [] };

      Object.keys(response).forEach(function (tab) {

        PERMISSION_TYPES.forEach(function (rootPermissionType, i) {
          if (tab === 'book' && rootPermissionType === 'resourceTypePermissions') return;

          response[tab][rootPermissionType] = {
            name: PERMISSION_TYPES_LOCALE[i],
            values: [] };

          data[rootPermissionType].forEach(function (resourceObject) {
            MAPPING[tab][rootPermissionType].forEach(function (permissionKey) {
              if (permissionKey in resourceObject) {
                (function () {

                  var existingObject = response[tab][rootPermissionType].values.findBy('name', resourceObject.name);

                  if (!_ember['default'].isEmpty(resourceObject[permissionKey])) {
                    if (_ember['default'].isEmpty(existingObject)) {
                      response[tab][rootPermissionType].values.push({
                        name: resourceObject.name,
                        isLinkable: _this6.isLinkable(resourceObject),
                        resourceTypeName: resourceObject.resourceTypeName,
                        id: resourceObject.id,
                        link: _this6.getRouteDataForObject(i, resourceObject),
                        permissions: [] });

                      existingObject = response[tab][rootPermissionType].values.findBy('name', resourceObject.name);
                    }

                    var permissionData = resourceObject[permissionKey];

                    // If is a nestedPermission
                    if (permissionKey === 'editPermissionsGroups' || permissionKey === 'editNestedPermissionsGroups') {
                      var permissionsArray = _this6._nestedPermissionDataNormalize(permissionData, permissionKey);
                      permissionsArray.forEach(function (permissionGroup) {
                        var newPermission = { name: _this6._getPermissionNameByPermissionKey(permissionGroup.name), permissions: permissionGroup.permissions };

                        // if permission already exists, push this one into the existing array
                        var existingPermission = existingObject.permissions.findBy('name', newPermission.name);
                        if (!_ember['default'].isEmpty(existingPermission)) {
                          var _existingPermission$permissions;

                          (_existingPermission$permissions = existingPermission.permissions).push.apply(_existingPermission$permissions, _toConsumableArray(newPermission.permissions));

                          // If not just push new one
                        } else {
                            existingObject.permissions.push(newPermission);
                          }
                      });
                    } else {
                      var newPermission = { name: _this6._getPermissionNameByPermissionKey(permissionKey, rootPermissionType), permissions: _this6._normalizePermissionGroup(resourceObject[permissionKey], permissionKey, rootPermissionType) };
                      existingObject.permissions.push(newPermission);
                    }
                  }
                })();
              }
            });
          });
        });
      });

      var sortedResults = this.sortResourceAndGroups(response);
      var sortedWithHeaders = this.addHeadersResourceAndGroups(sortedResults);
      return sortedWithHeaders;
    },

    sortResourceAndGroups: function sortResourceAndGroups(response) {
      var sorter = function sorter(a, b) {
        var sortAString = a.resourceTypeName + a.name;
        var sortBString = b.resourceTypeName + b.name;
        return sortAString < sortBString ? -1 : 1;
      };

      response.admin.resourcePermissions.values = response.admin.resourcePermissions.values.sort(sorter);
      response.book.resourcePermissions.values = response.book.resourcePermissions.values.sort(sorter);

      response.admin.resourceGroupPermissions.values = response.admin.resourceGroupPermissions.values.sort(sorter);
      response.book.resourceGroupPermissions.values = response.book.resourceGroupPermissions.values.sort(sorter);

      return response;
    },

    addHeadersResourceAndGroups: function addHeadersResourceAndGroups(response) {

      var eachHandler = function eachHandler(permissions) {
        var headers = [];
        permissions.values.forEach(function (r) {
          if (headers.indexOf(r.resourceTypeName) === -1) {
            headers.push(r.resourceTypeName);
            r.header = r.resourceTypeName;
          }
        });
      };

      eachHandler(response.admin.resourcePermissions);
      eachHandler(response.book.resourcePermissions);
      eachHandler(response.admin.resourceGroupPermissions);
      eachHandler(response.book.resourceGroupPermissions);

      return response;
    },

    _nestedPermissionDataNormalize: function _nestedPermissionDataNormalize(permissionData, permissionKey) {
      var _this7 = this;

      return permissionData.map(function (p) {
        var permissionName = permissionKey === 'editNestedPermissionsGroups' ? EDIT_NESTED_PERMISSION_NAMES[p.editPermissionType] : EDIT_PERMISSIONS_NAMES[p.editPermissionType];
        return {
          name: permissionName,
          permissionName: permissionName,
          permissions: [{
            isUserGroup: !!p.name,
            isLinkable: _this7.isLinkable(p),
            id: p.id,
            name: _this7._getPermissionValue(p)
          }] };
      }, []);
    },

    _normalizePermissionGroup: function _normalizePermissionGroup(data, permissionKey, permissionType) {
      var _this8 = this;

      if (!data) return null;

      if (permissionKey === 'requestPermissionGroups') {
        return data.map(function (p) {
          return {
            permissionName: _this8._getPermissionNameByPermissionKey(permissionKey),
            requester: {
              isUserGroup: !!p.permissionTarget && !!p.permissionTarget.name,
              isLinkable: p.permissionTarget ? _this8.isLinkable(p.permissionTarget) : false,
              id: p.permissionTarget ? p.permissionTarget.id : '',
              name: p.permissionTarget ? _this8._getPermissionValue(p.permissionTarget) : ''
            },
            approvers: p.approvers.map(function (a) {
              a.isUserGroup = !!a.name;
              a.isLinkable = _this8.isLinkable(a);
              a.name = _this8._getPermissionValue(a);
              return a;
            })
          };
        });
      } else {
        return data.map(function (p) {
          return {
            isUserGroup: !!p.name,
            isLinkable: _this8.isLinkable(p),
            id: p.id,
            name: _this8._getPermissionValue(p),
            permissionName: _this8._getPermissionNameByPermissionKey(permissionKey, permissionType)
          };
        });
      }
    },

    isLinkable: function isLinkable(permission) {
      return !!permission.name && permission.id !== this.get('objectId') && UNLINKABLE_USER_GROUPS.indexOf(permission.id) === -1;
    },

    // These allow us to get the translation string for the permissionKey and map any exceptional property names that not adhere to our convention
    // such as resourceOwners->Approve, etc..
    _getPermissionNameByPermissionKey: function _getPermissionNameByPermissionKey(permissionKey, type) {
      var k = undefined;

      if (permissionKey === 'editPermissionsPermissionGroups') {
        k = 'edit_permissions';
      } else if (permissionKey === 'resourcesEditPermissionsPermissionGroups') {
        k = 'resources_edit_permissions';
      } else if (permissionKey === 'manageBookingsPermissionGroups' && type === 'resourceGroupPermissions') {
        k = 'resources_manage_bookings';
      } else if (permissionKey === 'viewBookingsReportPermissionGroups' && type === 'resourceGroupPermissions') {
        k = 'resources_view_bookings_report';
      } else if (permissionKey === 'viewAuditReportPermissionGroups' && type === 'resourceGroupPermissions') {
        k = 'resources_view_audit_report';
      } else if (permissionKey === 'viewCalendarsPermissionGroups' && type === 'resourcePermissions') {
        k = 'view_calendar';
      } else if (permissionKey === 'resourceOwners') {
        k = 'approve';
      } else {
        k = permissionKey.split('Permission')[0].underscore();
      }

      if (type === 'templatePermissions' && k === 'read') k = 'show';

      return k;
    },

    _getPermissionValue: function _getPermissionValue(permission) {
      if (permission.name) return permission.name;
      try {
        var claimType = this.get('i18n').t('components.manage_resources.claims_form.claim_types.' + permission.permissionClaim.claimType.toLowerCase());
        return claimType.toString() + ': ' + permission.permissionClaim.claimValue;
      } catch (error) {
        return '';
      }
    }
  });
});