define('scientia-resourcebooker/pods/components/resource-filters/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var getOwner = _ember['default'].getOwner;

  var COMPONENT_PATH = 'resource-filters/%@';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    classNames: ['resourceFilters resourceFilter-type-boolean'],
    availableNow: false,
    spanMultipleDays: false,
    firstDay: null,
    lastDay: null,
    fromTime: '',
    toTime: '',
    duration: null,
    customFilters: [],
    filters: [],
    systemSettings: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),
    dateTimeHelpers: _ember['default'].inject.service(),
    isSearching: false,

    recurrence: null,
    recurrenceErrors: null,
    recurringFiltersEnabled: false,

    customFiltersSelected: [],
    isDebouncing: false,

    // 1: Single Booking, 2: Recurring Booking
    bookingMode: 1,

    contentFromTime: null,
    contentToTime: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.send('updateContentFromTime');
      this.send('updateContentToTime');
    },

    useOldTimePicker: _ember['default'].computed.alias('systemSettings.options.bookingUseSplitTimePicker'),

    calendarOptions: _ember['default'].computed('bookingType.calendarOptions.useCustomCalendarOptions', function () {
      var bt = this.get('bookingType');
      if (bt.get('calendarOptions.useCustomCalendarOptions')) {
        return bt.get('calendarOptions');
      } else {
        return this.get('systemSettings').getCalendarOptions();
      }
    }),

    datepickerBlockedDays: _ember['default'].computed('calendarOptions.calendarShowWeekends', function () {
      var showWeekends = this.get('calendarOptions.calendarShowWeekends');
      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      return showWeekends ? null : weekendDays;
    }),

    toTimeStartTimeRange: _ember['default'].computed('calendarOptions.calendarStartTime', 'fromTime', function () {

      var calendarStart = this.get('calendarOptions.calendarStartTime');

      var canSpanMultipleDays = this.get('calendarOptions.bookingSpanMultipleDays');
      if (canSpanMultipleDays && this.get('spanMultipleDays')) {
        return calendarStart;
      }

      var fromTime = this.get('fromTime');
      if (fromTime) {
        return this.get('dateTimeHelpers').getNextTimePeriod(fromTime, this.get('calendarOptions'), true);
      } else {
        return calendarStart;
      }
    }),

    // Used by oldTimePicker
    startHour: _ember['default'].computed('calendarOptions.calendarStartTime', function () {
      return this.get('dateTimeHelpers').getStartHourFromTime(this.get('calendarOptions.calendarStartTime'));
    }),

    // Used by oldTimePicker
    endHour: _ember['default'].computed('calendarOptions.calendarEndTime', function () {
      return this.get('dateTimeHelpers').getEndHourFromTime(this.get('calendarOptions.calendarEndTime'));
    }),

    // Reset duration if both from and toTime are selected
    fromToTimeChanged: (function () {
      var _this = this;

      if (!this.get('useOldTimePicker')) return;
      var fromTime = this.get('fromTime');
      var toTime = this.get('toTime');

      this._debounceLogFilter({ type: 'time', from: fromTime, to: toTime });

      if (fromTime && toTime && !this.get('spanMultipleDays')) {
        var _fromTime$split = fromTime.split(':');

        var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

        var fromHours = _fromTime$split2[0];
        var fromMinutes = _fromTime$split2[1];

        var _toTime$split = toTime.split(':');

        var _toTime$split2 = _slicedToArray(_toTime$split, 2);

        var toHours = _toTime$split2[0];
        var toMinutes = _toTime$split2[1];

        var fromMoment = moment({ hour: fromHours, minute: fromMinutes }).startOf('minute');
        var toMoment = moment({ hour: toHours, minute: toMinutes }).startOf('minute');

        if (fromMoment.isSame(toMoment) || fromMoment.isAfter(toMoment)) {
          (function () {
            var adjustedToTime = fromMoment.clone().add(15, 'minute');

            // Need to run on next run loop as dropdown won't update if we manually adjust toTIme.This fixes RB-206
            _ember['default'].run.next(_this, function () {
              this.set('toTime', adjustedToTime.format('HH:mm'));
            });
            // const toTime = this.$('.resourceFilters-timeInput-toTime .timeInput-select-minutes select');
            // Ember.run.next(this, function() {
            //   if (toTime) toTime.val(adjustedToTime.format('mm'));
            // });
          })();
        }
      }

      if (this.get('fromTime') && this.get('toTime')) this.set('duration', null);
      this.setDefaultStartDateIfNeeded();
    }).observes('fromTime', 'toTime', 'spanMultipleDays'),

    toTimeChanged: (function () {
      if (!this.get('useOldTimePicker')) return;
      if (this.get('isDestroyed') || this.get('isDestroying')) return;

      if (this.get('toTime') && this.get('duration')) {
        this.set('duration', null);
      }

      this.setDefaultStartDateIfNeeded();
    }).observes('toTime'),

    isFromTimeActivated: _ember['default'].computed.bool('fromTime'), // new version (non split timepicker)
    isToTimeActivated: _ember['default'].computed.bool('toTime'), // new version (non split timepicker)

    fromTimeActivated: _ember['default'].computed('fromTime', {
      get: function get() {
        return !!this.get('fromTime');
      },

      set: function set(key, value) {
        if (value) {
          var firstHourAvailable = this.get('systemSettings').getSetting('calendarStartTimeOffset');
          var fromTime = moment().hour(firstHourAvailable).minute(0);
          this.set('fromTime', fromTime.format('HH:mm'));
          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('fromTime', '');
        }
        return value;
      }
    }),

    toTimeActivated: _ember['default'].computed('toTime', {
      get: function get() {
        return !!this.get('toTime');
      },

      set: function set(key, value) {
        if (value) {
          var firstHourAvailable = this.get('systemSettings').getSetting('calendarStartTimeOffset');
          var toTime = moment().hour(firstHourAvailable).minute(0);
          this.set('toTime', toTime.format('HH:mm'));
          this.set('duration', null);

          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('toTime', '');
        }
        return value;
      }
    }),

    durationItems: _ember['default'].computed('i18n.locale', 'availableNow', 'calendarOptions.calendarBookingTimes', function () {
      var _this2 = this;

      var calendarOptions = this.get('calendarOptions');
      // let labels = this.get('i18n').t('components.resource_filters.duration_item_labels').toString().split(',');
      var durationValues = this.get('calendarOptionsHelper').getAvailableBookingTimeDurations(calendarOptions);

      var isAvailableNow = this.get('availableNow');
      return durationValues.map(function (d) {

        var label = _this2.get('i18n').t('components.resource_filters.duration_items.' + d.toString());
        if (isAvailableNow && d === 0) {
          label = _this2.get('i18n').t('components.resource_filters.available_now_with_all_day').toString();
        }
        return {
          id: d.toString(),
          value: d.toString(),
          label: label
        };
      });
    }),

    availableNowObserver: (function () {
      if (!this.get('useOldTimePicker')) return;

      this.set('firstDay', null);
      this.set('lastDay', null);
      this.set('fromTime', '');
      this.set('toTime', '');

      if (this.get('availableNow')) this.set('spanMultipleDays', false);

      this._debounceLogFilter({ type: 'available-now', value: this.get('availableNow') });
    }).observes('availableNow'),

    _updateSelectedDates: function _updateSelectedDates() {
      var firstDay = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var lastDay = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (!this.get('isDestroyed')) {
        this.sendAction('logFilter', { type: 'calendar', from: firstDay, to: lastDay, spanMultipleDays: this.get('spanMultipleDays') });
        this.set('firstDay', firstDay);
        this.set('lastDay', lastDay);
      }
    },

    _debounceLogFilter: function _debounceLogFilter(data) {
      _ember['default'].run.debounce(this, 'sendAction', 'logFilter', data, 500);
    },

    spanMultipleDaysChanged: function spanMultipleDaysChanged() {
      if (!this.get('useOldTimePicker')) return;

      if (this.get('isDestroyed') || this.get('isDestroying')) return;
      if (this.get('spanMultipleDays')) {
        var calendarStart = this.get('calendarOptions.calendarStartTime');
        var calendarEnd = this.get('calendarOptions.calendarEndTime');

        var _calendarStart$split = calendarStart.split(':');

        var _calendarStart$split2 = _slicedToArray(_calendarStart$split, 2);

        var startHour = _calendarStart$split2[0];
        var startMin = _calendarStart$split2[1];

        var _calendarEnd$split = calendarEnd.split(':');

        var _calendarEnd$split2 = _slicedToArray(_calendarEnd$split, 2);

        var endHour = _calendarEnd$split2[0];
        var endtMin = _calendarEnd$split2[1];

        var startTime = moment().startOf('day').hour(startHour).minute(startMin);
        var endTime = startTime.clone().hour(endHour === 0 ? 24 : endHour).minute(endtMin);
        this.setProperties({
          duration: null,
          firstDay: startTime.format('YYYY-MM-DD'),
          lastDay: startTime.clone().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
          fromTime: startTime.format('HH:mm'),
          toTime: endTime.hour() === 0 ? '24:00' : endTime.format('HH:mm')
        });
      } else {
        this.setProperties({
          firstDay: null,
          lastDay: null,
          fromTime: '',
          toTime: ''
        });
      }
      // });
    },

    setDefaultStartDateIfNeeded: function setDefaultStartDateIfNeeded() {
      if (!this.get('firstDay')) this.set('firstDay', moment().startOf('day').format('YYYY-MM-DD'));
    },

    durationChanged: (function () {
      this.setDefaultStartDateIfNeeded();

      if (this.get('duration')) {
        try {
          var durationValue = this.get('duration');
          this._debounceLogFilter({ type: 'duration', value: durationValue });
        } catch (e) {
          //no-op
        }
      }
    }).observes('duration'),

    actions: {

      onToggleSpanMultipleDays: function onToggleSpanMultipleDays(value) {
        this.set('spanMultipleDays', value);
        if (value) {

          var calendarStart = this.get('calendarOptions.calendarStartTime');

          var _calendarStart$split3 = calendarStart.split(':');

          var _calendarStart$split32 = _slicedToArray(_calendarStart$split3, 2);

          var startH = _calendarStart$split32[0];
          var startM = _calendarStart$split32[1];

          var calendarEnd = this.get('calendarOptions.calendarEndTime');

          var _calendarEnd$split3 = calendarEnd.split(':');

          var _calendarEnd$split32 = _slicedToArray(_calendarEnd$split3, 2);

          var endH = _calendarEnd$split32[0];
          var endM = _calendarEnd$split32[1];

          var startDay = moment().startOf('day').hour(startH).minute(startM);
          var lastDay = moment().endOf('day').add(1, 'day').hour(endH).minute(endM);

          this.setProperties({
            duration: null,
            firstDay: startDay.format('YYYY-MM-DD'),
            lastDay: lastDay.format('YYYY-MM-DD'),
            fromTime: startDay.format('HH:mm'),
            toTime: lastDay.format('HH:mm')
          });
        } else {
          this.setProperties({
            firstDay: null,
            lastDay: null,
            fromTime: '',
            toTime: ''
          });
        }

        this.send('updateContentToTime');
        this.send('updateContentFromTime');
      },

      onToggleAvailableNow: function onToggleAvailableNow(value) {
        this.set('availableNow', value);
        var availableNow = value;

        this.set('firstDay', null);
        this.set('lastDay', null);
        this.set('fromTime', '');
        this.set('toTime', '');

        if (availableNow) {
          this.send('onToggleSpanMultipleDays', false);
        }

        this._debounceLogFilter({ type: 'available-now', value: value });
      },

      onToggleFromTimeActivated: function onToggleFromTimeActivated(value) {
        if (value) {
          var startTime = this.get('calendarOptions.calendarStartTime');
          this.set('fromTime', startTime);
          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('fromTime', '');
        }
      },

      onToggleToTimeActivated: function onToggleToTimeActivated(value) {
        if (value) {
          var fromTime = this.get('fromTime');

          // If we got fromTime then just add 1 period to create the ToTime
          if (fromTime) {
            var _fromTime$split3 = fromTime.split(':');

            var _fromTime$split32 = _slicedToArray(_fromTime$split3, 2);

            var fromHour = _fromTime$split32[0];
            var fromMin = _fromTime$split32[1];

            var fromMoment = moment().hour(fromHour).minute(fromMin);
            var bookingPeriods = this.get('calendarOptions.calendarBookingTimes');
            var periodsAvailable = this.get('calendarOptionsHelper').parse(bookingPeriods).reject(function (x) {
              return x === 0;
            });
            if (!periodsAvailable.length) periodsAvailable = [0];
            var toTime = fromMoment.clone().add(periodsAvailable[0], 'minute');
            this.set('toTime', toTime.format('HH:mm'));
          } else {
            var calendarEndTime = this.get('calendarOptions.calendarEndTime');
            this.set('toTime', calendarEndTime);
          }
          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('toTime', '');
        }
        return value;
      },

      onSetFromTime: function onSetFromTime(fromTime) {
        this.set('fromTime', fromTime);

        var toTime = this.get('toTime');

        if (_ember['default'].isEmpty(toTime)) {
          this.send('updateContentToTime');
          return;
        }

        var spanMultipleDays = this.get('spanMultipleDays');
        if (!spanMultipleDays && toTime && fromTime && this.get('dateTimeHelpers').isTimeBefore(toTime, fromTime, true)) {
          this.set('toTime', '');
        }

        if (fromTime && toTime || toTime) {
          this.set('duration', null);
        }

        this.send('updateContentToTime');
      },

      onSetToTime: function onSetToTime(toTime) {

        var fromTime = this.get('fromTime');
        var spanMultipleDays = this.get('spanMultipleDays');
        if (!spanMultipleDays && toTime && fromTime && this.get('dateTimeHelpers').isTimeBefore(toTime, fromTime, true)) {
          this.set('toTime', '');
        } else {
          this.set('toTime', toTime);
        }

        if (toTime) {
          this.set('duration', null);
        }
      },

      updateContentFromTime: function updateContentFromTime() {

        var fromDate = moment();

        var calendarOptions = this.get('calendarOptions');

        var result = this.get('calendarOptionsHelper').getTimeSlotsForStartTime(fromDate, calendarOptions);

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentFromTime', data);
      },

      updateContentToTime: function updateContentToTime() {
        var fromTime = this.get('fromTime') || this.get('calendarOptions.calendarStartTime');

        var _fromTime$split4 = fromTime.split(':');

        var _fromTime$split42 = _slicedToArray(_fromTime$split4, 2);

        var h = _fromTime$split42[0];
        var m = _fromTime$split42[1];

        var fromDate = moment().hour(h).minute(m);

        var calendarOptions = this.get('calendarOptions');

        var spanMultipleDays = this.get('spanMultipleDays');

        var result = undefined;
        if (spanMultipleDays) {
          result = this.get('calendarOptionsHelper').getTimeSlotsForWholeCalendarRange(fromDate, calendarOptions, true);
        } else {
          var toTime = this.get('toTime') || this.get('calendarOptions.calendarEndTime');

          var _toTime$split3 = toTime.split(':');

          var _toTime$split32 = _slicedToArray(_toTime$split3, 2);

          var _h = _toTime$split32[0];
          var _m = _toTime$split32[1];

          var toDate = fromDate.clone().hour(_h).minute(_m);
          result = this.get('calendarOptionsHelper').getTimeSlotsForEndTime(fromDate, toDate, calendarOptions);
        }

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentToTime', data);
      },

      customFilterNumberChanged: function customFilterNumberChanged(propertyId, propertyValue, propertyName) {
        this.sendAction('customFilterNumberChanged', propertyId, propertyValue, propertyName);
      },

      updateSelectedDates: function updateSelectedDates() {
        var firstDay = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        var lastDay = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        if (this.get('spanMultipleDays') && firstDay && firstDay === lastDay) return;
        if (this.get('spanMultipleDays') && (!firstDay || !lastDay)) return;
        _ember['default'].run.debounce(this, '_updateSelectedDates', firstDay, lastDay, 500);
      },

      resetFilters: function resetFilters() {
        this.sendAction('resetFilters');
      },

      updateRecurrence: function updateRecurrence(recurrence) {
        this.sendAction('updateRecurrence', recurrence);
      },

      componentExists: function componentExists(valueType) {
        var componentPath = COMPONENT_PATH.loc(valueType);
        return getOwner(this).lookup('component:' + componentPath);
      }

    }
  });
});