define("scientia-resourcebooker/pods/manage-resources/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sidebar-overlay");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["toggleSidebar"], [], ["loc", [null, [3, 33], [3, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 9,
              "column": 52
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "help-trigger", [], ["mobile", true], ["loc", [null, [9, 24], [9, 52]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "sidebar-toggler at-root");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "sidebar-toggler-open-wrapper");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "sidebar-toggler-name");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createElementMorph(element3);
        morphs[2] = dom.createMorphAt(element3, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(element2, 5, 5);
        morphs[5] = dom.createMorphAt(element1, 5, 5);
        morphs[6] = dom.createMorphAt(element1, 7, 7);
        morphs[7] = dom.createMorphAt(element1, 9, 9);
        return morphs;
      },
      statements: [["block", "if", [["get", "isSidebarOpen", ["loc", [null, [2, 8], [2, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [4, 9]]]], ["element", "action", ["toggleSidebar"], [], ["loc", [null, [7, 45], [7, 71]]], 0, 0], ["inline", "inline-svg", ["icons/list.svg"], ["class", "sidebar-toggler-open at-root"], ["loc", [null, [7, 72], [7, 140]]], 0, 0], ["inline", "if", [["get", "appName", ["loc", [null, [8, 42], [8, 49]]], 0, 0, 0, 0], ["get", "appName", ["loc", [null, [8, 50], [8, 57]]], 0, 0, 0, 0], "Resource Booker"], [], ["loc", [null, [8, 37], [8, 77]]], 0, 0], ["block", "if", [["get", "media.isTiny", ["loc", [null, [9, 10], [9, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [9, 4], [9, 59]]]], ["inline", "container-sidebar", [], ["isSidebarOpen", ["subexpr", "@mut", [["get", "isSidebarOpen", ["loc", [null, [13, 18], [13, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [14, 9], [14, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "notificationsCount", ["subexpr", "@mut", [["get", "notificationsCountFormatted", ["loc", [null, [15, 23], [15, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleSidebar", ["subexpr", "action", ["toggleSidebar"], [], ["loc", [null, [16, 18], [16, 42]]], 0, 0], "logout", "logout"], ["loc", [null, [12, 2], [18, 4]]], 0, 0], ["content", "liquid-outlet", ["loc", [null, [20, 2], [20, 19]]], 0, 0, 0, 0], ["content", "modal-target", ["loc", [null, [22, 2], [22, 18]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});