define("scientia-resourcebooker/pods/components/resources-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 21,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["location", "at-resourcesList at-size--big"], ["loc", [null, [20, 2], [20, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "resources-list/items", [], ["firstDay", ["subexpr", "@mut", [["get", "firstDay", ["loc", [null, [23, 11], [23, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "firstDayRecurring", ["subexpr", "@mut", [["get", "firstDayRecurring", ["loc", [null, [24, 20], [24, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "lastDay", ["subexpr", "@mut", [["get", "lastDay", ["loc", [null, [25, 10], [25, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "fromTime", ["loc", [null, [26, 11], [26, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTimeRecurring", ["subexpr", "@mut", [["get", "fromTimeRecurring", ["loc", [null, [27, 20], [27, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "toTime", ["loc", [null, [28, 9], [28, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "toTimeRecurring", ["subexpr", "@mut", [["get", "toTimeRecurring", ["loc", [null, [29, 18], [29, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingModeIsRecurring", ["subexpr", "@mut", [["get", "bookingModeIsRecurring", ["loc", [null, [30, 25], [30, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "resourceGroups", ["subexpr", "@mut", [["get", "resourceGroups", ["loc", [null, [31, 17], [31, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "hideResourceGroupCount", ["subexpr", "@mut", [["get", "hideResourceGroupCount", ["loc", [null, [32, 25], [32, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "groupsListExpanded", ["subexpr", "@mut", [["get", "groupsListExpanded", ["loc", [null, [33, 21], [33, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "groupId", ["subexpr", "@mut", [["get", "groupId", ["loc", [null, [34, 10], [34, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "resources", ["subexpr", "@mut", [["get", "resources", ["loc", [null, [35, 12], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingType", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [36, 14], [36, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 0], [37, 2]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "resources-list/search", [], ["query", ["subexpr", "@mut", [["get", "queryText", ["loc", [null, [1, 30], [1, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "resetLoadMore", ["subexpr", "action", [["get", "resetLoadMore", ["loc", [null, [1, 62], [1, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 54], [1, 76]]], 0, 0]], ["loc", [null, [1, 0], [1, 78]]], 0, 0], ["inline", "resources-list/filters", [], ["availableNow", ["subexpr", "@mut", [["get", "availableNow", ["loc", [null, [5, 15], [5, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "spanMultipleDays", ["subexpr", "@mut", [["get", "spanMultipleDays", ["loc", [null, [6, 19], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "firstDay", ["subexpr", "@mut", [["get", "firstDay", ["loc", [null, [7, 11], [7, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "lastDay", ["subexpr", "@mut", [["get", "lastDay", ["loc", [null, [8, 10], [8, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "fromTime", ["loc", [null, [9, 11], [9, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "toTime", ["loc", [null, [10, 9], [10, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [11, 11], [11, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "customFilters", ["subexpr", "@mut", [["get", "customFilters", ["loc", [null, [13, 16], [13, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "customFiltersSelected", ["subexpr", "@mut", [["get", "customFiltersSelected", ["loc", [null, [14, 24], [14, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "removeDefaultFilter", "removeDefaultFilter", "removeCustomFilter", "removeCustomFilter"], ["loc", [null, [3, 0], [17, 2]]], 0, 0], ["block", "if", [["get", "isLoading", ["loc", [null, [19, 6], [19, 15]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [19, 0], [38, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});