define('scientia-resourcebooker/pods/components/manage-resources/notification-templates/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    classNames: ['notificationsTemplates'],
    templateSummary: null,
    selectedTemplate: null,
    clickedItemId: null,
    notificationTemplatesPersister: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    disabled: false,

    parentId: null,

    isSystem: false,
    isLoadingSummary: false,
    isLoadingTemplate: false,
    isSavingTemplate: false,
    isCopyingFromSystem: false,

    showCustomTemplatesToggleModal: false,
    copyFromSystemModalData: null,

    useCustomTemplates: false,
    didValidate: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.send('loadSummary');
    },

    actions: {

      loadSummary: function loadSummary() {
        var _this = this;

        var id = this.get('parentId');
        this.set('isLoadingSummary', true);
        this.get('notificationTemplatesPersister').getSummary({ parentId: id, isSystem: this.get('isSystem') }).then(function (templateSummary) {
          if (_this.get('isDestroying') || _this.get('isDestroyed')) return;
          _this.set('templateSummary', templateSummary);
          if (_this.get('selectedTemplate')) {
            _this.send('onItemClick', _this.get('selectedTemplate'));
          }
        })['finally'](function () {
          if (_this.get('isDestroying') || _this.get('isDestroyed')) return;
          _this.set('isLoadingSummary', false);
        });
      },

      onItemClick: function onItemClick(item, updateTemplateSummary) {
        var _this2 = this;

        // We want to rollback any unsaved changes when moving away from an item
        if (this.get('selectedTemplate')) {
          this.get('selectedTemplate').rollbackAttributes();
        }

        this.set('isLoadingTemplate', true);
        this.set('clickedItemId', item.id);

        this.get('notificationTemplatesPersister').fetchTemplate({
          parentId: this.get('parentId'),
          templateId: item.id,
          isSystem: this.get('isSystem')
        }).then(function (template) {
          _this2.set('selectedTemplate', template);

          if (updateTemplateSummary) {
            var templateSummary = _this2.get('templateSummary').findBy('id', template.id);
            _ember['default'].set(templateSummary, 'sendEmail', template.get('sendEmail'));
          }
        })['finally'](function () {
          _this2.set('isLoadingTemplate', false);
        });
      },

      copyFromSystem: function copyFromSystem(isSingle) {
        var _this3 = this;

        var deferred = _ember['default'].RSVP.defer();

        deferred.promise.then(function () {
          _this3.send('copyFromSystemConfirm', isSingle);
        });

        var titlePath = 'components.manage_resources.notification_templates.copy_from_system_' + (isSingle ? 'single' : 'all') + '_title';
        var messagePath = 'components.manage_resources.notification_templates.copy_from_system_' + (isSingle ? 'single' : 'all') + '_message';
        this.set('copyFromSystemModalData', { titlePath: titlePath, messagePath: messagePath, deferred: deferred });
      },

      copyFromSystemConfirm: function copyFromSystemConfirm(isSingle) {
        var _this4 = this;

        this.set('isCopyingFromSystem', true);

        var model = isSingle ? this.get('templateSummary').findBy('id', this.get('selectedTemplate.id')) : null;

        this.get('notificationTemplatesPersister').copySystem({
          parentId: this.get('parentId'),
          model: model,
          isSingle: isSingle
        }).then(function () {
          _this4.flashSuccess(_this4.get('i18n').t('components.manage_resources.notification_templates.flash_messages.copy_from_system_ok'));

          if (!isSingle) {
            _this4.send('loadSummary');
          } else {

            var selectedTemplate = _this4.get('selectedTemplate');
            if (selectedTemplate) {
              _this4.send('onItemClick', selectedTemplate, true);
            }
          }
        })['catch'](function () {
          _this4.flashError(_this4.get('i18n').t('components.manage_resources.notification_templates.flash_messages.copy_from_system_error'));
        })['finally'](function () {
          _this4.set('copyFromSystemModalData', false);
          _this4.set('isCopyingFromSystem', false);
        });
      },

      toggleUseCustomTemplates: function toggleUseCustomTemplates() {
        this.set('showCustomTemplatesToggleModal', true);
      },

      toggleUseCustomTemplatesConfirm: function toggleUseCustomTemplatesConfirm() {
        var _this5 = this;

        this.get('notificationTemplatesPersister').toggleUseCustomTemplates(this.get('parentId'), !this.get('useCustomTemplates')).then(function (response) {
          _this5.set('useCustomTemplates', response);
          _this5.set('showCustomTemplatesToggleModal', false);
        });
      },

      save: function save(template) {
        var _this6 = this;

        this.set('didValidate', true);
        this.set('isSavingTemplate', true);
        this.get('notificationTemplatesPersister').updateTemplate(template).then(function () {

          // Update template sendEmail in TemplateSummary to avoid having to refetch it all to refresh the list
          var templateSummary = _this6.get('templateSummary').findBy('id', template.id);
          _ember['default'].set(templateSummary, 'sendEmail', template.get('sendEmail'));
          _this6.flashSuccess(_this6.get('i18n').t('components.manage_resources.notification_templates.flash_messages.saved'));
        })['catch'](function (error) {
          _this6.flashError(_this6.get('i18n').t('components.manage_resources.notification_templates.flash_messages.error_saving'));
          return error;
        })['finally'](function () {
          _this6.set('isSavingTemplate', false);
        });
      }

    }

    // _handleErrors(response) {
    //   const message = this.get('errorHandler').buildErrorMessage(response, 'components.manage_resources.notification_templates.flash_messages');
    //   this.flashError(message);
    // }

  });
});