define("scientia-resourcebooker/pods/manage-resources/availability/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 15,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/availability/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "section-title-actions/item", [], ["icon", "icons/clock.svg", "text", ["subexpr", "t", ["pods.manage_resources.availability.index.create_pattern"], [], ["loc", [null, [12, 15], [12, 76]]], 0, 0], "routeTarget", "manage-resources.availability.new"], ["loc", [null, [10, 10], [14, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 16,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/availability/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "can", ["create availability"], [], ["loc", [null, [9, 14], [9, 41]]], 0, 0]], [], 0, null, ["loc", [null, [9, 8], [15, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/availability/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "section-title-actions", [], [], 0, null, ["loc", [null, [7, 6], [16, 32]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/availability/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-resourceTypesList");
        dom.setAttribute(el2, "id", "main-section");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(element1, 5, 5);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 53]]], 0, 0], ["block", "section-title", [], ["icon", "icons/clock.svg", "text", ["subexpr", "t", ["pods.manage_resources.availability.index.availability_patterns"], [], ["loc", [null, [5, 49], [5, 117]]], 0, 0], "location", "at-manageResourcesAvailabilityPatternsList"], 0, null, ["loc", [null, [5, 4], [18, 22]]]], ["inline", "manage-resources/resources-list/search", [], ["query", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [21, 12], [21, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.resources_list.search.search_patterns"], [], ["loc", [null, [22, 18], [22, 72]]], 0, 0]], ["loc", [null, [20, 4], [23, 6]]], 0, 0], ["inline", "manage-resources/item-types-list", [], ["items", ["subexpr", "@mut", [["get", "model", ["loc", [null, [26, 12], [26, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "availability", "iconUri", "icons/clock.svg", "location", "at-availabilityIndex", "deleteItemType", ["subexpr", "action", ["deleteItem"], [], ["loc", [null, [30, 21], [30, 42]]], 0, 0], "showItemPath", "manage-resources.availability.edit", "editItemPath", "manage-resources.availability.edit", "showDuplicateAction", ["subexpr", "can", ["edit availability"], [], ["loc", [null, [33, 26], [33, 51]]], 0, 0], "editAbilityPath", "seeEditIcon", "hideShowAction", true, "showBallPrefix", true, "ballPrefixPath", "enabled", "duplicateItemType", ["subexpr", "action", ["duplicatePattern"], [], ["loc", [null, [38, 24], [38, 51]]], 0, 0], "onBallClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [39, 36], [39, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 26], [39, 41]]], 0, 0]], [], ["loc", [null, [39, 18], [39, 42]]], 0, 0]], ["loc", [null, [25, 4], [40, 6]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});