define('scientia-resourcebooker/pods/manage-resources/system/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var TABS = { SETTINGS: 0, CALENDAR: 1, TEMPLATES: 2 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    calendarOptionsProcessor: _ember['default'].inject.service(),

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.system.page_title'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: {
      selectedTab: 'section'
    },

    calendarStartTime: _ember['default'].computed.oneWay('model.options.calendarStartTime'),
    calendarEndTime: _ember['default'].computed.oneWay('model.options.calendarEndTime'),
    calendarShowWeekends: _ember['default'].computed.oneWay('model.options.calendarShowWeekends'),
    calendarBookingTimes: _ember['default'].computed.oneWay('model.options.calendarBookingTimes'),
    bookingSpanMultipleDays: _ember['default'].computed.oneWay('model.options.bookingSpanMultipleDays'),

    error: '',

    selectedTab: 0,
    systemSettings: _ember['default'].inject.service(),
    isSaving: false,
    isSavingCalendarOptions: false,

    actions: {

      changeMode: function changeMode(mode) {
        this.set('model.options.systemMode', mode);
      },

      saveOptions: function saveOptions() {
        var _this = this;

        this.set('error', '');
        this.set('isSaving', true);

        this.get('systemSettings').update(this.get('model.options')).then(function (newOptions) {
          _this.flashSuccess(_this.get('i18n').t('pods.manage_resources.system.options_saved'));
          _this.set('model.options', newOptions);

          // If there's an error saving the options, lets show an error and invalidate the model to undo the changes in the UI
        })['catch'](function () {
          _this.send('invalidateModel');
          _this.flashError(_this.get('i18n').t('pods.manage_resources.system.options_not_saved'));
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      },

      saveCalendarOptions: function saveCalendarOptions(data) {
        var _this2 = this;

        this.set('error', '');

        // Validate Calendar Options
        var startTime = _ember['default'].get(data, 'calendarStartTime');
        var endTime = _ember['default'].get(data, 'calendarEndTime');
        if (!startTime || !endTime) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.calendar_times_required').toString(), TABS.CALENDAR);
        }

        this.set('isSavingCalendarOptions', true);
        this.get('calendarOptionsProcessor').saveSystemCalendar(data).then(function () {
          _this2.flashSuccess(_this2.get('i18n').t('pods.manage_resources.system.calendar_options_saved'));
        })['catch'](function () {
          _this2.flashError(_this2.get('i18n').t('pods.manage_resources.system.options_not_saved'));
        })['finally'](function () {
          _this2.set('isSavingCalendarOptions', false);
        });
      }
    },

    _displayError: function _displayError(error, tabIndex) {
      this.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false,
        isSavingCalendarOptions: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    }
  });
});