define('scientia-resourcebooker/pods/components/manage-resources/booking-type-manage-status/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'components.manage_resources.booking_type_manage_status';
  var MODES = ['live', 'disabled', 'hidden', 'offline'];
  var NOTIFICATION_TYPES = ['banner', 'popup'];

  exports['default'] = _ember['default'].Component.extend({
    localePrefix: DEFAULT_LOCALE_PREFIX,

    titleLabel: (function () {
      return this.get('localePrefix') + '.title';
    }).property(),

    disabled: false,

    editorOptions: _ember['default'].computed('disabled', 'elementId', function () {
      return {
        readonly: this.get('disabled'),
        menubar: false,
        toolbar1: 'undo redo | bold italic underline | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | code',
        'convert_urls': true,
        'relative_urls': false,
        'remove_script_host': false
      };
    }),

    modes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      return MODES.map(function (mode, i) {
        return {
          id: i.toString(),
          text: _this.get('i18n').t(DEFAULT_LOCALE_PREFIX + ('.' + mode)).toString(),
          description: _this.get('i18n').t(DEFAULT_LOCALE_PREFIX + ('.' + mode + '_description')).toString()
        };
      });
    }),

    notificationTypes: _ember['default'].computed('i18n.locale', function () {
      var _this2 = this;

      return NOTIFICATION_TYPES.map(function (type, i) {
        return {
          id: i,
          label: _this2.get('i18n').t(DEFAULT_LOCALE_PREFIX + ('.' + type)).toString()
        };
      });
    }),

    actions: {
      setMode: function setMode(value) {
        this.set('model.mode', value);
      },

      setNotificationType: function setNotificationType(type, value) {
        this.get('model').set(type, value.id);
      }
    }

  });
});