define("scientia-resourcebooker/pods/components/collapsable-section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/collapsable-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "collapsableSection-header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "collapsableSection-headerText");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "collapsableSection-caret");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "collapsableSection-content");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        return morphs;
      },
      statements: [["element", "action", [["subexpr", "toggle-action", ["open", ["get", "this", ["loc", [null, [1, 70], [1, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 48], [1, 75]]], 0, 0]], [], ["loc", [null, [1, 39], [1, 77]]], 0, 0], ["inline", "inline-svg", ["icons/caret-down.svg"], ["class", ["subexpr", "if", [["get", "open", ["loc", [null, [4, 52], [4, 56]]], 0, 0, 0, 0], "expanded", "collapsed"], [], ["loc", [null, [4, 48], [4, 80]]], 0, 0]], ["loc", [null, [4, 6], [4, 82]]], 0, 0], ["content", "title", ["loc", [null, [6, 4], [6, 13]]], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [8, 40], [8, 49]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});