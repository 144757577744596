define('scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestOverview-occurrencesList'],
    classNameBindings: ['isShowingOccurrences:bookingRequestOverview-occurrencesList--expanded'],

    occurrenceDateFormat: (0, _emberI18n.translationMacro)('components.booking_request_overview.occurrence_date_format'),
    occurrenceTimeFormat: (0, _emberI18n.translationMacro)('components.booking_request_overview.occurrence_time_format'),

    actions: {

      datesAreNotSameDay: function datesAreNotSameDay(start, end) {
        if (!start || !end) return false;

        // Subtract 1min from endTime as 00:00 is considered next day and would fail our boolean below
        return !moment(start).isSame(moment(end).clone().subtract(1, 'minute'), 'day');
      }
    }

  });
});