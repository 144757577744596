define("scientia-resourcebooker/pods/components/booking-request-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 3,
                "column": 137
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "aria-hidden", "true");
            dom.setAttribute(el1, "alt", "Icon");
            dom.setAttribute(el1, "class", "bookingRequestForm-titleIcon");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element13, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "mainResource.imageUri", ["loc", [null, [3, 73], [3, 94]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestForm-titleIconWrapper");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element14 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element14, 1, 1);
          morphs[1] = dom.createMorphAt(element14, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "mainResource.imageUri", ["loc", [null, [3, 8], [3, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [3, 144]]]], ["content", "mainResource.name", ["loc", [null, [3, 145], [3, 166]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-errors");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "formError", ["loc", [null, [10, 6], [10, 21]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 4
              },
              "end": {
                "line": 25,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "booking-type-notification-banner", [], ["location", "at-bookingRequestForm", "model", ["subexpr", "hash", [], ["info", true, "message", ["subexpr", "t", ["components.booking_request_form.adjusted_times"], [], ["loc", [null, [20, 16], [20, 68]]], 0, 0]], ["loc", [null, [18, 12], [21, 9]]], 0, 0], "onClose", ["subexpr", "action", [["subexpr", "mut", [["get", "showAdjustedTimesBanner", ["loc", [null, [22, 27], [22, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 22], [22, 51]]], 0, 0], false], [], ["loc", [null, [22, 14], [22, 58]]], 0, 0]], ["loc", [null, [16, 6], [23, 8]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 4
                },
                "end": {
                  "line": 42,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--reference");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_overview.reference"], [], ["loc", [null, [38, 85], [38, 138]]], 0, 0], ["content", "model.reference", ["loc", [null, [40, 12], [40, 31]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 2
              },
              "end": {
                "line": 49,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--status");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-mainResource");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.reference", ["loc", [null, [36, 10], [36, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [36, 4], [42, 11]]]], ["inline", "t", ["pods.logged_in.resource.show.status"], [], ["loc", [null, [44, 85], [44, 128]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.booking_request_overview.statuses.", ["get", "model.status", ["loc", [null, [46, 120], [46, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 56], [46, 133]]], 0, 0]], [], ["loc", [null, [46, 52], [46, 135]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 2
              },
              "end": {
                "line": 54,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "recurring-tabs", [], ["selected", ["subexpr", "@mut", [["get", "bookingMode", ["loc", [null, [53, 30], [53, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingMode", ["loc", [null, [53, 64], [53, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 59], [53, 76]]], 0, 0]], [], ["loc", [null, [53, 51], [53, 77]]], 0, 0], "location", "at-bookingRequest-form"], ["loc", [null, [53, 4], [53, 113]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 6
                },
                "end": {
                  "line": 65,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-updateRecurrencePattern");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("input");
              dom.setAttribute(el3, "type", "checkbox");
              dom.setAttribute(el3, "class", "at-updateRecurrencePattern-checkbox");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1, 1]);
              var element8 = dom.childAt(element7, [1]);
              if (this.cachedFragment) {
                dom.repairClonedNode(element8, [], true);
              }
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element8, 'checked');
              morphs[1] = dom.createElementMorph(element8);
              morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "checked", ["subexpr", "eq", [["get", "updateOccurrencePattern", ["loc", [null, [61, 88], [61, 111]]], 0, 0, 0, 0], true], [], ["loc", [null, [null, null], [61, 118]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleUpdateOccurrencePattern"], ["on", "change"], ["loc", [null, [61, 119], [61, 173]]], 0, 0], ["inline", "t", ["components.booking_request_form.update_recurrence_pattern"], [], ["loc", [null, [62, 14], [62, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 10
                  },
                  "end": {
                    "line": 72,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "recurring-event-fields-limited", [], ["model", ["subexpr", "@mut", [["get", "model.recurrencePattern", ["loc", [null, [71, 51], [71, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [71, 91], [71, 106]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "atResourceFilters"], ["loc", [null, [71, 12], [71, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 8
                },
                "end": {
                  "line": 74,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "updateOccurrencePattern", ["loc", [null, [70, 20], [70, 43]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isEditing", ["loc", [null, [70, 49], [70, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 44], [70, 59]]], 0, 0]], [], ["loc", [null, [70, 16], [70, 60]]], 0, 0]], [], 0, null, ["loc", [null, [70, 10], [72, 17]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 75,
                    "column": 10
                  },
                  "end": {
                    "line": 77,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "recurring-event-fields", [], ["model", ["subexpr", "@mut", [["get", "model.recurrencePattern", ["loc", [null, [76, 43], [76, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [76, 83], [76, 98]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "atBookingRequestForm"], ["loc", [null, [76, 12], [76, 129]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 8
                },
                "end": {
                  "line": 78,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "updateOccurrencePattern", ["loc", [null, [75, 20], [75, 43]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isEditing", ["loc", [null, [75, 49], [75, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 44], [75, 59]]], 0, 0]], [], ["loc", [null, [75, 16], [75, 60]]], 0, 0]], [], 0, null, ["loc", [null, [75, 10], [77, 17]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 4
              },
              "end": {
                "line": 80,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--recurring");
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isEditing", ["loc", [null, [58, 12], [58, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [58, 6], [65, 13]]]], ["block", "if", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [69, 14], [69, 55]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [69, 8], [78, 15]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 80,
                "column": 4
              },
              "end": {
                "line": 91,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            return morphs;
          },
          statements: [["inline", "booking-request-form/datetime-section", [], ["calendarDaysDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [84, 25], [84, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [85, 10], [85, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingType", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [86, 16], [86, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "useOldTimePicker", ["subexpr", "@mut", [["get", "useOldTimePicker", ["loc", [null, [87, 21], [87, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [88, 20], [88, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [83, 2], [89, 4]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 6
                  },
                  "end": {
                    "line": 109,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("label");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "sr-only");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(element3, 3, 3);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_form.bobo.title"], [], ["loc", [null, [106, 32], [106, 82]]], 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookOnBehalfOfText", ["loc", [null, [107, 25], [107, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.bobo.placeholder"], [], ["loc", [null, [107, 73], [107, 127]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-textarea"], ["loc", [null, [107, 8], [107, 201]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 97,
                  "column": 2
                },
                "end": {
                  "line": 111,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--bobo");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "bookingRequestForm-labelText");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var element5 = dom.childAt(element4, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element5, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element4, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "bookingRequestForm-input", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "boboBaseSettings.bookOnBehalfOfChecked", ["loc", [null, [100, 75], [100, 113]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [100, 10], [100, 115]]], 0, 0], ["inline", "t", ["components.booking_request_form.bobo.title"], [], ["loc", [null, [101, 53], [101, 103]]], 0, 0], ["block", "if", [["get", "boboBaseSettings.bookOnBehalfOfChecked", ["loc", [null, [104, 12], [104, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [104, 6], [109, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 121,
                    "column": 6
                  },
                  "end": {
                    "line": 125,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseOwner", ["loc", [null, [122, 34], [122, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.identifier_placeholder"], [], ["loc", [null, [122, 86], [122, 151]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [122, 8], [122, 227]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseEmail", ["loc", [null, [123, 34], [123, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.email_placeholder"], [], ["loc", [null, [123, 86], [123, 146]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [123, 8], [123, 222]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseName", ["loc", [null, [124, 34], [124, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.name_placeholder"], [], ["loc", [null, [124, 85], [124, 144]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [124, 8], [124, 220]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 3
                },
                "end": {
                  "line": 127,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--base");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "bookingRequestForm-labelText");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "bookingRequestForm-input", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseChecked", ["loc", [null, [117, 75], [117, 116]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [117, 10], [117, 118]]], 0, 0], ["inline", "t", ["components.booking_request_form.base.title"], [], ["loc", [null, [118, 53], [118, 103]]], 0, 0], ["block", "if", [["get", "boboBaseSettings.bookAsSomeoneElseChecked", ["loc", [null, [121, 12], [121, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [121, 6], [125, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 137,
                  "column": 2
                },
                "end": {
                  "line": 145,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "additional-resource-booking", [], ["resourcesSelected", ["subexpr", "@mut", [["get", "resourcesSelected", ["loc", [null, [139, 22], [139, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "resourceRestrictions", ["subexpr", "@mut", [["get", "bookingType.resourceRestrictionRules", ["loc", [null, [140, 25], [140, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "atBookingForm", true, "didSelectResourceRestrictionResource", ["subexpr", "action", [["get", "didSelectResourceRestrictionResource", ["loc", [null, [142, 49], [142, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [142, 41], [142, 86]]], 0, 0], "searchResources", ["subexpr", "action", [["get", "searchResources", ["loc", [null, [143, 28], [143, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [143, 20], [143, 44]]], 0, 0]], ["loc", [null, [138, 4], [144, 6]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 147,
                  "column": 2
                },
                "end": {
                  "line": 154,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--description");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element0, 3, 3);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_form.description"], [], ["loc", [null, [150, 87], [150, 138]]], 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [151, 25], [151, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-textrea"], ["loc", [null, [151, 8], [151, 115]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 156,
                  "column": 2
                },
                "end": {
                  "line": 158,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-request-form-tabs", [], ["onTabClick", ["subexpr", "action", ["onTabClick"], [], ["loc", [null, [157, 43], [157, 64]]], 0, 0], "model", ["subexpr", "@mut", [["get", "bookingForm.sections", ["loc", [null, [157, 71], [157, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [157, 104], [157, 115]]], 0, 0, 0, 0]], [], [], 0, 0], "formId", ["subexpr", "@mut", [["get", "bookingForm.id", ["loc", [null, [157, 123], [157, 137]]], 0, 0, 0, 0]], [], [], 0, 0], "isEditing", ["subexpr", "@mut", [["get", "isEditing", ["loc", [null, [157, 148], [157, 157]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [157, 4], [157, 159]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 94,
                "column": 2
              },
              "end": {
                "line": 160,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--guests bookingRequestForm-fields--separator");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "bookingRequestForm-label");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [5, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element6, 3, 3);
            morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canBookOnBehalfOf", ["loc", [null, [97, 8], [97, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [97, 2], [111, 9]]]], ["block", "if", [["get", "canBookAsSomeoneElse", ["loc", [null, [114, 9], [114, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [114, 3], [127, 9]]]], ["inline", "t", ["components.booking_request_form.guests"], [], ["loc", [null, [131, 85], [131, 131]]], 0, 0], ["inline", "rb-select", [], ["placeholder", ["subexpr", "t", ["components.booking_request_form.select_guest"], [], ["loc", [null, [132, 30], [132, 80]]], 0, 0], "class", "bookingRequestForm-select"], ["loc", [null, [132, 6], [132, 116]]], 0, 0], ["block", "if", [["get", "shouldShowAdditionalResources", ["loc", [null, [137, 8], [137, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [137, 2], [145, 9]]]], ["block", "if", [["get", "shouldShowDescriptionField", ["loc", [null, [147, 8], [147, 34]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [147, 2], [154, 9]]]], ["block", "if", [["get", "bookingForm", ["loc", [null, [156, 8], [156, 19]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [156, 2], [158, 9]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 161,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--title bookingRequestForm-title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "bookingRequestForm-label");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block ");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "text");
          dom.setAttribute(el3, "class", "form-input bookingRequestForm-input bookingRequestForm-title-input at-bookingRequestForm-bookingName");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [4, 1]);
          var element12 = dom.childAt(element11, [3]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
          morphs[3] = dom.createAttrMorph(element12, 'disabled');
          morphs[4] = dom.createAttrMorph(element12, 'value');
          morphs[5] = dom.createAttrMorph(element12, 'oninput');
          morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[7] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[8] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[9] = dom.createMorphAt(fragment, 11, 11, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "formError", ["loc", [null, [8, 8], [8, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 2], [12, 9]]]], ["block", "if", [["get", "showAdjustedTimesBanner", ["loc", [null, [15, 10], [15, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 4], [25, 11]]]], ["inline", "t", ["components.booking_request_form.name_placeholder"], [], ["loc", [null, [29, 86], [29, 142]]], 0, 0], ["attribute", "disabled", ["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [30, 36], [30, 74]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.name", ["loc", [null, [30, 85], [30, 95]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.name", ["loc", [null, [30, 120], [30, 130]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 115], [30, 131]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [30, 154]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isEditing", ["loc", [null, [35, 8], [35, 17]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [35, 2], [49, 9]]]], ["block", "unless", [["get", "disableRecurring", ["loc", [null, [52, 12], [52, 28]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [52, 2], [54, 13]]]], ["block", "if", [["subexpr", "and", [["get", "repeatBooking", ["loc", [null, [56, 15], [56, 28]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [56, 34], [56, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 29], [56, 73]]], 0, 0], ["subexpr", "not", [["get", "disableRecurring", ["loc", [null, [56, 79], [56, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [56, 74], [56, 96]]], 0, 0]], [], ["loc", [null, [56, 10], [56, 97]]], 0, 0]], [], 4, 5, ["loc", [null, [56, 4], [91, 9]]]], ["block", "unless", [["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [94, 12], [94, 50]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [94, 2], [160, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 164,
                "column": 2
              },
              "end": {
                "line": 164,
                "column": 185
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "if", [["get", "isEditing", ["loc", [null, [164, 86], [164, 95]]], 0, 0, 0, 0], ["subexpr", "t", ["components.booking_request_form.update"], [], ["loc", [null, [164, 96], [164, 140]]], 0, 0], ["subexpr", "t", ["components.booking_request_form.book"], [], ["loc", [null, [164, 141], [164, 183]]], 0, 0]], [], ["loc", [null, [164, 81], [164, 185]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 163,
              "column": 0
            },
            "end": {
              "line": 165,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [164, 26], [164, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "submit", "class", "button button--primary"], 0, null, ["loc", [null, [164, 2], [164, 202]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 166,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [1, 32], [1, 37]]], 0, 0, 0, 0], ["get", "isSaving", ["loc", [null, [1, 38], [1, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 24], [1, 47]]], 0, 0], "needsMinimizeButton", true], 0, null, ["loc", [null, [1, 0], [5, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [7, 0], [161, 18]]]], ["block", "modal-footer", [], ["class", "bookingRequestForm-modalFooter"], 2, null, ["loc", [null, [163, 0], [165, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});