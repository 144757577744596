define('scientia-resourcebooker/pods/components/modal-header/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'header',
    classNames: ['lf-dialog-header'],
    classNameBindings: ['class', 'needsCloseButton'],

    title: '',
    needsCloseButton: true,
    needsMinimizeButton: false,
    isMinimized: false,

    actions: {

      onHeaderClick: function onHeaderClick() {
        _ember['default'].$('.lf-dialog').removeClass('lf-dialog--minimized');
        this.set('isMinimized', false);
      },

      close: function close() {
        this.sendAction('onClose');
      },

      toggleMinimize: function toggleMinimize() {
        this.toggleProperty('isMinimized');
        _ember['default'].$('.lf-dialog').toggleClass('lf-dialog--minimized');
      }
    }
  });
});