define('scientia-resourcebooker/services/resource-unavailability-preview-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  var AvailabilityEntityType = {
    0: 'Resource',
    1: 'BookingType',
    2: 'Event',
    3: 'Booking',
    4: 'General',
    5: 'Concurrency',
    6: 'AvailabilityPattern'
  };

  var AvailabilityType = {
    0: 'Specific',
    1: 'Repeating',
    2: 'Exception'
  };

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var userGroupId = _ref.userGroupId;
      var includeDisabledPatternsUnavailability = _ref.includeDisabledPatternsUnavailability;

      var url = this._urlFromIds(id, parentId, type);
      var data = {
        rangeStartDate: startDate.startOf('minute').toISOString(),
        rangeEndDate: endDate.startOf('minute').toISOString(),
        excludeDisabledPatterns: !includeDisabledPatternsUnavailability
      };

      if (userGroupId && userGroupId !== DEFAULT_ALL_GROUPS) data.userGroupIdentity = userGroupId;

      return this.get('authenticatedRequest').fetch(url, data).then(function (results) {
        return _this._normalizeResults(results);
      });
    },

    _urlFromIds: function _urlFromIds(id, parentId, type) {
      if (parentId) {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_UNAVAILABILITY_PREVIEW[type].loc(parentId, id);
      } else {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_UNAVAILABILITY_PREVIEW[type].loc(id);
      }
    },

    _normalizeResults: function _normalizeResults(results) {
      var _this2 = this;

      // return data;
      var data = results.map(function (item, i) {

        // Adjust events that span DST boundaries (have a different start and end utc offsets)
        var start = moment(item.startDateTime);
        var end = start.clone().add(item.duration, 'minute');

        if (item.availabilityType === 1) {

          var startOffset = start.clone().subtract(1, 'minute').utcOffset();
          var endOffset = end.clone().subtract(1, 'minute').utcOffset();

          if (startOffset > endOffset) {
            item.duration += startOffset;
          } else if (startOffset < endOffset) {
            item.duration -= endOffset;
          }
        }

        var ev = {
          startDateTime: start,
          endDateTime: moment(item.startDateTime).add(item.duration, 'minute'),
          availabilityType: AvailabilityType[item.availabilityType],
          entityType: AvailabilityEntityType[item.entityType],
          tag: _this2._getTagNameFromType(item),
          id: i,
          name: _this2._getNameFromItem(item),
          extraClassNames: 'chronos-unavailability'
        };

        if (ev.endDateTime.minute() === 59) ev.endDateTime.add(1, 'minute');

        return ev;
      });

      var masking = this._generateMaskingResults(data);
      data.push.apply(data, _toConsumableArray(masking));
      return data;
    },

    _generateMaskingResults: function _generateMaskingResults(data) {
      return data.map(function (d) {
        return {
          id: d.id + '-masking',
          startDateTime: d.startDateTime,
          endDateTime: d.endDateTime,
          isMasking: true
        };
      });
    },

    _getTagNameFromType: function _getTagNameFromType(item) {
      var s = AvailabilityEntityType[item.entityType] + '_' + AvailabilityType[item.availabilityType];
      return s.toLowerCase();
    },

    _getNameFromItem: function _getNameFromItem(item) {

      // Return "Resource" if availability is for Resource
      if (item.entityType === 0) {
        return this.get('i18n').t('calendar.header.tags.resource').toString();
      } else if (item.entityType === 1) {
        return this.get('i18n').t('calendar.header.tags.template').toString();
      } else {
        return item.name;
      }
    }
  });
});