define('scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/component', ['exports', 'ember'], function (exports, _ember) {
  var ITEM_TYPE = 'booking-form-field-single-choice-item';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestForm-field bookingRequestForm-field-singleChoice'],
    classNameBindings: ['model.isRadio:bookingRequestForm-field-singleChoice--radio'],

    attributeBindings: ['fieldId:data-field-id'],
    fieldId: _ember['default'].computed.alias('model.identity'),

    authenticatedRequest: _ember['default'].inject.service(),
    externalDataSourceFetcher: _ember['default'].inject.service(),

    externalData: [],
    externalDataError: null,
    isUsingExternalDataContent: _ember['default'].computed.and('model.externalDataSourceUri', 'model.isUsingExternalDataSource'),

    content: _ember['default'].computed('model.items.length', 'isUsingExternalDataContent', function () {
      return this.get('isUsingExternalDataContent') ? [] : this.get('model.items');
    }),

    disabled: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].run.schedule('afterRender', this, function () {

        if (this.get('isUsingExternalDataContent')) {
          this._fetchExternalDataContent();
        } else {
          this._setDefaultValue();
          this._removeInvalidValue();
        }
      });
    },

    checkedValue: _ember['default'].computed.oneWay('model.value.value'),

    actions: {

      externalDataSourceSearch: function externalDataSourceSearch(query, deferred) {
        _ember['default'].run.debounce(this, '_searchExternalDataSource', query, deferred, 1000);
      },

      updateRadioValue: function updateRadioValue(value) {
        var selectedItem = this.get('model.items').findBy('value', value);
        this.set('model.value', selectedItem);
        if (value === null) {
          this.set('model.value', null);
          this.set('checkedValue', null);
          this.$().find('input:checked')[0].checked = false;
        }
      }
    },

    _searchExternalDataSource: function _searchExternalDataSource(query, deferred) {
      var _this = this;

      var fetchURL = this.get('model.externalDataSourceUri');
      this.get('externalDataSourceFetcher').fetch({ url: fetchURL, modelName: ITEM_TYPE, query: query.term, page: query.page }).then(function (results) {
        deferred.resolve(results);
      })['catch'](function () {
        _this.setProperties({
          externalDataError: _this.get('i18n').t('errors.externalDataSourceFetchError').toString(),
          disabled: true
        });
      });
    },

    _fetchExternalDataContent: function _fetchExternalDataContent() {
      var _this2 = this;

      var fetchURL = this.get('model.externalDataSourceUri');
      this.get('externalDataSourceFetcher').fetch({ url: fetchURL, modelName: ITEM_TYPE }).then(function (results) {
        _this2.set('model.items', results.data);
        if (_this2.get('model.isRadio')) {
          _this2._removeInvalidValue();
        }
      })['catch'](function () {
        _this2.setProperties({
          externalDataError: _this2.get('i18n').t('errors.externalDataSourceFetchError').toString(),
          disabled: true
        });
      });
    },

    _setDefaultValue: function _setDefaultValue() {
      if (this.get('isEditing')) return;

      // We only want to set a defaultValue if the model.value is undefined (ie. never set) because
      // the user could have already unchecked the default value (set it to null) and we don't want to recheck it if
      // it was unchecked already
      if (this.get('model.defaultValue') && this.get('model.value') === undefined) {
        var defaultValue = this.get('model.items').findBy('value', this.get('model.defaultValue'));

        if (defaultValue) {
          this.set('model.value', defaultValue);
        }
      }
    },

    _removeInvalidValue: function _removeInvalidValue() {
      var items = this.get('model.items');
      var foundValue = items.findBy('value', this.get('checkedValue'));
      if (!items || _ember['default'].isEmpty(foundValue)) {
        this.set('model.value', null);
      }
    }

  });
});