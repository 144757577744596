define('scientia-resourcebooker/pods/components/rb-calendar-day-view/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    'displayed-date': null,
    'calendar-events': [],
    withBookingRequest: false,
    fromTimePath: 'startDateTime',
    toTimePath: 'endDateTime',
    titlePath: 'description',
    subtitlePath: null,
    waitForLoading: false,
    isLoading: false,

    tagPath: (function () {
      return this.get('withBookingRequest') ? 'status' : 'eventType';
    }).property('withBookingRequest'),

    actions: {

      onDateClick: function onDateClick(date) {
        if (this.get('waitForLoading') && this.get('isLoading')) return;
        this.sendAction('on-date-click', date);
      },

      onEventClick: function onEventClick(event) {
        this.sendAction('on-event-click', event);
      },

      onEventCountBadgeClick: function onEventCountBadgeClick(date) {
        this.sendAction('on-event-count-badge-click', date);
      }
    }
  });
});