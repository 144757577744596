define('scientia-resourcebooker/pods/components/additional-resource-booking/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['additionalResourceBooking'],

    resourceRestrictions: [],

    hasRequiredRestrictions: _ember['default'].computed('resourceRestrictions.@each.min', function () {
      return !this.get('resourceRestrictions').rejectBy('isPrimary').isEvery('min', 0);
    }),

    hasOptionalRestrictions: _ember['default'].computed('resourceRestrictions.@each.min', function () {
      return this.get('resourceRestrictions').rejectBy('isPrimary').isAny('min', 0);
    }),

    actions: {

      filterByRequiredRestrictions: function filterByRequiredRestrictions(minValue) {
        return minValue > 0;
      },

      filterByOptionalRestrictions: function filterByOptionalRestrictions(minValue) {
        return minValue === 0;
      }
    }

  });
});