define('scientia-resourcebooker/services/custom-events', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    triggerCustomFiltersSelectedChanged: function triggerCustomFiltersSelectedChanged() {
      this.trigger('customFiltersSelectedChanged');
    },

    triggerRemoveCustomFilter: function triggerRemoveCustomFilter(filter) {
      this.trigger('removeCustomFilter', filter);
    },

    triggerResetFilters: function triggerResetFilters() {
      this.trigger('resetFilters');
    },

    triggerClearResourceFilters: function triggerClearResourceFilters() {
      this.trigger('clearResourceFilters');
    },

    triggerLocaleChanged: function triggerLocaleChanged() {
      this.trigger('localeChanged');
    },

    triggerNotificationCountUpdated: function triggerNotificationCountUpdated(count) {
      this.trigger('notificationCountUpdated', count);
    }

  });
});