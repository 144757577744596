define("scientia-resourcebooker/pods/components/time-input-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/time-input-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        return morphs;
      },
      statements: [["inline", "rb-select", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [2, 10], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "value", "optionLabelPath", "label", "value", ["subexpr", "if", [["get", "isRefreshingValue", ["loc", [null, [5, 12], [5, 29]]], 0, 0, 0, 0], null, ["subexpr", "readonly", [["get", "value", ["loc", [null, [5, 45], [5, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 35], [5, 51]]], 0, 0]], [], ["loc", [null, [5, 8], [5, 52]]], 0, 0], "showPlaceholder", true, "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [7, 11], [7, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "-- : --", "onChange", ["subexpr", "action", ["onChange"], [], ["loc", [null, [9, 11], [9, 30]]], 0, 0]], ["loc", [null, [1, 2], [10, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});