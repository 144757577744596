define('scientia-resourcebooker/pods/components/resources-list/item/show-properties-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourcesList-item-showPropertiesButton'],
    nestedResourceStore: _ember['default'].inject.service(),

    resource: null,
    properties: [],
    groupId: null,
    bookingTypeId: null,
    processingProperties: false,
    showProperties: false,
    propertiesLoaded: false,

    actions: {
      toggleProperties: function toggleProperties() {
        var _this = this;

        if (this.get('properties.length')) {
          this.toggleProperty('showProperties');
          return;
        }

        this.toggleProperty('processingProperties');

        this.get('nestedResourceStore').fetch({
          type: 'bookingType.resource',
          parentId: this.get('bookingTypeId'),
          // groupId: this.get('groupId'),
          id: this.get('resource.id')
        }).then(function (resource) {
          _this.toggleProperty('propertiesLoaded');
          _this.toggleProperty('showProperties');

          _this.set('properties', resource.get('properties'));
        })['finally'](function () {
          _this.toggleProperty('processingProperties');
        });
      }
    }
  });
});