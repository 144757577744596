define('scientia-resourcebooker/pods/components/booking-request-form-tabs/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    bookingFormMainSection: _ember['default'].computed.alias('model.firstObject'),
    bookingFormHasExtraSections: _ember['default'].computed.gt('model.length', 1),
    bookingFormExtraSections: _ember['default'].computed('model.[]', 'model.@each.fields[]', function () {
      return this.get('model').reject(function (x) {

        if (x.get('rank') === 0 || _ember['default'].isEmpty(x.get('fields'))) return true;

        var totalFields = x.get('fields.length');
        var inactiveFields = x.get('fields').rejectBy('isActive').get('length');
        return inactiveFields === totalFields;
      }).sortBy('rank');
      // return this.get('model').rejectBy('rank', 0).sortBy('rank');
    })

  });
});