define("scientia-resourcebooker/pods/logged-in/user-profile/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/permissions-summary"], ["model", ["subexpr", "@mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [7, 14], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [8, 14], [8, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [9, 27], [9, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 22], [9, 62]]], 0, 0], null], [], ["loc", [null, [9, 14], [9, 68]]], 0, 0]], ["loc", [null, [5, 11], [9, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [10, 34], [10, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 29], [10, 69]]], 0, 0], null], [], ["loc", [null, [10, 21], [10, 75]]], 0, 0]], ["loc", [null, [4, 9], [10, 76]]], 0, 0]], ["loc", [null, [2, 2], [11, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 4
              },
              "end": {
                "line": 23,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "help-trigger", ["loc", [null, [22, 6], [22, 22]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "unless", [["get", "media.isTiny", ["loc", [null, [21, 14], [21, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 4], [23, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 4
            },
            "end": {
              "line": 37,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "user-profile-info", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [31, 12], [31, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "canViewOwnPermissionSummary", ["subexpr", "@mut", [["get", "canViewOwnPermissionSummary", ["loc", [null, [32, 34], [32, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "displayMode", ["subexpr", "@mut", [["get", "userProfileDisplayMode", ["loc", [null, [33, 18], [33, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "isLoadingPermissionSummary", ["subexpr", "@mut", [["get", "fetchPermissionSummary.isRunning", ["loc", [null, [34, 33], [34, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "fetchPermissionSummary", ["subexpr", "action", [["subexpr", "perform", [["get", "fetchPermissionSummary", ["loc", [null, [35, 46], [35, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 37], [35, 69]]], 0, 0]], [], ["loc", [null, [35, 29], [35, 70]]], 0, 0]], ["loc", [null, [30, 6], [36, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 4
            },
            "end": {
              "line": 39,
              "column": 133
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["components.user_profile_form.save"], [], ["loc", [null, [39, 92], [39, 133]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 43,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/user-profile/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        dom.setAttribute(el1, "id", "main-section");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-userProfile");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element0, 5, 5);
        morphs[4] = dom.createMorphAt(element0, 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showResourcePermissionSummaryModal", ["loc", [null, [1, 6], [1, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 7]]]], ["block", "section-title", [], ["icon", "icons/user.svg", "text", ["subexpr", "t", ["pods.logged_in.user_profile.page_title"], [], ["loc", [null, [19, 9], [19, 53]]], 0, 0]], 1, null, ["loc", [null, [17, 4], [25, 22]]]], ["inline", "user-profile-form", [], ["save", ["subexpr", "action", ["save"], [], ["loc", [null, [27, 29], [27, 44]]], 0, 0], "isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [27, 54], [27, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 4], [27, 64]]], 0, 0], ["block", "if", [["get", "canShowUserProfile", ["loc", [null, [29, 10], [29, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [29, 4], [37, 11]]]], ["block", "async-button", [], ["action", ["subexpr", "action", ["save"], [], ["loc", [null, [39, 27], [39, 42]]], 0, 0], "loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [39, 51], [39, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "button button--primary"], 3, null, ["loc", [null, [39, 4], [39, 150]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});