define("scientia-resourcebooker/pods/components/calendar-download/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["components.calendar_modal.title"], [], ["loc", [null, [3, 1], [3, 40]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 8
                  },
                  "end": {
                    "line": 15,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "loading-icon", [], ["location", "at-calendarDownloadModal"], ["loc", [null, [13, 10], [13, 62]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 22,
                        "column": 14
                      },
                      "end": {
                        "line": 27,
                        "column": 14
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "calendarDownload-icon-label");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createAttrMorph(element3, 'class');
                    morphs[1] = dom.createElementMorph(element3);
                    morphs[2] = dom.createMorphAt(element3, 1, 1);
                    morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "class", ["concat", ["calendarDownload-icon-container calendarDownload-icon-container--", ["subexpr", "alphanumericfy", [["get", "feed.name", ["loc", [null, [23, 110], [23, 119]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 93], [23, 121]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["subscribe", ["get", "feed", ["loc", [null, [23, 144], [23, 148]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 123], [23, 150]]], 0, 0], ["inline", "inline-svg", [["get", "feed.icon", ["loc", [null, [24, 31], [24, 40]]], 0, 0, 0, 0]], ["class", "calendarDownload-icon"], ["loc", [null, [24, 18], [24, 72]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.calendar_modal.icon_labels.", ["subexpr", "alphanumericfy", [["get", "feed.name", ["loc", [null, [25, 137], [25, 146]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 121], [25, 147]]], 0, 0]], [], ["loc", [null, [25, 64], [25, 148]]], 0, 0]], [], ["loc", [null, [25, 60], [25, 150]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 20,
                      "column": 12
                    },
                    "end": {
                      "line": 28,
                      "column": 12
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
                },
                isEmpty: false,
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "feed.icon", ["loc", [null, [22, 20], [22, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 14], [27, 21]]]]],
                locals: ["n", "feed"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 8
                  },
                  "end": {
                    "line": 31,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "calendarDownload-note");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "calendarDownload-icons");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["components.calendar_modal.subscribe_text_with_icons"], [], ["loc", [null, [17, 46], [17, 105]]], 0, 0], ["block", "each-in", [["get", "calendarFeeds", ["loc", [null, [20, 23], [20, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 12], [28, 24]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 6
                },
                "end": {
                  "line": 32,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "isLoadingFeeds", ["loc", [null, [12, 14], [12, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [12, 8], [31, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "collapsable-section", [], ["open", true, "title", ["subexpr", "t", ["components.calendar_modal.subscribe_to_calendar_title"], [], ["loc", [null, [10, 45], [10, 104]]], 0, 0]], 0, null, ["loc", [null, [10, 6], [32, 30]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 6
                  },
                  "end": {
                    "line": 47,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "calendarDownload-note");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["components.calendar_modal.subscribe_text_no_icons"], [], ["loc", [null, [45, 10], [45, 67]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 4
                },
                "end": {
                  "line": 60,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "calendarDownload-note");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "calendarDownload-messageActions");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "hideIcalButtons", ["loc", [null, [43, 12], [43, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 6], [47, 13]]]], ["inline", "t", ["components.calendar_modal.subscribe_default_text"], [], ["loc", [null, [50, 8], [50, 64]]], 0, 0], ["inline", "button-text", [], ["icon", "icons/download.svg", "text", ["subexpr", "t", ["components.calendar_modal.subscribe"], [], ["loc", [null, [56, 13], [56, 54]]], 0, 0], "onClick", ["subexpr", "action", ["subscribe", ["get", "calendarFeeds.webCal", ["loc", [null, [57, 36], [57, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 16], [57, 57]]], 0, 0]], ["loc", [null, [54, 8], [58, 10]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 82,
                    "column": 6
                  },
                  "end": {
                    "line": 89,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "calendarDownload-statusMessage");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "calendarDownload-note");
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element2, 'class');
                morphs[1] = dom.createMorphAt(element2, 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element1, [3, 1]), 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["get", "copyClass", ["loc", [null, [84, 22], [84, 31]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "copyMessage", ["loc", [null, [84, 34], [84, 49]]], 0, 0, 0, 0], ["inline", "t", ["components.calendar_modal.note_copy"], [], ["loc", [null, [86, 16], [86, 59]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 4
                },
                "end": {
                  "line": 90,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "calendarDownload-note");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "sr-only");
              var el3 = dom.createTextNode("URL");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("textarea");
              dom.setAttribute(el2, "id", "calendarDownload-url");
              dom.setAttribute(el2, "readonly", "true");
              dom.setAttribute(el2, "rows", "3");
              dom.setAttribute(el2, "class", "form-input at-calendarDownload calendarDownloadURL");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "calendarDownload-messageActions");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
              morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["components.calendar_modal.subscribe_manually_text"], [], ["loc", [null, [67, 14], [67, 71]]], 0, 0], ["content", "calendarFeeds.webCal.url", ["loc", [null, [72, 128], [72, 156]]], 0, 0, 0, 0], ["inline", "button-text", [], ["icon", "icons/copy.svg", "text", ["subexpr", "t", ["components.calendar_modal.copy"], [], ["loc", [null, [77, 13], [77, 49]]], 0, 0], "onClick", ["subexpr", "action", ["copy"], [], ["loc", [null, [78, 16], [78, 31]]], 0, 0]], ["loc", [null, [75, 8], [79, 10]]], 0, 0], ["block", "if", [["get", "copyMessage", ["loc", [null, [82, 12], [82, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [82, 6], [89, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 2
              },
              "end": {
                "line": 92,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "collapsable-section", [], ["title", ["subexpr", "t", ["components.calendar_modal.subscribe_default_title"], [], ["loc", [null, [41, 33], [41, 88]]], 0, 0]], 0, null, ["loc", [null, [41, 4], [60, 28]]]], ["block", "collapsable-section", [], ["title", ["subexpr", "t", ["components.calendar_modal.subscribe_manually_title"], [], ["loc", [null, [65, 33], [65, 89]]], 0, 0]], 1, null, ["loc", [null, [65, 4], [90, 28]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 2
              },
              "end": {
                "line": 108,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "calendarDownload-note");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "calendarDownload-messageActions");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["components.calendar_modal.regenerate_subscription_text"], [], ["loc", [null, [98, 12], [98, 74]]], 0, 0], ["inline", "button-text", [], ["icon", "icons/refresh.svg", "location", "at-error", "text", ["subexpr", "t", ["components.calendar_modal.regenerate"], [], ["loc", [null, [104, 11], [104, 53]]], 0, 0], "onClick", ["subexpr", "action", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [105, 22], [105, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [105, 14], [105, 51]]], 0, 0]], ["loc", [null, [101, 6], [106, 8]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 110,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["block", "unless", [["get", "hideIcalButtons", ["loc", [null, [9, 14], [9, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 4], [33, 15]]]], ["block", "unless", [["get", "webcalFeedIsDisabled", ["loc", [null, [38, 12], [38, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [38, 2], [92, 13]]]], ["block", "collapsable-section", [], ["title", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_title"], [], ["loc", [null, [96, 31], [96, 92]]], 0, 0]], 2, null, ["loc", [null, [96, 2], [108, 26]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 0
            },
            "end": {
              "line": 116,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "button button--white at-lf-dialog-footer");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["element", "action", [["get", "close", ["loc", [null, [113, 19], [113, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [113, 10], [113, 26]]], 0, 0], ["inline", "t", ["general.close"], [], ["loc", [null, [114, 4], [114, 25]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 117,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/calendar-download/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["needsCloseButton", true, "onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [2, 54], [2, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 46], [2, 60]]], 0, 0]], 0, null, ["loc", [null, [2, 0], [4, 17]]]], ["block", "modal-content", [], ["padded", true], 1, null, ["loc", [null, [6, 0], [110, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [112, 0], [116, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});