define("scientia-resourcebooker/pods/manage-resources/resource-type/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/icon-selector"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [7, 25], [7, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 42]]], 0, 0], false], [], ["loc", [null, [7, 12], [7, 49]]], 0, 0], "submit", ["subexpr", "action", ["setIcon"], [], ["loc", [null, [8, 13], [8, 31]]], 0, 0]], ["loc", [null, [5, 9], [8, 32]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [9, 32], [9, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 27], [9, 49]]], 0, 0], false], [], ["loc", [null, [9, 19], [9, 56]]], 0, 0]], ["loc", [null, [4, 7], [9, 57]]], 0, 0]], ["loc", [null, [2, 0], [10, 2]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 24,
                  "column": 141
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [24, 21], [24, 82]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.new.description"], [], ["loc", [null, [24, 82], [24, 141]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 10
                },
                "end": {
                  "line": 25,
                  "column": 153
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/edit.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [25, 35], [25, 92]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.new.custom_fields"], [], ["loc", [null, [25, 92], [25, 153]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 10
                },
                "end": {
                  "line": 26,
                  "column": 152
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [26, 35], [26, 93]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_type.new.permissions"], [], ["loc", [null, [26, 93], [26, 152]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 8
              },
              "end": {
                "line": 27,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [24, 10], [24, 152]]]], ["block", "dc-tab", [], ["disabled", true], 1, null, ["loc", [null, [25, 10], [25, 164]]]], ["block", "dc-tab", [], ["disabled", true], 2, null, ["loc", [null, [26, 10], [26, 163]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/resource-type-form", [], ["localePrefix", "components.manage_resources.resource_type_new", "openIconSelector", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [32, 42], [32, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 37], [32, 59]]], 0, 0], true], [], ["loc", [null, [32, 29], [32, 65]]], 0, 0], "clearImageUri", ["subexpr", "action", [["subexpr", "mut", [["get", "imageUri", ["loc", [null, [33, 39], [33, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 34], [33, 48]]], 0, 0], ""], [], ["loc", [null, [33, 26], [33, 52]]], 0, 0], "imageUri", ["subexpr", "@mut", [["get", "imageUri", ["loc", [null, [34, 21], [34, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [35, 17], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "submit", "save"], ["loc", [null, [30, 10], [37, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 6
            },
            "end": {
              "line": 40,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [23, 8], [27, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [29, 8], [38, 25]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 3, 3);
        morphs[5] = dom.createMorphAt(element2, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIconSelector", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [14, 41], [14, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 2], [14, 55]]], 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.resource_type.new.title"], [], ["loc", [null, [17, 25], [17, 76]]], 0, 0], "location", "at-manageResources-resourceTypeNew"], ["loc", [null, [17, 4], [17, 124]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [20, 24], [20, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 6], [20, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", 0], 1, null, ["loc", [null, [22, 6], [40, 18]]]], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [41, 49], [41, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "hideSaveClose", true, "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [41, 84], [41, 99]]], 0, 0]], ["loc", [null, [41, 6], [41, 101]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});