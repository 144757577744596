define('scientia-resourcebooker/pods/components/container-sidebar/nav/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: ['sidebarNav', 'at-sidebar'],
    currentUser: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    notificationsCountFetcher: _ember['default'].inject.service(),
    isPreventingAutoLogout: _ember['default'].computed.alias('session.preventLogout'),
    disablePreventLogout: _ember['default'].computed.alias('systemSettings.options.enforceAutoLogoutOnNotificationsPollFailure'),

    notificationCountEnabledForMenuItem: _ember['default'].computed.alias('notificationsCountFetcher.notificationCountEnabledForMenuItem'),

    enabledPreventLogout: _ember['default'].computed.not('disablePreventLogout'),

    notificationsCount: 0,

    addDisabledClassToNotificationsMenuItem: _ember['default'].computed.and('isPreventingAutoLogout', 'notificationCountEnabledForMenuItem', 'enabledPreventLogout'),

    canSeeAdminTab: _ember['default'].computed('currentUser.hasLoadedPermissions', function () {
      return this.get('currentUser').isResourceOwner() || this.get('currentUser').isTemplateOwner() || this.get('currentUser').isUserGroupOwner() || this.get('currentUser').isBookingsReporter() || this.get('currentUser').isAuditReporter() || this.get('currentUser').isConcurrencyManager();
    })
  });
});