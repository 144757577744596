define('scientia-resourcebooker/services/booking-report-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    cachedReportData: null,
    appInsights: _ember['default'].inject.service(),

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    fetch: function fetch(data, startRange, endRange, skipComputeViewCalendar) {

      this.get('appInsights').trackEvent('report-bookings-generate', {
        isAdmin: this.get('isAdmin'),
        fromDate: startRange.toISOString(),
        toDate: endRange.toISOString(),
        count: _ember['default'].get(data, 'length')
      });

      data = this._serializeData(data);
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS.bookingRequests;
      url += '?StartRange=' + startRange.toISOString() + '&endRange=' + endRange.toISOString();
      if (skipComputeViewCalendar) url += '&ComputeViewCalendar=false';
      return this.get('authenticatedRequest').post(url, data).then(function (response) {
        // return this._normalizeData(response);
        return response;
      });
    },

    fetchPrintDataByReportId: function fetchPrintDataByReportId(reportId) {
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.PRINT.BOOKING_REPORT.loc(reportId);
      return this.get('authenticatedRequest').fetch(url).then(function (data) {
        return data.bookingRequests;
      });
    },

    setCachedReportData: function setCachedReportData(data) {
      this.set('cachedReportData', data);
    },

    fetchCachedReportData: function fetchCachedReportData() {
      return this.get('cachedReportData');
    },

    clearCachedReportData: function clearCachedReportData() {
      this.set('cachedReportData', null);
    },

    _serializeData: function _serializeData(data) {
      return data.map(function (d) {
        return {
          resourceTypeId: d.resourceTypeId,
          resourceGroupId: d.resourceGroupId,
          resourceId: d.resourceId
        };
      });
    },

    _normalizeData: function _normalizeData(data) {
      return data.reduce(function (memo, booking) {
        var startDateTime = moment(booking.startDateTime).format('YYYY-MM-DD');
        if (!(startDateTime in memo)) {
          memo[startDateTime] = [];
        }

        memo[startDateTime].push(booking);
        return memo;
      }, {});
    }
  });
});