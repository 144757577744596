define('scientia-resourcebooker/pods/components/container-sidebar/language/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sidebarLanguage', 'at-sidebar'],

    localeProcessor: _ember['default'].inject.service(),
    current: _ember['default'].computed.alias('i18n.locale'),
    showLocales: false,

    locales: _ember['default'].computed(function () {
      return this.get('localeProcessor.localesWithLabel');
    }),

    actions: {
      changeLocale: function changeLocale(val) {
        this.get('localeProcessor').setLocale(val, true);
        this.set('showLocales', false);
      },

      toggleShowLocales: function toggleShowLocales() {
        this.toggleProperty('showLocales');
      }
    }

  });
});