define('scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/component', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var FILE_TYPES = ['Image', 'Word', 'Excel', 'Pdf', 'Text', 'Archive', 'Custom'];

  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    classNames: ['bookingRequestForm-field bookingRequestForm-field-attachment'],

    attributeBindings: ['fieldId:data-field-id'],
    fieldId: _ember['default'].computed.alias('model.identity'),

    isUploading: false,
    isUploaded: false,
    isError: false,
    errorMessage: '',

    maxFileSize: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('maxFileSizeKB');
    }),

    fileInputExtensions: _ember['default'].computed(function () {
      var types = this.get('model.attachmentTypes');
      var customExtensions = this.get('model.customAttachmentTypes').replace(/ /g, '').replace(/\./g, '');

      var storageTypes = this.get('systemSettings').getSetting('attachmentFileTypes');

      return types.reduce(function (memo, typeIndex) {
        var extensions = '';

        var typeKey = FILE_TYPES[typeIndex].toLowerCase();

        if (!_ember['default'].isEmpty(memo)) memo += ',';

        if (typeIndex === 6) {
          extensions = '.' + customExtensions.replace(/ /g, '').split(',').join(',.');
        } else {
          extensions = '.' + storageTypes[typeKey].replace(/ /g, '').replace(/,/g, ',.');
        }

        memo += extensions;
        return memo;
      }, '');
    }),

    allowedTypes: _ember['default'].computed('i18n.locale', function () {

      var types = this.get('model.attachmentTypes');
      var typesTranslated = this.get('i18n').t('components.manage_resources.booking_form_field.attachment.types').toString().split(',');

      var storageTypes = this.get('systemSettings').getSetting('attachmentFileTypes');
      var customExtensions = this.get('model.customAttachmentTypes').replace(/ /g, '').replace(/,/g, ', ');

      return types.reduce(function (memo, typeIndex) {
        var typeKey = FILE_TYPES[typeIndex].toLowerCase();
        if (typeIndex === 6) {
          memo += _ember['default'].isEmpty(memo) ? typesTranslated[typeIndex] + ' (' + customExtensions + ')' : ', ' + typesTranslated[typeIndex] + ' (' + customExtensions + ')';
        } else {
          var extensions = storageTypes[typeKey].replace(/ /g, '').replace(/,/g, ', ');
          memo += _ember['default'].isEmpty(memo) ? typesTranslated[typeIndex] + ' (' + extensions + ')' : ', ' + typesTranslated[typeIndex] + ' (' + extensions + ')';
        }
        return memo;
      }, '');
    }),

    fileName: _ember['default'].computed('model.value', function () {
      if (this.get('model.value')) {
        var urlSplit = this.get('model.value').split('/');
        var fileName = urlSplit[urlSplit.length - 1];
        return fileName.replace('-RBTEMP', '');
      }
    }),

    uploadPercentage: 0,
    uploadPercentageSafeString: _ember['default'].computed('uploadPercentage', function () {
      var percentage = parseInt(this.get('uploadPercentage'), 10);
      return _ember['default'].String.htmlSafe(percentage);
    }),

    uploadPercentageCSS: _ember['default'].computed('uploadPercentage', function () {
      var percentage = parseInt(this.get('uploadPercentage'), 10);
      return _ember['default'].String.htmlSafe('width: ' + percentage + '%');
    }),

    uploadURL: (function () {
      var prefix = _scientiaResourcebookerConfigEnvironment['default'].API.HOST + '/' + _scientiaResourcebookerConfigEnvironment['default'].API.NAMESPACE + '/';
      return prefix + _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_FORM_FILE_UPLOAD.loc(this.get('formId'), this.get('model.identity'));
    }).property('formId', 'model.id'),

    actions: {
      uploadStarted: function uploadStarted() {
        this.setProperties({
          errorMessage: '',
          'model.value': null,
          uploadPercentage: 0,
          isUploaded: false,
          isUploading: true
        });
      },

      uploadProgress: function uploadProgress(percent) {
        this.set('uploadPercentage', percent);
      },

      uploadFinished: function uploadFinished(file) {
        this.setProperties({
          isUploaded: true,
          isUploading: false,
          'model.value': file
        });
      },

      uploadFailed: function uploadFailed(error) {
        if (error.responseJSON) {
          var locale = 'components.booking_request_form.fields.attachment.errors';
          var errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON, locale).toString();
          this.set('errorMessage', errorMessage);
        }

        // Remove filename from input field
        if (!this.get('isDestroyed')) {
          _ember['default'].$(this.$().find('input')[0]).val('');
        }

        this.setProperties({
          isError: true,
          isUploading: false,
          isUploaded: false,
          'model.value': null
        });
      },

      removeFile: function removeFile() {

        this.setProperties({
          isUploaded: false,
          isUploading: false,
          uploadPercentage: 0,
          'model.value': null,
          errorMessage: ''
        });
      }
    }
  });
});