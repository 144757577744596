define('scientia-resourcebooker/services/resource-events-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var bookingTypeId = _ref.bookingTypeId;
      var resourceTypeId = _ref.resourceTypeId;
      var resourceId = _ref.resourceId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var includeBookingTypes = _ref.includeBookingTypes;
      var includeConcurrencyGroups = _ref.includeConcurrencyGroups;
      var includeLinkedAvailability = _ref.includeLinkedAvailability;
      var forceResync = _ref.forceResync;

      // Default endpoint for a resource BusyTimes
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.resource.busyTime.loc(bookingTypeId, resourceId);

      // If there is a resourceTypeId instead of bookingTypeId it means this is to be viewed in the
      // resource calendar so we use a different endpoint
      if (resourceTypeId) {
        url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_ADMIN_BUSY_TIMES.loc(resourceTypeId, resourceId);
      }

      var data = {
        startDate: startDate.startOf('minute').toISOString(),
        endDate: endDate.startOf('minute').toISOString(),
        forceResync: forceResync,
        includeBookingTypes: includeBookingTypes,
        includeConcurrencyGroups: includeConcurrencyGroups,
        includeLinkedAvailability: includeLinkedAvailability
      };

      return this.get('authenticatedRequest').fetch(url, data).then(function (results) {
        if (!results) return [];
        results.forEach(function (r) {
          if (r.duration === 59) r.duration = 60;

          // Adjust events that span DST boundaries (have a different start and end utc offsets)
          var start = moment(r.startDateTime);
          var end = start.clone().add(r.duration, 'minute');
          var startOffset = start.clone().subtract(1, 'minute').utcOffset();
          var endOffset = end.clone().subtract(1, 'minute').utcOffset();

          var startIsSwitchDay = start.startOf('day').utcOffset() !== start.endOf('day').utcOffset();
          var endIsSwitchDay = end.startOf('day').utcOffset() !== end.endOf('day').utcOffset();
          if (startOffset > endOffset && (endIsSwitchDay || startIsSwitchDay)) {
            r.duration += startOffset;
          } else if (startOffset < endOffset && (endIsSwitchDay || startIsSwitchDay)) {
            r.duration -= endOffset;
          }
        });

        return results;
      });
    }
  });
});