define("scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 25
            },
            "end": {
              "line": 4,
              "column": 164
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 12,
                "column": 178
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
            var el2 = dom.createTextNode("*");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 16,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("       ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList");
          var el2 = dom.createTextNode("\n       ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-field-singleChoice-itemList-label");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "custom-checkbox", [], ["checked", ["subexpr", "contains", [["get", "item.value", ["loc", [null, [9, 43], [9, 53]]], 0, 0, 0, 0], ["get", "selectedValues", ["loc", [null, [9, 54], [9, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 33], [9, 69]]], 0, 0], "action", ["subexpr", "action", ["toggleSelection", ["get", "item", ["loc", [null, [9, 103], [9, 107]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 77], [9, 108]]], 0, 0]], ["loc", [null, [9, 7], [9, 110]]], 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "model.description", ["loc", [null, [12, 24], [12, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 19], [12, 42]]], 0, 0], ["subexpr", "eq", [["get", "model.items.length", ["loc", [null, [12, 47], [12, 65]]], 0, 0, 0, 0], 1], [], ["loc", [null, [12, 43], [12, 68]]], 0, 0], ["get", "model.isMinMaxRestricted", ["loc", [null, [12, 69], [12, 93]]], 0, 0, 0, 0], ["subexpr", "eq", [["get", "model.minimum", ["loc", [null, [12, 98], [12, 111]]], 0, 0, 0, 0], 1], [], ["loc", [null, [12, 94], [12, 114]]], 0, 0]], [], ["loc", [null, [12, 14], [12, 115]]], 0, 0]], [], 0, null, ["loc", [null, [12, 8], [12, 185]]]], ["content", "item.text", ["loc", [null, [13, 8], [13, 21]]], 0, 0, 0, 0]],
        locals: ["item"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 6
            },
            "end": {
              "line": 18,
              "column": 142
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [18, 102], [18, 135]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 6
            },
            "end": {
              "line": 21,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "externalDataError", ["loc", [null, [20, 35], [20, 56]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/multiple-choice/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 2, 2);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createMorphAt(element2, 5, 5);
        morphs[4] = dom.createMorphAt(element2, 7, 7);
        return morphs;
      },
      statements: [["content", "model.description", ["loc", [null, [4, 4], [4, 25]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["get", "model.isMinMaxRestricted", ["loc", [null, [4, 36], [4, 60]]], 0, 0, 0, 0], ["subexpr", "gte", [["get", "model.minimum", ["loc", [null, [4, 66], [4, 79]]], 0, 0, 0, 0], 1], [], ["loc", [null, [4, 61], [4, 82]]], 0, 0], ["get", "model.description", ["loc", [null, [4, 83], [4, 100]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 31], [4, 101]]], 0, 0]], [], 0, null, ["loc", [null, [4, 25], [4, 171]]]], ["block", "each", [["get", "model.items", ["loc", [null, [7, 12], [7, 23]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 4], [16, 13]]]], ["block", "if", [["subexpr", "and", [["get", "shouldDisplayValidations", ["loc", [null, [18, 17], [18, 41]]], 0, 0, 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [18, 42], [18, 73]]], 0, 0]], [], ["loc", [null, [18, 12], [18, 74]]], 0, 0]], [], 2, null, ["loc", [null, [18, 6], [18, 149]]]], ["block", "if", [["get", "externalDataError", ["loc", [null, [19, 12], [19, 29]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [19, 6], [21, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});