define("scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/header-filters/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportListHeader-timeframe");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportListHeader-activeBookings");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "bookingReportListHeader-activeBookingsLabel");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "modal-dropdown-trigger", [], ["value", ["subexpr", "@mut", [["get", "timeframeSelectedLabel", ["loc", [null, [14, 12], [14, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-bookingReportsList", "onClick", ["subexpr", "action", [["get", "onShowTimeframeModal", ["loc", [null, [16, 22], [16, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 14], [16, 43]]], 0, 0]], ["loc", [null, [13, 4], [16, 45]]], 0, 0], ["inline", "t", ["components.reports.bookings.active_bookings"], [], ["loc", [null, [23, 63], [23, 114]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "filterActiveBookings", ["loc", [null, [25, 24], [25, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 14], [25, 45]]], 0, 0], "disabled", ["subexpr", "not", [["get", "hasResults", ["loc", [null, [26, 20], [26, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 15], [26, 31]]], 0, 0], "onToggle", ["subexpr", "action", [["get", "onToggleFilterActiveBookings", ["loc", [null, [27, 23], [27, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 15], [27, 52]]], 0, 0]], ["loc", [null, [24, 6], [27, 54]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});