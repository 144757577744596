define('scientia-resourcebooker/services/calendar-options-helper', ['exports', 'ember'], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var PERIODS = [[0, 15, 30, 45], [0, 30], [0], [30], [15, 45], [0, 10, 20, 30, 40, 50], [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]];

    var DURATIONS = [[15, 30, 60, 120, 180, 0], [30, 60, 120, 180, 0], [60, 120, 180, 0], [60, 120, 180, 0], [15, 30, 60, 120, 180, 0], [30, 60, 120, 180, 0], [15, 30, 60, 120, 180, 0]];

    exports['default'] = _ember['default'].Service.extend({

        systemSettings: _ember['default'].inject.service(),
        dateTimeHelpers: _ember['default'].inject.service(),

        /**
         * Simply converts an enum into an array of allowed minutes
         * @param int bookingTimePeriod
         */
        parse: function parse(bookingTimePeriod) {
            var period = parseInt(bookingTimePeriod, 10);
            return PERIODS[period] || [];
        },

        /**
         * Returns available duration in minutes based on calendarOptions
         * @param {*} calendarOptions
         */
        getAvailableBookingTimeDurations: function getAvailableBookingTimeDurations(calendarOptions) {
            var bookingTimesIndex = calendarOptions.calendarBookingTimes || 0;
            return DURATIONS[bookingTimesIndex];
        },

        /**
         * Returns an array with available timeSlots based on calendarOptions
         * @param {Moment} startDate the StartDate where the times will be generated from
         * @param {*} calendarOptions CalendarOptions
         */
        getTimeSlotsForStartTime: function getTimeSlotsForStartTime(startDate, calendarOptions) {
            var inclusiveLast = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

            var _calendarOptions$calendarStartTime$split = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split2 = _slicedToArray(_calendarOptions$calendarStartTime$split, 2);

            var startHour = _calendarOptions$calendarStartTime$split2[0];
            var startMinutes = _calendarOptions$calendarStartTime$split2[1];

            var startMoment = startDate.clone().hour(startHour).minute(startMinutes);
            var times = this.getTimeSlotsForWholeCalendarRange(startMoment, calendarOptions, inclusiveLast);

            // getTimeSlotsForWholeCalendarRange will return 24:00 instead of 00:00 if the result is only 1 value of 00:00.
            // The below condition fixes that, where if the calendar is *weirdly* set from 00:00 to 00:15, that the startime is not 24:00;
            //  Most likely no one will ever set a calendar range for 15 minutes, but the testers did..
            if (times.length && times[0] === '24:00') times[0] = '00:00';
            return times;
        },

        /**
         * Returns an array with available timeSlots for the end time picker based on current calendarOptions and a startDate
         * @param {Moment} fromDate The Moment object of the start picker
         * @param {Moment} toDate The Moment object of the end picker (so we can calculate if we are spanning multiple days)
         * @param {CalendarOptions} calendarOptions The current CalendarOptions
         */
        getTimeSlotsForEndTime: function getTimeSlotsForEndTime(fromDate, toDate, calendarOptions) {

            var datesAreDifferentDays = !fromDate.isSame(toDate.clone().subtract(1, 'minute'), 'day');

            var calendarStartPeriod = this.getPeriodForDate(fromDate);
            var times = this.getTimeSlotsForWholeCalendarRange(fromDate, calendarOptions);
            var timePeriods = this.getTimeSlotsWithPeriods(times);

            // If dates are different return all times available for EndTime
            if (datesAreDifferentDays && fromDate.isBefore(toDate)) {
                return timePeriods.map(function (t) {
                    return t.time;
                });
            } else {
                return timePeriods.filter(function (t) {
                    return t.period > calendarStartPeriod;
                }).map(function (t) {
                    return t.time;
                });
            }
        },

        /**
         * Returns a list of valid times between calendarOptions.calendarStartTime and calendarEndTime. Each time slot is adheres to
         * the valid periods based on calendarOptions.calendarBookingTimes
         * @param {Moment} date A Moment date to generate the time slots from. The hours and minutes will be reset to calendarStartTime to start the range.
         * @param {*} calendarOptions CalendarOptions from a booking time or SystemSettings
         */
        getTimeSlotsForWholeCalendarRange: function getTimeSlotsForWholeCalendarRange(date, calendarOptions) {
            var inclusiveLast = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

            var bookingPeriods = this.parse(calendarOptions.calendarBookingTimes);

            var _calendarOptions$calendarStartTime$split3 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split32 = _slicedToArray(_calendarOptions$calendarStartTime$split3, 2);

            var startHour = _calendarOptions$calendarStartTime$split32[0];
            var startMinutes = _calendarOptions$calendarStartTime$split32[1];

            var _calendarOptions$calendarEndTime$split = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split2 = _slicedToArray(_calendarOptions$calendarEndTime$split, 2);

            var endHour = _calendarOptions$calendarEndTime$split2[0];
            var endMinutes = _calendarOptions$calendarEndTime$split2[1];

            var startMoment = date.clone().hour(startHour).minute(startMinutes);

            var endMoment = startMoment.clone().hour(endHour).minute(endMinutes);

            var timeSlots = [];

            var currentMoment = startMoment.clone();

            while (currentMoment.isSameOrBefore(endMoment)) {
                if (bookingPeriods.indexOf(currentMoment.minutes()) !== -1) {
                    timeSlots.push(currentMoment.format('HH:mm'));
                }
                currentMoment.add(5, 'minutes');
            }

            if (!inclusiveLast) timeSlots.pop();

            // If last entry is 00:00, change it to 24:00
            if (timeSlots[timeSlots.length - 1] === '00:00') timeSlots[timeSlots.length - 1] = '24:00';

            // Remove duplicates in case we get duplicate 01:xx timeSlots on a DstShift day
            var uniqueTimeSlots = timeSlots.filter(function (v, i) {
                return timeSlots.indexOf(v) === i;
            });

            return uniqueTimeSlots;
        },

        getAvailableStartTimes: function getAvailableStartTimes(startDate, endDate, calendarOptions) {
            var isSpanningMultipleDays = calendarOptions.bookingSpanMultipleDays && !startDate.isSame(endDate.clone().subtract(1, 'minute'), 'day');
            if (isSpanningMultipleDays) {
                return this.getTimeSlotsForWholeCalendarRange(startDate, calendarOptions, false);
            }

            var bookingPeriods = this.parse(calendarOptions.calendarBookingTimes);
            var timeSlots = [];

            var _calendarOptions$calendarStartTime$split4 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split42 = _slicedToArray(_calendarOptions$calendarStartTime$split4, 2);

            var startHour = _calendarOptions$calendarStartTime$split42[0];
            var startMinutes = _calendarOptions$calendarStartTime$split42[1];

            var _calendarOptions$calendarEndTime$split3 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split32 = _slicedToArray(_calendarOptions$calendarEndTime$split3, 2);

            var endHour = _calendarOptions$calendarEndTime$split32[0];
            var endMinutes = _calendarOptions$calendarEndTime$split32[1];

            var calendarStart = startDate.clone().hour(startHour).minute(startMinutes);
            var calendarEnd = calendarStart.clone().hour(endHour).minute(endMinutes);

            if (calendarEnd.hour() === 0 && calendarEnd.minute() === 0) calendarEnd.add(1, 'day');

            var currentMoment = calendarStart.clone();

            var isStillValid = function isStillValid(date) {
                return isSpanningMultipleDays ? date.isSameOrBefore(calendarEnd) : date.isBefore(calendarEnd);
            };

            while (isStillValid(currentMoment)) {
                if (bookingPeriods.indexOf(currentMoment.minutes()) !== -1) {
                    timeSlots.push(currentMoment.format('HH:mm'));
                }
                currentMoment.add(5, 'minutes');
            }

            return timeSlots;
        },

        getAvailableEndTimes: function getAvailableEndTimes(startDate, endDate, calendarOptions) {
            var isSpanningMultipleDays = calendarOptions.bookingSpanMultipleDays && !startDate.clone().subtract(1, 'minute').isSame(endDate, 'day');
            if (isSpanningMultipleDays) {
                var startMidnight = calendarOptions.calendarStartTime === '00:00';
                var endMidnight = calendarOptions.calendarEndTime === '00:00';
                var results = this.getTimeSlotsForWholeCalendarRange(startDate, calendarOptions, !endMidnight);
                if (endMidnight && !startMidnight) results.unshift('00:00');
                return results;
            }

            var bookingPeriods = this.parse(calendarOptions.calendarBookingTimes);

            var _calendarOptions$calendarStartTime$split5 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split52 = _slicedToArray(_calendarOptions$calendarStartTime$split5, 2);

            var startHour = _calendarOptions$calendarStartTime$split52[0];
            var startMinutes = _calendarOptions$calendarStartTime$split52[1];

            var _calendarOptions$calendarEndTime$split4 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split42 = _slicedToArray(_calendarOptions$calendarEndTime$split4, 2);

            var endHour = _calendarOptions$calendarEndTime$split42[0];
            var endMinutes = _calendarOptions$calendarEndTime$split42[1];

            var calendarStart = startDate.clone().hour(startHour).minute(startMinutes);
            var calendarEnd = calendarStart.clone().hour(endHour).minute(endMinutes);

            var timeSlots = [];
            var currentMoment = startDate.clone();

            // If we are not spanning days, we want to make sure the first end time slot is 1 slot later than the current start slot.
            if (!isSpanningMultipleDays) currentMoment.add(5, 'minutes');

            var isStillValid = function isStillValid(date) {
                // return isSpanningMultipleDays ? date.isSameOrBefore(calendarEnd) : date.isBefore(calendarEnd);
                return date.isSameOrBefore(calendarEnd);
            };

            while (isStillValid(currentMoment)) {
                if (bookingPeriods.indexOf(currentMoment.minutes()) !== -1) {
                    timeSlots.push(currentMoment.format('HH:mm'));
                }
                currentMoment.add(5, 'minutes');
            }

            return timeSlots;
        },

        // _mapValues(values) {

        //   const lastSlot = values[values.length - 1];
        //   if (lastSlot === '00:00' && values.length > 1) values[values.length - 1] = '24:00';

        //   return values.map(v => {
        //     return {value: v, label: v};
        //   });
        // },

        adjustStartDateTimePerCalendarOptions: function adjustStartDateTimePerCalendarOptions(date, calendarOptions) {

            date = this.adjustDateToFallOutsideWeekend(date, calendarOptions);

            var _calendarOptions$calendarStartTime$split6 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split62 = _slicedToArray(_calendarOptions$calendarStartTime$split6, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split62[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split62[1];

            var _calendarOptions$calendarEndTime$split5 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split52 = _slicedToArray(_calendarOptions$calendarEndTime$split5, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split52[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split52[1];

            var calendarStartMoment = date.clone().hour(calendarStartHour).minute(calendarStartMinutes);
            var calendarEndMoment = calendarStartMoment.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            if (date.isSameOrBefore(calendarStartMoment)) return calendarStartMoment;
            if (date.isSameOrAfter(calendarEndMoment)) return calendarEndMoment.clone().subtract(1, 'hour');

            var periods = PERIODS[calendarOptions.calendarBookingTimes];

            var possibleStartTime = date.clone();
            var possibleMinutes = parseInt(possibleStartTime.format('mm'), 10);

            // While the current minutes does not match the calendar options periods, keep adding 5 minutes.
            while (periods.indexOf(possibleMinutes) === -1) {
                possibleStartTime.add(5, 'm');
                possibleMinutes = parseInt(possibleStartTime.format('mm'), 10);
            }

            // After adjusting the start time to the next valid time (by incrementing 5mins each time), we need to make sure we didn't reach the endTime.
            // If we did we need to start decrementing by 5min until we reach the last valid start time.

            var isStartTimeSameAsCalendarEndTime = possibleStartTime.format('HH:mm') === calendarOptions.calendarEndTime;
            if (isStartTimeSameAsCalendarEndTime) {
                possibleStartTime.subtract(5, 'm');
                possibleMinutes = parseInt(possibleStartTime.format('mm'), 10);

                while (periods.indexOf(possibleMinutes) === -1) {
                    possibleStartTime.subtract(5, 'm');
                    possibleMinutes = parseInt(possibleStartTime.format('mm'), 10);
                }
            }

            return possibleStartTime;
        },

        // used by drag and booking-request-form
        adjustEndDateTimePerCalendarOptions: function adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions, allowDatesToSpanDays) {

            endDate = this.adjustDateToFallOutsideWeekend(endDate, calendarOptions);

            var _calendarOptions$calendarStartTime$split7 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split72 = _slicedToArray(_calendarOptions$calendarStartTime$split7, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split72[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split72[1];

            var calendarStartMoment = startDate.clone().hour(calendarStartHour).minute(calendarStartMinutes);

            var _calendarOptions$calendarEndTime$split6 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split62 = _slicedToArray(_calendarOptions$calendarEndTime$split6, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split62[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split62[1];

            // Force calendarEnd to same day if not allow span multiple days
            var calendarEndMoment = allowDatesToSpanDays ? endDate.clone() : startDate.clone();
            calendarEndMoment = calendarStartMoment.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            if (endDate.isSameOrBefore(startDate)) {
                endDate = startDate.clone().add(1, 'hour');
            }

            if (!allowDatesToSpanDays && endDate.isSameOrAfter(calendarEndMoment)) return calendarEndMoment;

            var periods = PERIODS[calendarOptions.calendarBookingTimes];

            var possibleEndTime = endDate.clone();
            var possibleMinutes = possibleEndTime.minute();

            /* While we are incrementing the endTime by 5min each time, we need to make sure of two things:
              That the period is valid based on calendarBookingTimes, but also when valid that we are within the calendar
              start and end times (ie: 08:00-21:00). For that reason we need 2 moment dates where hours/minutes are
              always calendarStart and calendarEnd, but the day() of it is in sync with possibleEndTime. If when adding
              5min periods to possibleEndTime we shift to the next day (which is valid if allowDatesToSpanDays === true)
              then we need dates on the same day to be able to compare if the possibleEndTime isBetween Start and End times
             */
            var tempCalendarStart = possibleEndTime.clone().hour(calendarStartHour).minute(calendarStartMinutes);
            var tempCalendarEnd = possibleEndTime.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            while (periods.indexOf(possibleMinutes) === -1) {
                possibleEndTime.add(5, 'm');

                // Make sure the 2 moment dates have the same day() as the possibleEndTime so we can commpare them properly
                tempCalendarStart.day(possibleEndTime.day());
                tempCalendarEnd.day(possibleEndTime.day());
                if (tempCalendarEnd.hour() === 0 && tempCalendarEnd.minute() === 0) tempCalendarEnd.add(1, 'day');

                possibleMinutes = possibleEndTime.minute();
                if (!allowDatesToSpanDays && !possibleEndTime.clone().subtract(1, 'minute').isSame(calendarEndMoment.clone().subtract(1, 'minute'), 'day')) break;
            }

            if (!possibleEndTime.isBetween(tempCalendarStart, tempCalendarEnd, 'minute', '[]')) {
                return tempCalendarEnd.clone();
            }

            return possibleEndTime;
        },

        // Only called when editing a booking times if necessary
        adjustBookingEditStartAndEndDateTimePerCalendarOptions: function adjustBookingEditStartAndEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions, forceAdjustmentsToSameDay) {
            var _calendarOptions$calendarEndTime$split7 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split72 = _slicedToArray(_calendarOptions$calendarEndTime$split7, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split72[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split72[1];

            startDate = this.adjustStartDateTimePerCalendarOptions(startDate, calendarOptions);
            endDate = this.adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions);

            var calendarEnd = undefined;
            if (forceAdjustmentsToSameDay) {
                calendarEnd = startDate.clone().hour(calendarEndHour).minute(calendarEndMinutes);
            } else {
                calendarEnd = endDate.clone().hour(calendarEndHour).minute(calendarEndMinutes);
            }

            if (forceAdjustmentsToSameDay && !startDate.isSame(endDate, 'day')) {
                endDate = calendarEnd.clone();
            } else if (endDate.isSameOrAfter(calendarEnd, 'minute')) {
                endDate = calendarEnd.clone();
            } else if (startDate.isSameOrAfter(endDate)) {
                startDate = endDate.clone().subtract(1, 'hour');
            }

            return [startDate, endDate];
        },

        /**
         * Calculates start and end time of a new booking when clicking on a calendar slot.
         * @param {*} clickedDate The moment() date which was clicked on
         * @param {*} toDate The moment() end date. This only exists if the user dragged on the calendar and therefore
         * selected the end time as well, or if in the resource filters they have selected both dates and times, and the booking form
         * automagically pops up open when they click on the resource.
         * @param {*} calendarOptions Calendar Options of booking type or System
         * @param {*} allowDatesToSpanDays Whether the adjustment of the dates can adjust them to different days or if they are restricted to same day. This does not check if calendarOptions allows it
         */
        getAdjustedTimesForOnSlotClick: function getAdjustedTimesForOnSlotClick(clickedDate, toDate, calendarOptions, allowDatesToSpanDays) {

            // Don't adjust if they are valid
            var isStartDateValid = this.isStartTimeValidForCalendarOptions(clickedDate, calendarOptions);
            var isEndDateValid = this.isEndTimeValidForCalendarOptions(clickedDate, toDate, calendarOptions, allowDatesToSpanDays);
            if (isStartDateValid && isEndDateValid) return [clickedDate, toDate];

            var _calendarOptions$calendarStartTime$split8 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split82 = _slicedToArray(_calendarOptions$calendarStartTime$split8, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split82[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split82[1];

            var calendarStartMoment = clickedDate.clone().hour(calendarStartHour).minute(calendarStartMinutes);

            var _calendarOptions$calendarEndTime$split8 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split82 = _slicedToArray(_calendarOptions$calendarEndTime$split8, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split82[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split82[1];

            var calendarEndMoment = clickedDate.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            var initialClickedDate = clickedDate.clone();

            // If clickedDate is before the calendarStart then it becomes calendarStart
            if (clickedDate.isSameOrBefore(calendarStartMoment)) {
                clickedDate = calendarStartMoment.clone();
            }

            if (toDate) {
                var previousDuration = toDate.diff(initialClickedDate, 'minutes');
                toDate = clickedDate.clone().add(previousDuration, 'minutes');
            }

            // const startDate = this.getNearestTimeInTimeSlots(clickedDate, timeSlotsForCalendar, true);
            var startDate = this.adjustStartDateTimePerCalendarOptions(clickedDate, calendarOptions);

            var endDate = toDate ? toDate.clone() : startDate.clone().add(1, 'hour');
            endDate = this.adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions);

            // Check if adding one hour takes us to the next day. if it does then revert back to calendarEndTime
            // We should only restrict it to same day if calendarOptions doesn't allow it or if allowDatesToSpanDays is false
            var blockSpanDays = !allowDatesToSpanDays || !calendarOptions.bookingSpanMultipleDays;
            var endDateIsAfterCalendarEnd = endDate.isAfter(calendarEndMoment);
            if (blockSpanDays && (endDateIsAfterCalendarEnd || endDate.isAfter(startDate, 'day'))) {
                endDate = calendarEndMoment.clone();
            } else {
                // Adjust endDate
                // endDate = this.getNearestTimeInTimeSlots(endDate, timeSlotsForCalendar, true);
                endDate = this.adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions, true);
            }

            return [startDate, endDate];
        },

        getNearestTimeInTimeSlots: function getNearestTimeInTimeSlots(time, timeSlots) {
            var outputAsMoment = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

            var timeMoment = undefined;
            if (!moment.isMoment(time)) {
                var _time$split = time.split(':');

                var _time$split2 = _slicedToArray(_time$split, 2);

                var h = _time$split2[0];
                var m = _time$split2[1];

                timeMoment = moment().startOf('day');
                timeMoment.hour(h).minutes(m);
            }

            timeMoment.startOf('minute');
            var timePeriod = timeMoment.diff(timeMoment.clone().startOf('day'), 'minutes') / 5;

            // Let's make sure that 24:00 is treated as the last timePeriod and not the first
            if (time === '24:00') timePeriod = 288;

            var slotsWithPeriods = this.getTimeSlotsWithPeriods(timeSlots);
            var best = slotsWithPeriods.find(function (slot) {
                return slot.period >= timePeriod;
            });

            // If no best is found it probably means our current time is out of bounds of the timeSlots available
            // so we select the last one which should be the closest
            if (!best) best = slotsWithPeriods[slotsWithPeriods.length - 1];

            if (outputAsMoment) {
                var bestMoment = timeMoment.clone();

                var _best$time$split = best.time.split(':');

                var _best$time$split2 = _slicedToArray(_best$time$split, 2);

                var h = _best$time$split2[0];
                var m = _best$time$split2[1];

                bestMoment.hour(h).minute(m);
                return bestMoment;
            }

            return best.time;
        },

        getTimeSlotsWithPeriods: function getTimeSlotsWithPeriods(timeSlots) {
            var startOfDay = moment();
            startOfDay.startOf('day');

            var slotsWithPeriods = timeSlots.map(function (time) {
                var _time$split3 = time.split(':');

                var _time$split32 = _slicedToArray(_time$split3, 2);

                var h = _time$split32[0];
                var m = _time$split32[1];

                var currentTime = startOfDay.clone();
                currentTime.hour(h).minute(m);

                return {
                    time: time,
                    period: currentTime.diff(startOfDay, 'minutes') / 5
                };
            });

            if (slotsWithPeriods.length > 1 && slotsWithPeriods[slotsWithPeriods.length - 1].time === '00:00') {
                slotsWithPeriods[slotsWithPeriods.length - 1].period = 288;
            }

            return slotsWithPeriods;
        },

        isStartTimeValidForCalendarOptions: function isStartTimeValidForCalendarOptions(startDateTime, calendarOptions) {
            var _calendarOptions$calendarStartTime$split9 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split92 = _slicedToArray(_calendarOptions$calendarStartTime$split9, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split92[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split92[1];

            var _calendarOptions$calendarEndTime$split9 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split92 = _slicedToArray(_calendarOptions$calendarEndTime$split9, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split92[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split92[1];

            var calendarStart = startDateTime.clone().hour(calendarStartHour).minute(calendarStartMinutes);
            var calendarEnd = startDateTime.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            if (startDateTime.isBefore(calendarStart) || startDateTime.isAfter(calendarEnd)) return false;

            var periods = PERIODS[calendarOptions.calendarBookingTimes];
            var minuteSlotAllowed = periods.indexOf(startDateTime.minute()) !== -1;
            return minuteSlotAllowed;
        },

        isEndTimeValidForCalendarOptions: function isEndTimeValidForCalendarOptions(startDateTime, endDateTime, calendarOptions, allowDatesToSpanDays) {
            var _calendarOptions$calendarStartTime$split10 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split102 = _slicedToArray(_calendarOptions$calendarStartTime$split10, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split102[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split102[1];

            var _calendarOptions$calendarEndTime$split10 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split102 = _slicedToArray(_calendarOptions$calendarEndTime$split10, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split102[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split102[1];

            var endMoment = allowDatesToSpanDays && calendarOptions.bookingSpanMultipleDays ? endDateTime.clone() : startDateTime.clone();

            var calendarStart = startDateTime.clone().hour(calendarStartHour).minute(calendarStartMinutes);
            var calendarEnd = endMoment.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            if (endDateTime.isBefore(startDateTime)) return false;
            if (endDateTime.isBefore(calendarStart) || endDateTime.isAfter(calendarEnd)) return false;

            var periods = PERIODS[calendarOptions.calendarBookingTimes];
            var minuteSlotAllowed = periods.indexOf(endDateTime.minute()) !== -1;
            return minuteSlotAllowed;
        },

        // Checks if booking times are still valid for the calendarOptions
        isBookingTimesValidForCalendarOptions: function isBookingTimesValidForCalendarOptions(startDateTime, endDateTime, calendarOptions) {

            if (this.isDateOnWeekend(startDateTime, calendarOptions)) return false;
            if (this.isDateOnWeekend(endDateTime.clone().subtract(1, 'minute'), calendarOptions)) return false;

            var _calendarOptions$calendarStartTime$split11 = calendarOptions.calendarStartTime.split(':');

            var _calendarOptions$calendarStartTime$split112 = _slicedToArray(_calendarOptions$calendarStartTime$split11, 2);

            var calendarStartHour = _calendarOptions$calendarStartTime$split112[0];
            var calendarStartMinutes = _calendarOptions$calendarStartTime$split112[1];

            var _calendarOptions$calendarEndTime$split11 = calendarOptions.calendarEndTime.split(':');

            var _calendarOptions$calendarEndTime$split112 = _slicedToArray(_calendarOptions$calendarEndTime$split11, 2);

            var calendarEndHour = _calendarOptions$calendarEndTime$split112[0];
            var calendarEndMinutes = _calendarOptions$calendarEndTime$split112[1];

            var calendarStart = startDateTime.clone().hour(calendarStartHour).minute(calendarStartMinutes);
            var calendarEnd = endDateTime.clone().hour(calendarEndHour).minute(calendarEndMinutes);

            if (!calendarOptions.bookingSpanMultipleDays && !startDateTime.isSame(endDateTime.clone().subtract(1, 'minute'), 'day')) return false;

            var adjustedEndDateTime = endDateTime.clone();

            if (startDateTime.isSameOrAfter(adjustedEndDateTime)) return false;

            var endTimeToCheck = endDateTime.format('HH:mm');
            endTimeToCheck = endTimeToCheck === '00:00' ? '24:00' : endTimeToCheck;
            if (this.get('dateTimeHelpers').isTimeBefore(calendarOptions.calendarEndTime, endTimeToCheck)) {
                return false;
            }

            // First check if times fall outside calendar start/end times
            var isValid = startDateTime.isSameOrAfter(calendarStart, 'minute') && adjustedEndDateTime.isSameOrBefore(calendarEnd, 'minute');

            // Check if both times are the same
            var timesAreSame = startDateTime.isSame(adjustedEndDateTime, 'minute');
            if (timesAreSame) isValid = false;

            // Check if date falls on weekend and weekends are disabled
            var showWeekends = calendarOptions.calendarShowWeekends;
            var weekendDays = this.get('systemSettings').getSetting('weekendDays');
            if (!showWeekends && weekendDays.indexOf(startDateTime.day()) !== -1) {
                return false;
            }

            // Then check if minutes are valid based on bookingPeriods
            var startMinutes = startDateTime.minutes();
            var endMinutes = adjustedEndDateTime.minutes();
            var bookingPeriods = this.parse(calendarOptions.calendarBookingTimes);
            if (bookingPeriods.indexOf(startMinutes) === -1 || bookingPeriods.indexOf(endMinutes) === -1) {
                isValid = false;
            }

            return isValid;
        },

        getPeriodForDate: function getPeriodForDate(date) {

            // We want to make sure that the calculated period number is not influenced by the date happening in a DST change day
            // so we calculate it based on the 1 January
            var dateInitial = date.clone().month(1).day(1);

            var period = dateInitial.diff(dateInitial.clone().startOf('day'), 'minutes') / 5;
            return period;
        },

        adjustDateToFallOutsideWeekend: function adjustDateToFallOutsideWeekend(date, calendarOptions) {
            var i = 0;
            while (this.isDateOnWeekend(date, calendarOptions)) {
                date.add(1, 'day');
                i++;
                if (i >= 10) {
                    LE.error('ERROR: Adjusting date to fall outside of weekend failed. Please check "WeekendDays"');
                    break;
                }
            }
            return date;
        },

        isDateOnWeekend: function isDateOnWeekend(date, calendarOptions) {
            var weekendDays = this.get('systemSettings').getSetting('weekendDays');
            var showWeekends = calendarOptions.calendarShowWeekends;
            if (showWeekends || _ember['default'].isEmpty(weekendDays)) return false;

            return weekendDays.indexOf(date.day()) !== -1;
        }

    });
});