define("scientia-resourcebooker/pods/manage-resources/booking-type/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/icon-selector"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [7, 25], [7, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 42]]], 0, 0], false], [], ["loc", [null, [7, 12], [7, 49]]], 0, 0], "submit", ["subexpr", "action", ["setIcon"], [], ["loc", [null, [8, 13], [8, 31]]], 0, 0]], ["loc", [null, [5, 9], [8, 32]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [9, 32], [9, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 27], [9, 49]]], 0, 0], false], [], ["loc", [null, [9, 19], [9, 56]]], 0, 0]], ["loc", [null, [4, 7], [9, 57]]], 0, 0]], ["loc", [null, [2, 0], [10, 2]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 10
                },
                "end": {
                  "line": 23,
                  "column": 181
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [23, 21], [23, 82]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.new.description"], [], ["loc", [null, [23, 99], [23, 155]]], 0, 0]], [], ["loc", [null, [23, 93], [23, 156]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [23, 157], [23, 174]]], 0, 0, 0, 0], true], [], ["loc", [null, [23, 82], [23, 181]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 10
                },
                "end": {
                  "line": 24,
                  "column": 188
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/power.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [24, 35], [24, 93]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.status"], [], ["loc", [null, [24, 110], [24, 162]]], 0, 0]], [], ["loc", [null, [24, 104], [24, 163]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [24, 164], [24, 181]]], 0, 0, 0, 0], true], [], ["loc", [null, [24, 93], [24, 188]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 10
                },
                "end": {
                  "line": 25,
                  "column": 193
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [25, 35], [25, 96]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.calendar"], [], ["loc", [null, [25, 113], [25, 167]]], 0, 0]], [], ["loc", [null, [25, 107], [25, 168]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [25, 169], [25, 186]]], 0, 0, 0, 0], true], [], ["loc", [null, [25, 96], [25, 193]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 10
                },
                "end": {
                  "line": 26,
                  "column": 194
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/clock.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [26, 35], [26, 93]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.availability"], [], ["loc", [null, [26, 110], [26, 168]]], 0, 0]], [], ["loc", [null, [26, 104], [26, 169]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [26, 170], [26, 187]]], 0, 0, 0, 0], true], [], ["loc", [null, [26, 93], [26, 194]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 10
                },
                "end": {
                  "line": 27,
                  "column": 201
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/cog.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [27, 35], [27, 91]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.resource_restrictions"], [], ["loc", [null, [27, 108], [27, 175]]], 0, 0]], [], ["loc", [null, [27, 102], [27, 176]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [27, 177], [27, 194]]], 0, 0, 0, 0], true], [], ["loc", [null, [27, 91], [27, 201]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 10
                },
                "end": {
                  "line": 28,
                  "column": 192
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [28, 35], [28, 93]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.new.permissions"], [], ["loc", [null, [28, 110], [28, 166]]], 0, 0]], [], ["loc", [null, [28, 104], [28, 167]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [28, 168], [28, 185]]], 0, 0, 0, 0], true], [], ["loc", [null, [28, 93], [28, 192]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 10
                },
                "end": {
                  "line": 29,
                  "column": 194
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/bell.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [29, 35], [29, 92]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.notifications"], [], ["loc", [null, [29, 109], [29, 168]]], 0, 0]], [], ["loc", [null, [29, 103], [29, 169]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [29, 170], [29, 187]]], 0, 0, 0, 0], true], [], ["loc", [null, [29, 92], [29, 194]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 10
                },
                "end": {
                  "line": 30,
                  "column": 188
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/papers.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [30, 35], [30, 94]]], 0, 0], ["inline", "truncate", [["subexpr", "to-s", [["subexpr", "t", ["pods.manage_resources.booking_type.edit.rules"], [], ["loc", [null, [30, 111], [30, 162]]], 0, 0]], [], ["loc", [null, [30, 105], [30, 163]]], 0, 0], ["get", "tabTruncateLength", ["loc", [null, [30, 164], [30, 181]]], 0, 0, 0, 0], true], [], ["loc", [null, [30, 94], [30, 188]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 8
              },
              "end": {
                "line": 31,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 15, 15, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [23, 10], [23, 192]]]], ["block", "dc-tab", [], ["disabled", true], 1, null, ["loc", [null, [24, 10], [24, 199]]]], ["block", "dc-tab", [], ["disabled", true], 2, null, ["loc", [null, [25, 10], [25, 204]]]], ["block", "dc-tab", [], ["disabled", true], 3, null, ["loc", [null, [26, 10], [26, 205]]]], ["block", "dc-tab", [], ["disabled", true], 4, null, ["loc", [null, [27, 10], [27, 212]]]], ["block", "dc-tab", [], ["disabled", true], 5, null, ["loc", [null, [28, 10], [28, 203]]]], ["block", "dc-tab", [], ["disabled", true], 6, null, ["loc", [null, [29, 10], [29, 205]]]], ["block", "dc-tab", [], ["disabled", true], 7, null, ["loc", [null, [30, 10], [30, 199]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 8
              },
              "end": {
                "line": 44,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/booking-type-form", [], ["localePrefix", "components.manage_resources.booking_type_new", "openIconSelector", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [36, 42], [36, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 37], [36, 59]]], 0, 0], true], [], ["loc", [null, [36, 29], [36, 65]]], 0, 0], "clearImageUri", ["subexpr", "action", [["subexpr", "mut", [["get", "imageUri", ["loc", [null, [37, 39], [37, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 34], [37, 48]]], 0, 0], ""], [], ["loc", [null, [37, 26], [37, 52]]], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [38, 17], [38, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "@mut", [["get", "description", ["loc", [null, [39, 24], [39, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "imageUri", ["subexpr", "@mut", [["get", "imageUri", ["loc", [null, [40, 21], [40, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingForm", ["subexpr", "@mut", [["get", "bookingForm", ["loc", [null, [41, 24], [41, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "searchBookingForm", ["subexpr", "action", ["searchBookingForm"], [], ["loc", [null, [42, 30], [42, 58]]], 0, 0]], ["loc", [null, [34, 10], [43, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 46,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 8
              },
              "end": {
                "line": 47,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 48,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 6
            },
            "end": {
              "line": 50,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [22, 8], [31, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [33, 8], [44, 25]]]], ["block", "dc-tab-panel", [], [], 2, null, ["loc", [null, [46, 8], [46, 42]]]], ["block", "dc-tab-panel", [], [], 3, null, ["loc", [null, [47, 8], [47, 42]]]], ["block", "dc-tab-panel", [], [], 4, null, ["loc", [null, [48, 8], [48, 42]]]], ["block", "dc-tab-panel", [], [], 5, null, ["loc", [null, [49, 8], [49, 42]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        dom.setAttribute(el2, "id", "main-section");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-note");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n     ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(element2, 1, 1);
        morphs[5] = dom.createMorphAt(element2, 3, 3);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[7] = dom.createMorphAt(element2, 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIconSelector", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [13, 39], [13, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 0], [13, 53]]], 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.booking_type.new.title"], [], ["loc", [null, [16, 25], [16, 75]]], 0, 0]], ["loc", [null, [16, 4], [16, 77]]], 0, 0], ["element", "action", ["save"], ["on", "submit"], ["loc", [null, [18, 10], [18, 39]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [19, 22], [19, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 4], [19, 29]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [21, 59], [21, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [21, 6], [50, 18]]]], ["inline", "t", ["pods.manage_resources.booking_type.new.disabled_tab_note"], [], ["loc", [null, [52, 52], [52, 116]]], 0, 0], ["inline", "manage-resources/footer-actions", [], ["isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [54, 48], [54, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "hideSaveClose", true, "onSave", ["subexpr", "action", ["save"], [], ["loc", [null, [54, 83], [54, 98]]], 0, 0]], ["loc", [null, [54, 5], [54, 100]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});