define('scientia-resourcebooker/pods/components/recurring-event-fields-limited/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recurringEventFields recurringEventFieldsLimited'],
    classNameBindings: ['class'],
    recurrenceProcessor: _ember['default'].inject.service(),
    dateTimeHelpers: _ember['default'].inject.service(),
    calendarOptionsHelper: _ember['default'].inject.service(),

    systemSettings: _ember['default'].inject.service(),

    useOldTimePicker: _ember['default'].computed.alias('systemSettings.options.bookingUseSplitTimePicker'),

    firstDayOfTheWeekOffset: _ember['default'].computed.alias('systemSettings.options.calendarFirstDayOfTheWeekOffset'),

    lastWeekendDay: _ember['default'].computed('firstDayOfTheWeekOffset', function () {
      var lastDay = this.get('firstDayOfTheWeekOffset') - 1;
      return lastDay < 0 ? lastDay + 7 : lastDay;
    }),

    fromTimeHour: _ember['default'].computed('model.fromTime', function () {

      if (this.get('model.fromTime')) {
        return parseInt(this.get('model.fromTime').split(':')[0]);
      }
    }),

    // Used by oldTimePicker
    startHour: _ember['default'].computed('calendarOptions.calendarStartTime', function () {
      return this.get('dateTimeHelpers').getStartHourFromTime(this.get('calendarOptions.calendarStartTime'));
    }),

    // Used by oldTimePicker
    endHour: _ember['default'].computed('calendarOptions.calendarEndTime', function () {
      return this.get('dateTimeHelpers').getEndHourFromTime(this.get('calendarOptions.calendarEndTime'));
    }),

    daysOfWeekDisabledStart: _ember['default'].computed('firstDayOfTheWeekOffset', function () {
      var firstDayOfTheWeekOffset = this.get('firstDayOfTheWeekOffset');
      return [0, 1, 2, 3, 4, 5, 6].without(firstDayOfTheWeekOffset);
    }),

    daysOfWeekDisabledEnd: _ember['default'].computed('lastWeekendDay', function () {
      return [0, 1, 2, 3, 4, 5, 6].without(this.get('lastWeekendDay'));
    }),

    maxDateForUntilDate: null,

    contentFromTime: null,
    contentToTime: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.send('updateContentFromTime');
      this.send('updateContentToTime');
    },

    // We need to set these values on the pattern seeing it is a restricted pattern
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('model')) return;

      this.get('model').setProperties({
        isSPlusRestricted: true,
        recurrenceTypeSelected: 1, // weekly
        untilType: 1 // untilDate
      });
    },

    recurrenceDescription: _ember['default'].computed('i18n.locale', 'model.recurrenceTypeSelected', 'model.startDate', 'model.interval', 'model.untilType', 'model.fromTime', 'model.toTime', 'model.recurringDaysOfWeek.length', 'model.untilDate', function () {

      return this.get('recurrenceProcessor').generateDescription(this.get('model'));
    }),

    actions: {
      updateStartWeek: function updateStartWeek(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model')) return;

        if (date && !date.isSame(this.get('model.startDate'))) {
          this.set('model.startDate', date);
        }

        this.set('maxDateForUntilDate', date.clone().add('3', 'years'));
      },

      updateEndWeek: function updateEndWeek(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model')) return;

        if (date && !date.isSame(this.get('model.untilDate'))) {
          this.set('model.untilDate', date);
        }
      },

      onSetFromTime: function onSetFromTime(fromTime) {
        this.set('model.fromTime', fromTime);

        var toTime = this.get('model.toTime');
        if (toTime && fromTime && this.get('dateTimeHelpers').isTimeBefore(toTime, fromTime, true)) {
          var _fromTime$split = fromTime.split(':');

          var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

          var fromHour = _fromTime$split2[0];
          var fromMinutes = _fromTime$split2[1];

          var _toTime$split = toTime.split(':');

          var _toTime$split2 = _slicedToArray(_toTime$split, 2);

          var toHour = _toTime$split2[0];
          var toMinutes = _toTime$split2[1];

          var startDate = moment().hour(fromHour).minute(fromMinutes);
          var endDate = startDate.clone().hour(toHour).minute(toMinutes);
          var calendarOptions = this.get('calendarOptions');

          var result = this.get('calendarOptionsHelper').adjustEndDateTimePerCalendarOptions(startDate, endDate, calendarOptions, false);
          var time = result.format('HH:mm');
          this.set('model.toTime', time === '00:00' ? '24:00' : time);
        }

        this.send('updateContentToTime');
      },

      onSetToTime: function onSetToTime(toTime) {
        this.set('model.toTime', toTime);
      },

      updateContentFromTime: function updateContentFromTime() {
        var fromDate = moment();
        var calendarOptions = this.get('calendarOptions');
        var result = this.get('calendarOptionsHelper').getTimeSlotsForStartTime(fromDate, calendarOptions);

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentFromTime', data);
      },

      updateContentToTime: function updateContentToTime() {
        var fromTime = this.get('model.fromTime') || this.get('calendarOptions.calendarStartTime');

        var _fromTime$split3 = fromTime.split(':');

        var _fromTime$split32 = _slicedToArray(_fromTime$split3, 2);

        var h = _fromTime$split32[0];
        var m = _fromTime$split32[1];

        var fromDate = moment().hour(h).minute(m);

        var calendarOptions = this.get('calendarOptions');

        var toTime = this.get('model.toTime') || this.get('calendarOptions.calendarEndTime');

        var _toTime$split3 = toTime.split(':');

        var _toTime$split32 = _slicedToArray(_toTime$split3, 2);

        var toHour = _toTime$split32[0];
        var toMinutes = _toTime$split32[1];

        var toDate = fromDate.clone().hour(toHour).minute(toMinutes);
        var result = this.get('calendarOptionsHelper').getTimeSlotsForEndTime(fromDate, toDate, calendarOptions);

        var data = result.map(function (v) {
          return { value: v, label: v };
        });
        this.set('contentToTime', data);
      },

      isValidEndDate: function isValidEndDate(date) {
        var startDate = this.get('model.startDate') || moment();
        return date.isAfter(startDate, 'd');
      }
    }

  });
});