define('scientia-resourcebooker/pods/manage-resources/booking-type/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/availability-form', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsAvailabilityForm, _scientiaResourcebookerMixinsFlashMessages) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var TABS = { DESCRIPTION: 0, STATUS: 1, CALENDAR: 2, AVAILABILITY: 3, RESTRICTIONS: 4, PERMISSIONS: 5, NOTIFICATIONS: 6, RULES: 7 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsAvailabilityForm['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    queryParams: [{ selectedTab: 'section' }, 'availabilityTab', 'isNew'],

    can: _ember['default'].inject.service(),
    media: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    nestedResourceStore: _ember['default'].inject.service(),
    permissionsProcessor: _ember['default'].inject.service(),
    permissionDeprecatedChecker: _ember['default'].inject.service(),
    calendarOptionsProcessor: _ember['default'].inject.service(),

    type: 'bookingType',
    modelId: _ember['default'].computed.alias('model.bookingType.id'),
    parentId: null,

    selectedTab: 0,

    name: '',
    isNew: false,

    isSaving: false,
    isSavingCalendarOptions: false,

    error: '',
    showIconSelector: false,

    showDeleteRestrictionWarning: false,
    resourceRestrictionToDeleteDeferred: null,

    enableBobo: _ember['default'].computed.bool('systemSettings.options.enableBobo'),
    enableBase: _ember['default'].computed.bool('systemSettings.options.enableBase'),

    tabTruncateLength: _ember['default'].computed('media.isTiny', 'media.isMobile', 'media.isDesktop', 'media.isJumbo', 'media.isMassive', function () {
      if (this.get('media.isTiny') || this.get('media.isMobile') || this.get('media.isMassive')) return 50;
      if (this.get('media.isDesktop') || this.get('media.isJumbo')) return 10;
    }),

    canEditAll: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-all booking-type', { model: this.get('model.bookingType') });
    }),
    canEditDescription: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-description booking-type', { model: this.get('model.bookingType') });
    }),
    canEditPermissions: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-permissions booking-type', { model: this.get('model.bookingType') });
    }),
    canEditCalendar: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-calendar booking-type', { model: this.get('model.bookingType') });
    }),
    canEditAvailability: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-availability booking-type', { model: this.get('model.bookingType') });
    }),
    canEditStatus: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-status booking-type', { model: this.get('model.bookingType') });
    }),
    canEditResourceRestrictions: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-resource-restrictions booking-type', { model: this.get('model.bookingType') });
    }),
    canEditNotifications: _ember['default'].computed('model.bookingType', function () {
      return this.get('can').can('edit-notifications booking-type', { model: this.get('model.bookingType') });
    }),
    canEditRules: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-rules booking-type', { model: this.get('model.bookingType') });
    }),

    showSaveActions: _ember['default'].computed('selectedTab', function () {
      var i = parseInt(this.get('selectedTab'), 10);
      return i !== TABS.NOTIFICATIONS;
    }),

    permissionsTypesLabelOverrides: { 'read': 'show' },
    permissionsTypes: _ember['default'].computed('canEditAll', 'canEditPermissions', function () {
      var _this = this;

      var canEditPermissions = this.get('canEditPermissions');
      var canEditAll = this.get('canEditAll');

      // Add initial permissions with base/bobo if enabled
      var initialPermissions = ['read', 'book', 'request', 'recurring'];
      var importPermissions = ['import-bookings'];
      var editPermissions = ['edit-all', 'edit-status', 'edit-calendar', 'edit-availability', 'edit-resource-restrictions', 'edit-permissions', 'edit-notifications', 'edit-rules'];

      if (this.get('enableBase')) initialPermissions.push('base');
      if (this.get('enableBobo')) initialPermissions.push('bobo');
      initialPermissions.push('separator');

      var toObject = function toObject(value, disabled) {
        var deprecated = _this.get('permissionDeprecatedChecker').isDeprecated({ type: 'booking-type', name: value });
        return { value: value, disabled: disabled, deprecated: deprecated };
      };

      var permissionTypes = [];

      // Map them from string to a value object
      initialPermissions = initialPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      importPermissions = importPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      editPermissions = editPermissions.map(function (p) {
        return toObject(p, !canEditAll);
      });

      // Shove editPermissions into previous array and return them all
      permissionTypes.push.apply(permissionTypes, _toConsumableArray(initialPermissions).concat(_toConsumableArray(importPermissions), _toConsumableArray(editPermissions)));

      return permissionTypes;
    }),

    permissions: (function () {
      var bookingType = this.get('model.bookingType');

      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(bookingType);
      return fragments;
    }).property('model.bookingType.readPermissionGroups', 'model.bookingType.bookPermissionGroups', 'model.bookingType.basePermissionGroups', 'model.bookingType.boboPermissionGroups', 'model.bookingType.recurringPermissionGroups', 'model.bookingType.requestPermissionGroups', 'model.bookingType.editAllPermissionGroups', 'model.bookingType.editCalendarPermissionGroups', 'model.bookingType.editAvailabilityPermissionGroups', 'model.bookingType.editStatusPermissionGroups', 'model.bookingType.editRulesPermissionGroups', 'model.bookingType.editResourceRestrictionsPermissionGroups', 'model.bookingType.editPermissionsPermissionGroups', 'model.bookingType.importBookingsPermissionGroups'),

    actions: {
      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      fetchPreviewEvents: function fetchPreviewEvents(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      searchResourceType: function searchResourceType(query, deferred) {
        _ember['default'].run.debounce(this, 'send', '_searchResourceTypeDebounced', query, deferred, 700);
      },

      _searchResourceTypeDebounced: function _searchResourceTypeDebounced(query, deferred) {
        var data = { pageNumber: query.page || 1, query: query.term, itemsPerPage: 30, atResourceRestrictions: true, bookingTypeIdentity: this.get('model.bookingType.id') };

        this.get('store').query('resource-type', data).then(function (response) {
          var meta = { currentPage: response.meta.currentPage, totalPages: response.meta.totalPages };
          var hasMoreResults = meta.currentPage < meta.totalPages;
          var responseData = { data: response, more: hasMoreResults };

          deferred.resolve(responseData);
        });
      },

      searchResourceGroup: function searchResourceGroup(rule, query, deferred) {
        _ember['default'].run.debounce(this, 'send', '_searchResourceGroupDebounced', rule, query, deferred, 700);
      },

      _searchResourceGroupDebounced: function _searchResourceGroupDebounced(rule, query, deferred) {

        this.get('nestedResourceStore').fetch({
          type: 'bookingTypeRestrictions.resourceGroup',
          parentId: this.get('model.bookingType.id'),
          groupId: rule.get('resourceType.id'),
          data: { query: query.term, pageNumber: query.page }
        }).then(function (response) {

          var meta = { currentPage: response.meta.currentPage, totalPages: response.meta.totalPages };
          var hasMoreResults = meta.currentPage < meta.totalPages;
          // delete response.meta;
          var responseData = { data: response, more: hasMoreResults };

          responseData.data = responseData.data.map(function (r) {
            return { id: r.id, name: r.get('name') };
          });
          deferred.resolve(responseData);
        });
      },

      searchBookingForm: function searchBookingForm(term, deferred) {
        this.get('store').query('booking-form', { query: term }).then(function (results) {
          results = results.map(function (r) {
            r.identity = r.id;
            return r;
          });
          deferred.resolve(results);
        });
      },

      onDeleteResourceRestriction: function onDeleteResourceRestriction(deferred) {
        this.set('showDeleteRestrictionWarning', true);
        this.set('resourceRestrictionToDeleteDeferred', deferred);
      },

      onDeleteResourceRestrictionConfirm: function onDeleteResourceRestrictionConfirm() {
        this.get('resourceRestrictionToDeleteDeferred').resolve();
        this.set('resourceRestrictionToDeleteDeferred', null);
        this.set('showDeleteRestrictionWarning', false);
      },

      setIcon: function setIcon(icon) {
        this.set('showIconSelector', false);
        this.set('model.bookingType.imageUri', icon.url);
      },

      deleteModel: function deleteModel() {
        var _this2 = this;

        var defer = _ember['default'].RSVP.defer();

        var confirmData = {
          titlePath: 'pods.manage_resources.booking_type.index.confirm_delete_title',
          messagePath: 'pods.manage_resources.booking_type.index.confirm_delete_message',
          cancelLabelPath: 'general.cancel',
          confirmLabelPath: 'general.confirm'
        };

        confirmData.deferred = defer;

        this.get('globalConfirmer').confirmModal(confirmData);

        defer.promise.then(function () {
          _this2.send('deleteModelConfirm');
        })['catch'](function () {
          _this2.get('globalConfirmer').close();
        });
      },

      deleteModelConfirm: function deleteModelConfirm() {
        var _this3 = this;

        this.get('model.bookingType').destroyRecord().then(function () {
          _this3.get('globalConfirmer').close();
          _this3.deleteSuccessFlash('booking-type');
          _this3.transitionToRoute('manage-resources.booking-type.index');
        })['catch'](function () {
          _this3.get('globalConfirmer').close();
          _this3.deleteErrorFlash('booking-type');
        });
      },

      onCancel: function onCancel() {
        this.transitionToRoute('manage-resources.booking-type.index');
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_type.index.booking_types'),
        path: 'manage-resources.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        linkable: false
      }];
    }).property('model.bookingType.name', 'i18n.locale')
  });
});