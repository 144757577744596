define("scientia-resourcebooker/pods/components/resources-list/items/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 8
                  },
                  "end": {
                    "line": 22,
                    "column": 171
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("span");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["badge at-resourcesList-item ", ["subexpr", "if", [["get", "resourceGroup.resourceCount", ["loc", [null, [22, 88], [22, 115]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [22, 83], [22, 131]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "resourceGroup.resourceCount", ["loc", [null, [22, 133], [22, 164]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 6
                },
                "end": {
                  "line": 27,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "class", "resourcesList-item-icon resourcesList-item-icon--group");
              dom.setAttribute(el1, "src", "icons/folder.svg");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "resourcesList-item-infos");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesList-item-small");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "resourcesList-item-name");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'alt');
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "alt", ["concat", [["subexpr", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [21, 18], [21, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "hideResourceGroupCount", ["loc", [null, [22, 18], [22, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 8], [22, 182]]]], ["content", "resourceGroup.name", ["loc", [null, [25, 48], [25, 70]]], 0, 0, 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["resourcesList-item resourcesList-item--group ", ["subexpr", "if", [["subexpr", "not", [["get", "groupsListExpanded", ["loc", [null, [18, 70], [18, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 65], [18, 89]]], 0, 0], " resourcesList-item--group--hidden", ""], [], ["loc", [null, [18, 60], [18, 131]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["logged-in.booking-type.show", ["get", "bookingType.id", ["loc", [null, [19, 47], [19, 61]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "firstDay", ["loc", [null, [19, 81], [19, 89]]], 0, 0, 0, 0], "fromTime", ["get", "calendarFromTime", ["loc", [null, [19, 99], [19, 115]]], 0, 0, 0, 0], "toTime", ["get", "calendarToTime", ["loc", [null, [19, 123], [19, 137]]], 0, 0, 0, 0], "resourceGroupId", ["get", "resourceGroup.id", ["loc", [null, [19, 154], [19, 170]]], 0, 0, 0, 0]], ["loc", [null, [19, 62], [19, 171]]], 0, 0]], ["class", "resourcesList-item-link"], 0, null, ["loc", [null, [19, 6], [27, 18]]]]],
          locals: ["resourceGroup"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "resources-list--groups-wrapper");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "class", "resourcesList-item resourcesList-item--expander");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "resourcesList-item-link");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "resourceTree-expand");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("img");
          dom.setAttribute(el4, "class", "resourcesList-item-icon resourcesList-item-icon--group");
          dom.setAttribute(el4, "src", "icons/folder.svg");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "resourcesList-item-infos");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "resourcesList-item-small");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("span");
          dom.setAttribute(el5, "class", "resourcesList-item-name");
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n        ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element5, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
          morphs[2] = dom.createAttrMorph(element6, 'alt');
          morphs[3] = dom.createMorphAt(dom.childAt(element5, [5, 3]), 1, 1);
          morphs[4] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["element", "action", [["subexpr", "action", [["subexpr", "toggle", ["groupsListExpanded", ["get", "this", ["loc", [null, [3, 108], [3, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 79], [3, 113]]], 0, 0]], [], ["loc", [null, [3, 71], [3, 114]]], 0, 0]], [], ["loc", [null, [3, 62], [3, 116]]], 0, 0], ["inline", "inline-svg", ["icons/caret-down.svg"], ["class", ["subexpr", "if", [["get", "groupsListExpanded", ["loc", [null, [6, 54], [6, 72]]], 0, 0, 0, 0], "expanded", "collapsed"], [], ["loc", [null, [6, 50], [6, 96]]], 0, 0]], ["loc", [null, [6, 8], [6, 98]]], 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [8, 16], [8, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.resources_list.items.groups"], [], ["loc", [null, [11, 47], [11, 93]]], 0, 0], ["block", "each", [["get", "resourceGroups", ["loc", [null, [17, 10], [17, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 2], [29, 11]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 0
            },
            "end": {
              "line": 42,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "resources-list/item", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [35, 13], [35, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "groupId", ["subexpr", "@mut", [["get", "groupId", ["loc", [null, [36, 12], [36, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "firstDay", ["subexpr", "@mut", [["get", "calendarDate", ["loc", [null, [37, 13], [37, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "lastDay", ["subexpr", "@mut", [["get", "lastDay", ["loc", [null, [38, 12], [38, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "calendarFromTime", ["loc", [null, [39, 13], [39, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "calendarToTime", ["loc", [null, [40, 11], [40, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingType", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [41, 16], [41, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [34, 2], [41, 29]]], 0, 0]],
        locals: ["resource"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 45,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "resourcesList-item resourcesList-item--empty");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [44, 61], [44, 106]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "resourceGroups", ["loc", [null, [43, 12], [43, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 2], [45, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "resourceGroups.length", ["loc", [null, [1, 6], [1, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [31, 7]]]], ["block", "each", [["get", "resources", ["loc", [null, [33, 8], [33, 17]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [33, 0], [46, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});