define('scientia-resourcebooker/pods/components/system/system-settings/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tabForm at-systemSettings'],
    options: null,
    currentUser: _ember['default'].inject.service(),

    editorOptions: _ember['default'].computed('elementId', function () {
      return {
        'font_formats': 'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Calibri=Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
        'toolbar1': 'insertfile undo redo | styleselect fontselect fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code preview',
        'convert_urls': true,
        'relative_urls': false,
        'menubar': true,
        'remove_script_host': false,
        'valid_children': '+body[style]'
      };
    }),

    isSaving: false,

    showDetailedUnavailabilityToggles: false,
    detailedUnavailabilityOptions: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var values = ['everyone', 'admin', 'resource_owner'];
      return values.map(function (v) {
        var id = v === 'resource_owner' ? 'resource owner' : v;
        return { id: id, label: _this.get('i18n').t('components.system.system_settings.detailed_unavailability.' + v) };
      });
    }),

    detailedUnavailabilitySelected: _ember['default'].computed('options.detailedUnavailabilityBookingDisplay', function () {
      var v = this.get('options.detailedUnavailabilityBookingDisplay') || '';
      return v.split(',').map(function (x) {
        return (x || '').trim().toLowerCase();
      });
    }),

    actions: {
      changeMode: function changeMode(mode) {
        this.sendAction('changeMode', mode);
      },

      changeDetailedUnavailability: function changeDetailedUnavailability(option) {
        var selected = this.get('detailedUnavailabilitySelected');
        var newOptions = !selected.includes(option.id) ? [].concat(_toConsumableArray(selected), [option.id]) : selected.filter(function (x) {
          return x !== option.id;
        });
        this.set('options.detailedUnavailabilityBookingDisplay', newOptions.join(','));
      },

      save: function save() {
        this.sendAction('save');
      }
    }

  });
});