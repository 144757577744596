define("scientia-resourcebooker/pods/components/booking-report-list/header/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "booking-report-list/header/header-actions", [], ["onExportCSV", ["subexpr", "action", [["get", "onExportCSV", ["loc", [null, [14, 24], [14, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 16], [14, 36]]], 0, 0], "onPrintClick", ["subexpr", "action", [["get", "onPrintClick", ["loc", [null, [15, 25], [15, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 17], [15, 38]]], 0, 0], "hasResults", ["subexpr", "@mut", [["get", "hasResults", ["loc", [null, [16, 15], [16, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onRefreshReport", ["subexpr", "action", [["get", "onRefreshReport", ["loc", [null, [17, 28], [17, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 20], [17, 44]]], 0, 0]], ["loc", [null, [13, 4], [18, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 2
            },
            "end": {
              "line": 36,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "booking-report-list/header/header-filters", [], ["timeframe", ["subexpr", "@mut", [["get", "timeframe", ["loc", [null, [21, 14], [21, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "startDateTime", ["subexpr", "@mut", [["get", "startDateTime", ["loc", [null, [22, 18], [22, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "endDateTime", ["subexpr", "@mut", [["get", "endDateTime", ["loc", [null, [23, 16], [23, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "filterActiveBookings", ["subexpr", "@mut", [["get", "filterActiveBookings", ["loc", [null, [24, 25], [24, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "onShowTimeframeModal", ["subexpr", "action", [["get", "onShowTimeframeModal", ["loc", [null, [25, 33], [25, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 25], [25, 54]]], 0, 0], "onToggleFilterActiveBookings", ["subexpr", "action", [["get", "onToggleFilterActiveBookings", ["loc", [null, [26, 41], [26, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 33], [26, 70]]], 0, 0], "hasResults", ["subexpr", "@mut", [["get", "hasResults", ["loc", [null, [27, 15], [27, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 4], [28, 6]]], 0, 0], ["inline", "booking-report-list/header/header-actions", [], ["onExportCSV", ["subexpr", "action", [["get", "onExportCSV", ["loc", [null, [31, 24], [31, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 16], [31, 36]]], 0, 0], "onPrintClick", ["subexpr", "action", [["get", "onPrintClick", ["loc", [null, [32, 25], [32, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 17], [32, 38]]], 0, 0], "hasResults", ["subexpr", "@mut", [["get", "hasResults", ["loc", [null, [33, 15], [33, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "onRefreshReport", ["subexpr", "action", [["get", "onRefreshReport", ["loc", [null, [34, 28], [34, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 20], [34, 44]]], 0, 0]], ["loc", [null, [30, 4], [35, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 2
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingReportListHeader-sub at-mobile");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "booking-report-list/header/header-filters", [], ["timeframe", ["subexpr", "@mut", [["get", "timeframe", ["loc", [null, [43, 14], [43, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "startDateTime", ["subexpr", "@mut", [["get", "startDateTime", ["loc", [null, [44, 18], [44, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "endDateTime", ["subexpr", "@mut", [["get", "endDateTime", ["loc", [null, [45, 16], [45, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "filterActiveBookings", ["subexpr", "@mut", [["get", "filterActiveBookings", ["loc", [null, [46, 25], [46, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "onShowTimeframeModal", ["subexpr", "action", [["get", "onShowTimeframeModal", ["loc", [null, [47, 33], [47, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 25], [47, 54]]], 0, 0], "onToggleFilterActiveBookings", ["subexpr", "action", [["get", "onToggleFilterActiveBookings", ["loc", [null, [48, 41], [48, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 33], [48, 70]]], 0, 0], "hasResults", ["subexpr", "@mut", [["get", "hasResults", ["loc", [null, [49, 15], [49, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 4], [50, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-report-list/header/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingReportListHeader-left");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bookingReportListHeader-left-wrapper");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "button button--white button--withIcon at-bookingReportList-back");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "button--icon button--icon-flip-horizontal");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bookingReportListHeader-right-wrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [2, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [4]), 1, 1);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["element", "action", [["subexpr", "action", [["get", "onBack", ["loc", [null, [5, 27], [5, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 19], [5, 34]]], 0, 0]], [], ["loc", [null, [5, 10], [5, 36]]], 0, 0], ["inline", "inline-svg", ["icons/chevron.svg"], [], ["loc", [null, [6, 60], [6, 94]]], 0, 0], ["inline", "t", ["general.back"], [], ["loc", [null, [7, 10], [7, 30]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "media.isTiny", ["loc", [null, [12, 12], [12, 24]]], 0, 0, 0, 0], ["get", "media.isMobile", ["loc", [null, [12, 25], [12, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 8], [12, 40]]], 0, 0]], [], 0, 1, ["loc", [null, [12, 2], [36, 9]]]], ["block", "if", [["subexpr", "or", [["get", "media.isTiny", ["loc", [null, [40, 12], [40, 24]]], 0, 0, 0, 0], ["get", "media.isMobile", ["loc", [null, [40, 25], [40, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 8], [40, 40]]], 0, 0]], [], 2, null, ["loc", [null, [40, 2], [52, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});