define("scientia-resourcebooker/pods/components/toggle-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/toggle-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'disabled');
        morphs[1] = dom.createAttrMorph(element0, 'class');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 0, 0);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 39], [1, 47]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["toggleButton ", ["subexpr", "if", [["get", "isActive", ["loc", [null, [1, 75], [1, 83]]], 0, 0, 0, 0], "toggleButton--active"], [], ["loc", [null, [1, 70], [1, 108]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggle"], [], ["loc", [null, [1, 8], [1, 27]]], 0, 0], ["content", "option.label", ["loc", [null, [1, 110], [1, 126]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});