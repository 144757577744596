define('scientia-resourcebooker/pods/components/resources-list/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['resourcesList-item'],

    resource: null,
    bookingType: null,
    groupId: null,
    firstDay: null,
    lastDay: null,
    properties: [],
    propertiesLoaded: false,
    showProperties: false,
    fromTime: '',
    toTime: '',

    showPropertiesContainer: _ember['default'].computed.and('propertiesLoaded', 'showProperties'),

    // If the item has at least one non null property or a valid description.
    hasProperties: (function () {
      var hasNonNullProperty = this.get('properties').any(function (property) {
        if (property.get('valueType') === 'yesno') return true;

        return property.get('value') != null;
      });

      return hasNonNullProperty || this.get('resource.description') && this.get('resource.description') !== '';
    }).property('showPropertiesContainer', 'properties.[]', 'resource.description')
  });
});