define('scientia-resourcebooker/pods/components/recurring-event-fields/weekly/component', ['exports', 'ember', 'scientia-resourcebooker/helpers/offset-array'], function (exports, _ember, _scientiaResourcebookerHelpersOffsetArray) {
  exports['default'] = _ember['default'].Component.extend({

    recurrenceDaysOfWeek: [],
    systemSettings: _ember['default'].inject.service(),
    showWeekends: false,

    weekdays: (function () {

      var weekDaysAbbr = this.get('i18n').t('general.weekdays_abbreviations').toString().split(',');

      // Generate all weekdays
      var weekdays = weekDaysAbbr.reduce(function (memo, weekday, index) {
        var day = _ember['default'].Object.create({
          id: index,
          value: index,
          label: weekday
        });
        memo.pushObject(day);
        return memo;
      }, []);

      // Offset them due to firstDayOfTheWeekOffset
      var firstDayOfTheWeekOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
      weekdays = (0, _scientiaResourcebookerHelpersOffsetArray.offsetArray)(weekdays, firstDayOfTheWeekOffset);

      // Filter weekends
      var showWeekends = this.get('showWeekends');
      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      weekdays = !showWeekends ? weekdays.filter(function (item) {
        return !weekendDays.includes(item.id);
      }) : weekdays;
      return weekdays;
    }).property('i18n.locale', 'showWeekends'),

    actions: {
      itemToggled: function itemToggled(option) {
        var id = option.get('id');
        var values = this.get('recurringDaysOfWeek');

        if (values.includes(id)) {
          values.removeObject(id);
        } else {
          values.pushObject(id);
        }
      }
    }
  });
});