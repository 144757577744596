define("scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 6
              },
              "end": {
                "line": 30,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [24, 18], [24, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [25, 16], [25, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.fromTime", ["loc", [null, [26, 30], [26, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 25], [26, 45]]], 0, 0]], [], ["loc", [null, [26, 17], [26, 46]]], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [27, 24], [27, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.fromTime", ["loc", [null, [28, 14], [28, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-startDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [23, 8], [29, 91]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 6
              },
              "end": {
                "line": 37,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentFromTime", ["loc", [null, [32, 16], [32, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.fromTime", ["loc", [null, [33, 24], [33, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 14], [33, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateFromTime"], [], ["loc", [null, [35, 17], [35, 43]]], 0, 0]], ["loc", [null, [31, 8], [36, 10]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 8
                },
                "end": {
                  "line": 59,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [53, 20], [53, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [54, 18], [54, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [55, 16], [55, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [56, 26], [56, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [57, 32], [57, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 27], [57, 45]]], 0, 0]], [], ["loc", [null, [57, 19], [57, 46]]], 0, 0], "class", "at-endDate--limited bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [52, 10], [58, 100]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 8
                },
                "end": {
                  "line": 66,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [61, 18], [61, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [62, 26], [62, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 16], [62, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [64, 19], [64, 42]]], 0, 0]], ["loc", [null, [60, 10], [65, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 6
              },
              "end": {
                "line": 68,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-input bookingRequestForm-input");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "disabled", "true");
            dom.setAttribute(el2, "class", "form-input");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'aria-label');
            morphs[1] = dom.createAttrMorph(element0, 'value');
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["components.booking_request_form.to"], [], ["loc", [null, [47, 27], [47, 69]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["subexpr", "moment-format", [["get", "model.startDateTime", ["loc", [null, [47, 93], [47, 112]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [47, 119], [47, 147]]], 0, 0]], [], ["loc", [null, [47, 113], [47, 148]]], 0, 0]], [], ["loc", [null, [null, null], [47, 150]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [51, 14], [51, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [51, 8], [66, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 8
                },
                "end": {
                  "line": 94,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input", [], ["calendarOptions", ["subexpr", "@mut", [["get", "calendarOptions", ["loc", [null, [87, 26], [87, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "startHour", ["subexpr", "@mut", [["get", "startHour", ["loc", [null, [88, 20], [88, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endHour", ["subexpr", "@mut", [["get", "endHour", ["loc", [null, [89, 18], [89, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [90, 16], [90, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [91, 32], [91, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [91, 27], [91, 45]]], 0, 0]], [], ["loc", [null, [91, 19], [91, 46]]], 0, 0], "class", "at-endDate bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [86, 10], [92, 91]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 94,
                  "column": 8
                },
                "end": {
                  "line": 101,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "time-input-select", [], ["content", ["subexpr", "@mut", [["get", "contentToTime", ["loc", [null, [96, 18], [96, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "readonly", [["get", "model.toTime", ["loc", [null, [97, 26], [97, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [97, 16], [97, 39]]], 0, 0], "location", "at-bookingForm", "onChange", ["subexpr", "action", ["updateToTime"], [], ["loc", [null, [99, 19], [99, 43]]], 0, 0]], ["loc", [null, [95, 10], [100, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 6
              },
              "end": {
                "line": 103,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-input date-input");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            return morphs;
          },
          statements: [["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.endDateTime", ["loc", [null, [73, 13], [73, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [75, 21], [75, 49]]], 0, 0]], [], ["loc", [null, [75, 15], [75, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateEndDate"], [], ["loc", [null, [76, 19], [76, 43]]], 0, 0], "readonly", true, "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [78, 27], [78, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [72, 8], [81, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [85, 14], [85, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [85, 8], [101, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 106,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "bookingRequestForm-input date-input");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bookingRequestForm-label--inlineItems");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 1, 1);
          morphs[2] = dom.createMorphAt(element2, 4, 4);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_form.from"], [], ["loc", [null, [4, 83], [4, 127]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.startDateTime", ["loc", [null, [11, 13], [11, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [13, 21], [13, 49]]], 0, 0]], [], ["loc", [null, [13, 15], [13, 50]]], 0, 0], "updateDate", ["subexpr", "action", ["updateStartDate"], [], ["loc", [null, [14, 19], [14, 45]]], 0, 0], "readonly", true, "daysOfWeekDisabled", ["subexpr", "@mut", [["get", "calendarDaysDisabled", ["loc", [null, [16, 27], [16, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [10, 8], [19, 31]]], 0, 0], ["block", "if", [["get", "useOldTimePicker", ["loc", [null, [22, 12], [22, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [22, 6], [37, 13]]]], ["inline", "t", ["components.booking_request_form.to"], [], ["loc", [null, [42, 83], [42, 125]]], 0, 0], ["block", "if", [["subexpr", "or", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [45, 16], [45, 57]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "calendarOptions.bookingSpanMultipleDays", ["loc", [null, [45, 63], [45, 102]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 58], [45, 103]]], 0, 0]], [], ["loc", [null, [45, 12], [45, 104]]], 0, 0]], [], 2, 3, ["loc", [null, [45, 6], [103, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 0
            },
            "end": {
              "line": 108,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["size", "medium", "align", "center"], ["loc", [null, [107, 2], [107, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 112,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/datetime-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "display:none; font-size: 14px;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" || ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(fragment, [2]);
        var element6 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createMorphAt(element4, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 0, 0);
        morphs[3] = dom.createMorphAt(element5, 2, 2);
        morphs[4] = dom.createMorphAt(element6, 0, 0);
        morphs[5] = dom.createMorphAt(element6, 2, 2);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["bookingRequestForm-fields--datetime-contentWrapper ", ["subexpr", "if", [["get", "isLoading", ["loc", [null, [1, 68], [1, 77]]], 0, 0, 0, 0], "bookingRequestForm-fields--datetime-contentWrapper--loading", ""], [], ["loc", [null, [1, 63], [1, 144]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "isLoading", ["loc", [null, [2, 12], [2, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [108, 11]]]], ["inline", "moment-format", [["get", "model.startDateTime", ["loc", [null, [110, 60], [110, 79]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [110, 44], [110, 100]]], 0, 0], ["content", "model.fromTime", ["loc", [null, [110, 104], [110, 122]]], 0, 0, 0, 0], ["inline", "moment-format", [["get", "model.endDateTime", ["loc", [null, [111, 60], [111, 77]]], 0, 0, 0, 0], "DD/MM/YYYY HH:mm"], [], ["loc", [null, [111, 44], [111, 98]]], 0, 0], ["content", "model.toTime", ["loc", [null, [111, 102], [111, 118]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});