define("scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [7, 12], [7, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [8, 25], [8, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 20], [8, 44]]], 0, 0], false], [], ["loc", [null, [8, 12], [8, 51]]], 0, 0], "showBookingRequestActions", true, "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [10, 20], [10, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [11, 18], [11, 47]]], 0, 0]], ["loc", [null, [5, 9], [11, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [13, 32], [13, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 27], [13, 51]]], 0, 0], false], [], ["loc", [null, [13, 19], [13, 58]]], 0, 0]], ["loc", [null, [4, 7], [13, 59]]], 0, 0]], ["loc", [null, [2, 2], [13, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [22, 12], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 9], [22, 34]]], 0, 0]], ["loc", [null, [19, 7], [22, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [23, 37], [23, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 27], [23, 42]]], 0, 0]], [], ["loc", [null, [23, 19], [23, 43]]], 0, 0]], ["loc", [null, [17, 2], [24, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [34, 12], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [35, 25], [35, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 20], [35, 64]]], 0, 0], null], [], ["loc", [null, [35, 12], [35, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [36, 14], [36, 58]]], 0, 0]], ["loc", [null, [32, 9], [36, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [37, 32], [37, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 27], [37, 71]]], 0, 0], null], [], ["loc", [null, [37, 19], [37, 77]]], 0, 0]], ["loc", [null, [31, 7], [37, 78]]], 0, 0]], ["loc", [null, [29, 2], [38, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 56,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [46, 21], [46, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [47, 22], [47, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "name", "subtitlePath", "bookerName", "tagPath", "tag", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [51, 25], [51, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 20], [51, 50]]], 0, 0], null], [], ["loc", [null, [51, 12], [51, 56]]], 0, 0], "on-date-click", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [52, 38], [52, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 28], [52, 43]]], 0, 0]], [], ["loc", [null, [52, 20], [52, 44]]], 0, 0], "on-event-click", ["subexpr", "action", ["onEventClick"], [], ["loc", [null, [53, 21], [53, 44]]], 0, 0]], ["loc", [null, [44, 9], [53, 45]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [54, 32], [54, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [54, 27], [54, 57]]], 0, 0], null], [], ["loc", [null, [54, 19], [54, 63]]], 0, 0]], ["loc", [null, [43, 7], [54, 64]]], 0, 0]], ["loc", [null, [42, 2], [55, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 0
            },
            "end": {
              "line": 67,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["event-overview"], ["model", ["subexpr", "@mut", [["get", "showEventOverview", ["loc", [null, [64, 12], [64, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showEventOverview", ["loc", [null, [65, 25], [65, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 20], [65, 43]]], 0, 0], null], [], ["loc", [null, [65, 12], [65, 49]]], 0, 0]], ["loc", [null, [62, 9], [65, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showEventOverview", ["loc", [null, [66, 32], [66, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 27], [66, 50]]], 0, 0], null], [], ["loc", [null, [66, 19], [66, 56]]], 0, 0]], ["loc", [null, [61, 7], [66, 57]]], 0, 0]], ["loc", [null, [59, 0], [66, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 94,
                "column": 8
              },
              "end": {
                "line": 107,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "rb-dropdown-content-section");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "rb-dropdown-content-title");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["pods.manage_resources.resource.show.view_mode"], [], ["loc", [null, [96, 52], [96, 105]]], 0, 0], ["inline", "toggle-button-group", [], ["content", ["subexpr", "@mut", [["get", "viewModes", ["loc", [null, [99, 20], [99, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "not", [["get", "canShowCalendar", ["loc", [null, [100, 26], [100, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [100, 21], [100, 42]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "viewModeSelected", ["loc", [null, [101, 21], [101, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "singleValue", true, "action", ["subexpr", "action", ["setViewMode"], [], ["loc", [null, [103, 19], [103, 41]]], 0, 0], "class", "at-concurrencyViewMode"], ["loc", [null, [98, 12], [105, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 2
            },
            "end": {
              "line": 111,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle-actions at-concurrencyShow");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "templateUnavailability-toggler");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "toggler-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "templateUnavailability-toggler");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "toggler-label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(element3, 3, 3);
          morphs[4] = dom.createMorphAt(element1, 5, 5);
          return morphs;
        },
        statements: [["inline", "t", ["pods.manage_resources.resource.show.show_template_unavailability"], [], ["loc", [null, [85, 38], [85, 110]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "showTemplateUnavailability", ["loc", [null, [86, 44], [86, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [86, 34], [86, 71]]], 0, 0], "onToggle", ["subexpr", "action", ["toggleTemplateUnavailability"], [], ["loc", [null, [86, 81], [86, 120]]], 0, 0]], ["loc", [null, [86, 10], [86, 122]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource.show.show_concurrency_unavailability"], [], ["loc", [null, [90, 38], [90, 113]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "readonly", [["get", "showConcurrencyUnavailability", ["loc", [null, [91, 44], [91, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [91, 34], [91, 74]]], 0, 0], "onToggle", ["subexpr", "action", ["toggleConcurrencyUnavailability"], [], ["loc", [null, [91, 84], [91, 126]]], 0, 0]], ["loc", [null, [91, 10], [91, 128]]], 0, 0], ["block", "rb-dropdown", [], ["disabled", ["subexpr", "not", [["get", "showViewModeOptions", ["loc", [null, [94, 37], [94, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [94, 32], [94, 57]]], 0, 0], "location", "at-concurrencyViewMode", "buttonLabel", ["subexpr", "t", ["pods.manage_resources.resource.show.view_mode"], [], ["loc", [null, [94, 104], [94, 155]]], 0, 0]], 0, null, ["loc", [null, [94, 8], [107, 24]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 114,
              "column": 4
            },
            "end": {
              "line": 140,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [116, 23], [116, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "compactMode", ["subexpr", "@mut", [["get", "compactMode", ["loc", [null, [118, 20], [118, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "contrastMode", ["subexpr", "@mut", [["get", "contrastMode", ["loc", [null, [119, 21], [119, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "calendarEvents", ["subexpr", "@mut", [["get", "model.events", ["loc", [null, [120, 23], [120, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "lanesToggled", ["subexpr", "@mut", [["get", "lanesToggledOnInit", ["loc", [null, [121, 21], [121, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "lanesWidthPerHour", ["subexpr", "if", [["get", "compactMode", ["loc", [null, [122, 30], [122, 41]]], 0, 0, 0, 0], 50, 200], [], ["loc", [null, [122, 26], [122, 49]]], 0, 0], "event-title-path", "name", "event-subtitle-path", "bookerName", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "tag", "show-tag-button", true, "on-event-click", "onEventClick", "on-lanes-toggled", ["subexpr", "action", ["toggleViewModeVisiblity"], [], ["loc", [null, [130, 25], [130, 59]]], 0, 0], "categories", ["subexpr", "@mut", [["get", "masterViewCategories", ["loc", [null, [131, 19], [131, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-view-type-changed", "onViewTypeChanged", "on-sheet-change", "fetchEvents", "show-lanes-toggler-button", true, "event-parent-id-path", "resourceIdLanesViewVariant"], ["loc", [null, [115, 6], [138, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 140,
              "column": 6
            },
            "end": {
              "line": 142,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "loading-icon", [], ["size", "big", "align", "center"], ["loc", [null, [141, 6], [141, 48]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 146,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-type/concurrency/show/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-manageResources-resource");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [10]);
        var element5 = dom.childAt(element4, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[5] = dom.createMorphAt(element4, 1, 1);
        morphs[6] = dom.createMorphAt(element5, 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showBookingRequest", ["loc", [null, [1, 6], [1, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [14, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [16, 6], [16, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 0], [25, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [28, 6], [28, 44]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [39, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [41, 6], [41, 30]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [41, 0], [56, 7]]]], ["block", "if", [["get", "showEventOverview", ["loc", [null, [58, 6], [58, 23]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [58, 0], [67, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [70, 39], [70, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [70, 0], [70, 53]]], 0, 0], ["block", "section-title", [], ["icon", ["subexpr", "@mut", [["get", "model.resource.imageUri", ["loc", [null, [75, 7], [75, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "beta", true, "text", ["subexpr", "t", ["pods.manage_resources.resource_type.concurrency.show.title"], ["name", ["get", "model.concurrencyObject.name", ["loc", [null, [77, 76], [77, 104]]], 0, 0, 0, 0]], ["loc", [null, [77, 7], [77, 105]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [79, 14], [79, 73]]], 0, 0], "clickAction", "reloadView", "location", "at-manageResources-resource at-concurrencyShow"], 5, null, ["loc", [null, [74, 2], [111, 22]]]], ["block", "if", [["get", "canShowCalendar", ["loc", [null, [114, 10], [114, 25]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [114, 4], [142, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});